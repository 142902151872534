import React from "react";
import "./backButton.css";
import ArrowIconBlack from "../../../assets/ArrowIconBlack.png";

function BackButton() {
  return (
    <div
      className="backButton"
      onClick={() => {
        window.history.back();
      }}
    >
      <img className="backIcon" src={ArrowIconBlack} alt="Arrow" />
      <div className="fs20 semibold">Back</div>
    </div>
  );
}

export default BackButton;
