export function validateInput(creationItem) {
  if (creationItem.event_name === undefined || creationItem.event_name === "") {
    return false;
  }
  return true;
}

export function canProceedToNext(creation) {
  if (creation.stage >= 0 && !creation.event_type) {
    alert("Please select an event type.");
    return false;
  }
  if (creation.stage >= 1 && (!creation.event_name || !creation.event_tldr || !creation.event_picture)) {
    alert("Please fill out all fields.");
    return false;
  }
  if (creation.stage >= 2) {
    if (creation.exact_location && !creation.event_location) {
      alert("Please select a location.");
      return false;
    }
    if (!creation.exact_location && (!creation.event_location_place || !creation.event_location_noun)) {
      alert("Please select a location.");
      return false;
    }
  }
  if (creation.stage >= 3) {
    if (creation.exact_date && !creation.event_start_date) {
      alert("Please select a date.");
      return false;
    }
    if (!creation.exact_date && !creation.event_date_tags.length) {
      alert("Please select a rough date.");
      return false;
    }
  }
  if (creation.stage >= 4) {
    if (creation.event_type === "crowdfund" && !creation.event_fund_target) {
      alert("Please select a funding goal.");
      return false;
    }
  }
  if (creation.stage >= 5 && !creation.terms_agreed) {
    alert("Please agree to terms and services.");
    return false;
  }
  return true;
}
