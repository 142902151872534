import React, { useEffect } from "react";
import "./login.css";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useFormInput } from "../../../hooks/useFormInput";
import BackButton from "../../../components/button/backButton/backButton";
import { validateLogin } from "../accountValidation";
import showPasswordImg from "../../../assets/showPassword.png";
import hidePasswordImg from "../../../assets/hidePassword.png";

function Login() {
  const emailProps = useFormInput("");
  const passwordProps = useFormInput("");

  function showPassword() {
    const passwordInput = document.getElementById("password");
    const passwordImg = document.querySelector(".showPassword img");
    if (passwordInput.type === "password") {
      passwordInput.type = "text";
      passwordImg.src = hidePasswordImg;
    } else {
      passwordInput.type = "password";
      passwordImg.src = showPasswordImg;
    }
  }

  useEffect(() => {
    window.history.replaceState(null, null, "/login");
  }, []);

  return (
    <div className="centerPage">
      <div className="loginPage">
        <div className="loginNavigation">
          <BackButton />
          <div
            className="loginSignup semibold"
            onClick={() => {
              window.location.href = "/signup";
            }}
          >
            Create Account
          </div>
        </div>
        <div className="fs32 semibold">Login</div>
        <div className="loginForm">
          <div className="loginRow">
            <input className="loginInput" type="email" {...emailProps} placeholder="Email Address"/>
          </div>
          <div className="loginRow passwordRow">
            <input className="loginInput" id="password" type="password" {...passwordProps} placeholder="Password"/>
            <span className="showPassword" onClick={showPassword}><img src={showPasswordImg}></img></span>
          </div>
          <div
            className="loginAction fs18 semibold"
            onClick={() => {
              validateLogin(emailProps.value, passwordProps.value);
              const auth = getAuth();
              signInWithEmailAndPassword(auth, emailProps.value, passwordProps.value)
                .then(() => {
                  // const user = userCredential.user;
                  window.location.href = "/";
                })
                .catch((error) => {
                  const errorMessage = error.message;
                  console.log(errorMessage);
                  alert("The account does not exist or the password is incorrect");
                });
            }}
          >
            Continue
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
