import React from "react";
import "./createEventButton.css";
import "../buttonStyle.css";
import ArrowIcon from "../../../assets/ArrowIcon.png";
import { useNavigate } from "react-router-dom";

// Create Event Button Used On:
// 1. Landing Page
function CreateEventButton() {
  const navigate = useNavigate();
  return (
    <div
      className="buttonHolder buttonHolderBlack"
      onClick={() => {
        navigate("/create");
      }}
    >
      <div>Create event</div>
      <img className="buttonArrow" src={ArrowIcon} alt="ArrowIcon" />
    </div>
  );
}

export default CreateEventButton;
