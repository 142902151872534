import React from "react";
import "./transactionTitle.css";

function TransactionTitle({ title, subtitle }) {
  return (
    <div className="transactionTitleSection">
      <div className="transactionPageTitle">{title}</div>
      <div className="transactionPageSubtitle">{subtitle}</div>
    </div>
  );
}

export default TransactionTitle;
