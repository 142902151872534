import React, { useEffect, useState } from "react";
import "./eventWhatCreate.css";
import CreationNavBar from "../creationNavBar/creationNavBar";
import { useDispatch, useSelector } from "react-redux";
import { SET_CREATION_STEP } from "../../../redux/action/createAction";
import checked from "../../../assets/Checked.png";
import EmptyImageIcon from "../../../assets/EmptyImageIcon.png";
import UsernameComponent from "../../user/usernameComponent/usernameComponent";

function EventWhatCreate() {
  const dispatch = useDispatch();
  const creation = useSelector((state) => state.createReducer.currentCreation);
  const currentUser = useSelector((state) => state.userReducer.currentUser);
  const [showNameInfo, setShowNameInfo] = useState(true);
  const [showTldrInfo, setShowTldrInfo] = useState(true);
  const [showImageInfo, setShowImageInfo] = useState(true);
  const [showTldrInput, setShowTldrInput] = useState(false);
  const [showImageInput, setShowImageInput] = useState(false);

  useEffect(() => {
    if (creation.event_name) setShowNameInfo(false);
    if (creation.event_tldr) {
      setShowTldrInfo(false);
      setShowTldrInput(true);
    }
    if (creation.event_picture) {
      setShowImageInfo(false);
      setShowImageInput(true);
    }
  }, []);

  useEffect(() => {
    if (showTldrInput && creation.event_tldr === "") {
      document.getElementById("tldrInput").focus();
    }
  }, [showTldrInput]);

  const onChangePicture = (e) => {
    if (e.target.files[0]) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setShowImageInfo(false);
        dispatch({
          type: SET_CREATION_STEP,
          payload: {
            ...creation,
            event_picture: e.target.files[0],
            event_picture_cache: reader.result,
          },
        });
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  return (
    <>
      <div className="eventCreationLeft invisiScroll">
        <div>
          <div className="eventCreationTitle">Tell us about your event</div>
          <div className="eventCreationSubTitle">{"Let's give your event a name, TLDR, and upload an image."}</div>
          <div className="eventCreationOptionsHolder">
            {/* Name */}
            <div className={showNameInfo ? "eventCreationOption" : "eventCreationDone"}>
              <div className="eventWhatQuestionIcon">{showNameInfo ? 1 : <img className="eventWhatQuestionIconDone" src={checked} alt=""></img>}</div>
              <div className="eventCreationEntry">
                {showNameInfo && <div className="eventCreationOptionTitle">Name your event first</div>}
                {showNameInfo && <div className="eventCreationOptionSubTitle">Think of a unique name that describes your event.</div>}
                <input
                  className="eventCreationInput"
                  type="text"
                  placeholder="e.g Paint by Numbers Picnic Party"
                  defaultValue={creation.event_name}
                  onChange={(e) => {
                    dispatch({
                      type: SET_CREATION_STEP,
                      payload: {
                        ...creation,
                        event_name: e.target.value,
                      },
                    });
                  }}
                  onBlur={() => {
                    if (creation.event_name?.length > 0) {
                      setShowTldrInput(true);
                      setShowNameInfo(false);
                      if (creation.event_tldr?.length > 0) {
                        setShowImageInput(true);
                        setShowTldrInfo(false);
                      }
                    }
                  }}
                  onFocus={() => {
                    setShowNameInfo(true);
                    if (creation.event_picture !== "") {
                      setShowImageInfo(false);
                    } else {
                      setShowImageInput(false);
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.target.blur();
                    }
                  }}
                />
              </div>
            </div>
            {/* TLDR */}
            <div className={showTldrInfo ? "eventCreationOption" : "eventCreationDone"}>
              <div className="eventWhatQuestionIcon">{showTldrInfo ? 2 : <img className="eventWhatQuestionIconDone" src={checked} alt=""></img>}</div>
              <div className="eventCreationEntry">
                {showTldrInfo && <div className="eventCreationOptionTitle">Add a TLDR</div>}
                {showTldrInput && showTldrInfo && <div className="eventCreationOptionSubTitle">Describe your event in one sentence.</div>}
                {showTldrInput && (
                  <input
                    id="tldrInput"
                    className="eventCreationInput"
                    type="text"
                    placeholder="e.g Come have fun painting in the outdoors with some delicious snacks."
                    defaultValue={creation.event_tldr}
                    onChange={(e) => {
                      dispatch({
                        type: SET_CREATION_STEP,
                        payload: {
                          ...creation,
                          event_tldr: e.target.value,
                        },
                      });
                    }}
                    onBlur={() => {
                      if (creation.event_tldr?.length > 0) {
                        setShowImageInput(true);
                        setShowTldrInfo(false);
                      }
                    }}
                    onFocus={() => {
                      setShowTldrInfo(true);
                      if (creation.event_picture !== "") {
                        setShowImageInfo(false);
                      } else {
                        setShowImageInput(false);
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.target.blur();
                      }
                    }}
                  />
                )}
              </div>
            </div>
            {/* Image */}
            <div className={showImageInfo ? "eventCreationOption" : "eventCreationDone"}>
              <div className="eventWhatQuestionIcon">{showImageInfo ? 3 : <img className="eventWhatQuestionIconDone" src={checked} alt=""></img>}</div>
              <div className="eventCreationEntry">
                {showImageInfo && <div className="eventCreationOptionTitle">Upload an image</div>}
                {showImageInput && showImageInfo && <div className="eventCreationOptionSubTitle">Choose an image that shows off your event idea.</div>}
                {showImageInput && (
                  <div
                    className="eventWhatImage clickable"
                    onClick={(e) => {
                      e.stopPropagation();
                      document.querySelector("input[type='file']").click();
                    }}
                  >
                    {creation.event_picture_cache ? (
                      <img className="chosenImage" src={creation.event_picture_cache} alt="Event" />
                    ) : (
                      <img className="emptyImageIcon" src={EmptyImageIcon} alt="NoImage" />
                    )}
                    <input
                      type="file"
                      hidden
                      onChange={onChangePicture}
                      onBlur={() => {
                        setShowImageInfo(false);
                      }}
                      onFocus={() => {
                        setShowImageInfo(true);
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <CreationNavBar />
      </div>
      <div className="eventCreationRight">
        <div className="eventPreviewBacking">
          <div className="previewBox">
            <div className="creationImagePreviewBox">
              {creation.event_picture_cache ? <img className="creationImagePreview" src={creation.event_picture_cache} /> : null}
            </div>
            {creation.event_name !== "" && (
              <UsernameComponent userId={currentUser.user_id} firstName={currentUser.user_first_name} lastName={currentUser.user_last_name} />
            )}
            <div className={`creationNamePreview ${creation.event_name === "" ? "nameShadowBox" : ""}`}>{creation.event_name}</div>
            <div className={`creationTLDRPreview ${creation.event_tldr === "" ? "tldrShadowBox" : ""}`}>{creation.event_tldr}</div>
            <div className="preview-label">Event Details Preview</div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EventWhatCreate;
