import AWS from "aws-sdk";

export function uploadEventPictureToS3(eventId, creation) {
  const awsConfig = new AWS.Config({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    region: "us-west-2",
  });

  AWS.config.update(awsConfig);
  const s3 = new AWS.S3({});

  const params = {
    Bucket: "host-sonder",
    Key: `event/${eventId}/${eventId}.jpg`,
    Body: creation.event_picture,
    ContentType: "image/jpeg",
  };

  s3.upload(params, function (err, data) {
    if (err) {
      console.log(err, err.stack);
    } else {
      console.log("data: ", data);
    }
  });
}

export function uploadGalleryPictureToS3(path, picture) {
  const awsConfig = new AWS.Config({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    region: "us-west-2",
  });

  AWS.config.update(awsConfig);
  const s3 = new AWS.S3({});

  const params = {
    Bucket: "host-sonder",
    Key: path,
    Body: picture,
    ContentType: "image/jpeg",
  };

  s3.upload(params, function (err, data) {
    if (err) {
      console.log(err, err.stack);
    } else {
      console.log("data: ", data);
    }
  });
}

export function uploadUserPictureToS3(userId, pictureFile) {
  const awsConfig = new AWS.Config({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    region: "us-west-2",
  });

  AWS.config.update(awsConfig);
  const s3 = new AWS.S3({});

  const params = {
    Bucket: "host-sonder",
    Key: `profile/${userId}.jpg`,
    Body: pictureFile,
    ContentType: "image/jpeg",
  };

  s3.upload(params, function (err, data) {
    if (err) {
      console.log(err, err.stack);
    } else {
      console.log("data: ", data);
    }
  });
}

export async function getUserProfilePicture(userId) {
  const awsConfig = new AWS.Config({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    region: "us-west-2",
  });

  AWS.config.update(awsConfig);
  const s3 = new AWS.S3({});

  const params = {
    Bucket: "host-sonder",
    Key: `profile/${userId}.jpg`,
  };

  try {
    await s3.headObject(params).promise();
  } catch (err) {
    return null;
  }

  return s3.getSignedUrl("getObject", params);
}


export async function getRandomImageFromPath(path) {
  const awsConfig = new AWS.Config({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    region: "us-west-2",
  });

  AWS.config.update(awsConfig);
  const s3 = new AWS.S3({});

  const params = {
    Bucket: "host-sonder",
    Prefix: path,
  };

  return s3
    .listObjectsV2(params)
    .promise()
    .then((data) => {
      const res = data.Contents.map((image) => {
        return image.Key;
      });

      return res;
    });
}
