import React from "react";
import { useDispatch } from "react-redux";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import "./signup.css";
import { POST_USER } from "../../../redux/action/userAction";
import { useFormInput } from "../../../hooks/useFormInput";
import BackButton from "../../../components/button/backButton/backButton";
import { validateSignup } from "../accountValidation";
import showPasswordImg from "../../../assets/showPassword.png";
import hidePasswordImg from "../../../assets/hidePassword.png";

function SignUp() {
  const dispatch = useDispatch();
  const firstNameProps = useFormInput("");
  const lastNameProps = useFormInput("");
  const emailProps = useFormInput("");
  const passwordProps = useFormInput("");
  const confirmPasswordProps = useFormInput("");

  function showPassword() {
    const passwordInput = document.getElementById("password");
    const confirmPasswordInput = document.getElementById("confirmPassword");
    const passwordImg = document.querySelector(".showPassword img");
    if (passwordInput.type === "password") {
      passwordInput.type = "text";
      confirmPasswordInput.type = "text";
      passwordImg.src = hidePasswordImg;
    } else {
      passwordInput.type = "password";
      confirmPasswordInput.type = "password";
      passwordImg.src = showPasswordImg;
    }
  }

  return (
    <div className="centerPage">
      <div className="signupPage">
        <div className="signupNavigation">
          <BackButton />
          <div
            className="signupLogin semibold"
            onClick={() => {
              window.location.href = "/login";
            }}
          >
            Login
          </div>
        </div>
        <div className="fs32 semibold">Sign Up</div>
        <div className="signupForm">
          <div className="signupRow">
            <input {...firstNameProps} className="signupInput" placeholder="First Name"/>
          </div>
          <div className="signupRow">
            <input {...lastNameProps} className="signupInput" placeholder="Last Name"/>
          </div>
          <div className="signupRow">
            <input {...emailProps} className="signupInput" type="email" placeholder="Email"/>
          </div>
          <div className="signupRow passwordRow">
            <input {...passwordProps} className="signupInput" id="password" type="password" placeholder="Password"/>
            <span className="showPassword" onClick={showPassword}><img src={showPasswordImg}></img></span>
          </div>
          <div className="signupRow">
            <input {...confirmPasswordProps} className="signupInput" id="confirmPassword" type="password" placeholder="Confirm Password"/>
          </div>
          <div
            className="signupAction fs18 semibold"
            onClick={() => {
              validateSignup(firstNameProps.value, lastNameProps.value, emailProps.value, passwordProps.value, confirmPasswordProps.value);
              const auth = getAuth();
              createUserWithEmailAndPassword(auth, emailProps.value, passwordProps.value)
                .then((userCredential) => {
                  const user = userCredential.user;
                  dispatch({
                    type: POST_USER,
                    payload: {
                      user_id: user.uid,
                      first_name: firstNameProps.value,
                      last_name: lastNameProps.value,
                      email_address: emailProps.value,
                    },
                  });
                  setTimeout(() => {
                    window.location.href = "/";
                  }, 1000);
                })
                .catch((error) => {
                  console.log(`${error.code}: ${error.message}`);
                  alert(`There was an error with your sign up. Please try again. ${error.message}`);
                });
            }}
          >
            Continue
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
