import React from "react";
import "./creationNavBar.css";
import EventCreationBackButton from "../../button/eventCreationBackButton/eventCreationBackButton";
import { useSelector } from "react-redux";
import CreateEventNextButton from "../../button/createEventNextButton/createEventNextButton";

function CreationNavBar() {
  const creation = useSelector((state) => state.createReducer.currentCreation);

  return (
    <>
      <div className="creationNavBarBottom">
        <div className="creationNavBarBackArea">{creation.stage >= 1 && <EventCreationBackButton />}</div>
        <CreateEventNextButton />
      </div>
    </>
  );
}

export default CreationNavBar;
