/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import "./myEvents.css";
import { useDispatch, useSelector } from "react-redux";
import { GET_MY_FUTURE_EVENTS, GET_MY_FUTURE_HOSTED_EVENTS, GET_MY_PAST_EVENTS, GET_MY_PAST_HOSTED_EVENTS } from "../../redux/action/myEventsAction";
import VerticalEventCard from "../../components/cards/verticalEventCard/verticalEventCard";

function MyEvents() {
  const dispatch = useDispatch();
  const [eventType, setEventType] = useState("future");
  const [eventHostType, setEventHostType] = useState("tickets");
  const currentUser = useSelector((state) => state.userReducer.currentUser);
  const myPastEvents = useSelector((state) => state.myEventsReducer.myPastEvents);
  const myFutureEvents = useSelector((state) => state.myEventsReducer.myFutureEvents);
  const myPastHostedEvents = useSelector((state) => state.myEventsReducer.myPastHostedEvents);
  const myFutureHostedEvents = useSelector((state) => state.myEventsReducer.myFutureHostedEvents);
  const [displayedEvents, setDisplayedEvents] = useState([]);

  useEffect(() => {
    dispatch({ type: GET_MY_PAST_EVENTS, payload: currentUser.user_id ?? "" });
    dispatch({ type: GET_MY_FUTURE_EVENTS, payload: currentUser.user_id ?? "" });
    dispatch({ type: GET_MY_PAST_HOSTED_EVENTS, payload: currentUser.user_id ?? "" });
    dispatch({ type: GET_MY_FUTURE_HOSTED_EVENTS, payload: currentUser.user_id ?? "" });
  }, [dispatch, currentUser]);

  useEffect(() => {
    setDisplayContent(eventType, eventHostType);
  }, [eventType, eventHostType, myPastEvents, myFutureEvents, myPastHostedEvents, myFutureHostedEvents]);

  function setDisplayContent(eventType, host) {
    if (eventType === "future") {
      // if (host === "tickets") {
      //   let eventSet = {};
      //   myFutureEvents.forEach((event) => {
      //     eventSet[event.event_id] = event;
      //   });
      //   setDisplayedEvents(Object.values(eventSet));
      // } else {
      //   let eventSet = {};
      //   myFutureHostedEvents.forEach((event) => {
      //     eventSet[event.event_id] = event;
      //   });
      //   setDisplayedEvents(Object.values(eventSet));
      // }
      let eventSet = {};
      myFutureEvents.forEach((event) => {
        eventSet[event.event_id] = event;
      });
      myFutureHostedEvents.forEach((event) => {
        eventSet[event.event_id] = event;
      });
      setDisplayedEvents(Object.values(eventSet));
    } else {
      if (host === "tickets") {
        let eventSet = {};
        myPastEvents.forEach((event) => {
          eventSet[event.event_id] = event;
        });
        setDisplayedEvents(eventSet);
      } else {
        let eventSet = {};
        myPastHostedEvents.forEach((event) => {
          eventSet[event.event_id] = event;
        });
        setDisplayedEvents(eventSet);
      }
    }
  }

  return (
    <div className="centerPage">
      <div className="myEventsPage">
        <div className="myEventsTitle">Your Events</div>
        <div className="myEventsBox">
          {Object.values(displayedEvents).map((event) => {
            return <VerticalEventCard key={event.event_id} event={event} />;
          })}
        </div>
      </div>
    </div>
  );
}

export default MyEvents;
