import { combineReducers } from "redux";
import { SET_EVENTS_BY_KEYWORD, SET_NEARBY_EVENTS, SET_NEARLY_FUNDED_EVENTS, SET_NEW_EVENTS } from "../action/eventAction";

const defaultEvents = [];

const nearbyEvents = (state = defaultEvents, action) => {
  switch (action.type) {
  case SET_NEARBY_EVENTS: {
    const nearby = action.payload;
    return nearby;
  }
  default: {
    return state;
  }
  }
};

const nearlyFundedEvents = (state = defaultEvents, action) => {
  switch (action.type) {
  case SET_NEARLY_FUNDED_EVENTS: {
    const nearly = action.payload;
    return nearly;
  }
  default: {
    return state;
  }
  }
};

const newEvents = (state = defaultEvents, action) => {
  switch (action.type) {
  case SET_NEW_EVENTS: {
    const new_events = action.payload;
    return new_events;
  }
  default: {
    return state;
  }
  }
};

const eventSearchResults = (state = defaultEvents, action) => {
  switch (action.type) {
  case SET_EVENTS_BY_KEYWORD: {
    const searchResults = action.payload;
    return searchResults;
  }
  default: {
    return state;
  }
  }
};

export default combineReducers({
  nearbyEvents,
  nearlyFundedEvents,
  newEvents,
  eventSearchResults,
});