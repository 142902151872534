import React, { useEffect, useState } from "react";
import "./eventAbout.css";
import { useDispatch, useSelector } from "react-redux";
import convertMarkdown from "../../../utils/textMarkdown";
import { UPDATE_EVENT } from "../../../redux/action/createAction";
import { SET_EVENT } from "../../../redux/action/eventAction";

function EventAbout() {
  const dispatch = useDispatch();
  const [editable, setEditable] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const currentUser = useSelector((state) => state.userReducer.currentUser);
  const event = useSelector((state) => state.eventReducer.currentEvent);
  const [newDescription, setNewDescription] = useState("");

  useEffect(() => {
    if (event.user_id && currentUser?.user_id === event?.user_id) {
      setEditable(true);
    }
  }, [currentUser, event]);

  useEffect(() => {
    setNewDescription(event?.event_description ?? "");
  }, [event]);

  function EditBox() {
    return (
      <div className="eventDescriptionEditor">
        <textarea
          className="eventDescriptionTextBox"
          defaultValue={newDescription}
          onChange={(e) => {
            e.stopPropagation();
            setNewDescription(e.target.value);
          }}
        />
        <div className="descriptionActions">
          <div
            className="descriptionActionButton descriptionActionGray"
            onClick={(e) => {
              e.stopPropagation();
              setEditMode(false);
              setNewDescription(event?.event_description ?? "");
            }}
          >
            Cancel
          </div>
          <div
            className="descriptionActionButton"
            onClick={(e) => {
              e.stopPropagation();
              setEditMode(false);
              dispatch({
                type: UPDATE_EVENT,
                payload: {
                  ...event,
                  event_description: newDescription,
                },
              });
              dispatch({ type: SET_EVENT, payload: { ...event, event_description: newDescription } });
            }}
          >
            Save
          </div>
        </div>
      </div>
    );
  }

  if (event?.event_description === null || event.event_description === "") {
    if (editable) {
      return (
        <div
          className={`eventAbout ${editMode ? "" : "clickable"}`}
          onClick={() => {
            setEditMode(true);
          }}
        >
          <div className="eventAboutTitle semibold">About the Event</div>
          {editMode ? EditBox() : <div className="aboutTextPlaceholder">Add a description...</div>}
        </div>
      );
    }

    return null;
  }

  if (editable) {
    return (
      <div
        className={`eventAbout ${editMode ? "" : "clickable"}`}
        onClick={() => {
          setEditMode(true);
        }}
      >
        <div className="eventAboutTitle semibold">About the Event</div>
        {editMode ? EditBox() : <div className="aboutText">{convertMarkdown(event.event_description)}</div>}
      </div>
    );
  }

  return (
    <div className="eventAbout">
      <div className="eventAboutTitle semibold">About the Event</div>
      <div className="aboutText">{convertMarkdown(event.event_description)}</div>
    </div>
  );
}

export default EventAbout;
