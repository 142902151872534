import React from "react";
import "./removeWidgetButton.css";
import "../buttonStyle.css";
import { useDispatch } from "react-redux";
import { DELETE_DASHBOARD } from "../../../redux/action/dashboardAction";

function RemoveWidgetButton({ widget }) {
  const dispatch = useDispatch();
  return (
    <div
      className="buttonHolder buttonHolderBlack"
      onClick={() => {
        dispatch({ type: DELETE_DASHBOARD, payload: widget });
      }}
    >
      <div>Remove Widget</div>
    </div>
  );
}

export default RemoveWidgetButton;
