import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import "./landing.css";
import { GET_NEARBY_EVENTS, GET_NEARLY_FUNDED_EVENTS, GET_NEW_EVENTS } from "../../redux/action/eventAction";
import NewsletterTitle from "../../components/landingPage/newsletterTitle/newsletterTitle";
import Divider from "../../components/divider/divider";
import ForYou from "../../components/landingPage/forYou/forYou";
import UpcomingEvents from "../../components/landingPage/upcomingEvents/upcomingEvents";
import CreateInspiration from "../../components/landingPage/createInspiration/createInspiration";
import { useMediaQuery } from "../../hooks/useMediaQuery";

function Landing() {
  const dispatch = useDispatch();
  const isDesktop = useMediaQuery("(min-width: 768px)");

  useEffect(() => {
    dispatch({ type: GET_NEARBY_EVENTS, payload: "c2b2" });
    dispatch({ type: GET_NEARLY_FUNDED_EVENTS });
    dispatch({ type: GET_NEW_EVENTS });
  }, [dispatch]);

  return (
    <div className="centerPage">
      <div className="landingPage">
        <NewsletterTitle />
        <Divider />
        <ForYou />

        <div className="landingPageUpcoming">
          <div className="landingPageHalfSection">
            <UpcomingEvents />
          </div>
          {isDesktop ? <div className="landingPageHalfSection2">
            <CreateInspiration />
          </div> : null}
        </div>
      </div>
    </div>
  );
}

export default Landing;
