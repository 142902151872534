import React from "react";
import "./scanWidget.css";
import Scan from "../../../assets/Scan.png";
import { useNavigate } from "react-router-dom";

function ScanWidget({ event }) {
  const navigate = useNavigate();
  //// Logic to check if event is expired
  // var deleteAvailableTime = new Date(event.event_start_time);
  // deleteAvailableTime.setDate(deleteAvailableTime.getDate() + 1);
  // const eventExpired = deleteAvailableTime < new Date();
  // if (eventExpired) {
  //   return (
  //     <div className="scanTicketHolder">
  //       <div className="widgetTitle scanTicketTitle">Thanks for hosting on Sonder</div>
  //     </div>
  //   );
  // }
  return (
    <div
      className="scanTicketHolder"
      onClick={() => {
        navigate(`/scan/${event.event_id}`);
      }}
    >
      <div className="widgetTitle scanTicketTitle">Scan Tickets</div>
      <div className="scanImgHolder">
        <img className="scanImg" src={Scan} alt="Scan Icon" />
      </div>
    </div>
  );
}

export default ScanWidget;
