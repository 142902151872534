import React from "react";
import "./payButton.css";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import { v4 as uuidv4 } from "uuid";
import { frontend } from "../../../../Constants";
import { useDispatch, useSelector } from "react-redux";
import { POST_EVENT_TRANSACTION } from "../../../../redux/action/eventAction";

function PayButton({paymentIntentId}) {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.userReducer.currentUser);
  const event = useSelector((state) => state.eventReducer.currentEvent);
  const cart = useSelector((state) => state.cartReducer.currentCart);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    Object.entries(cart.cartContent).forEach((eventTierType) => {
      const eventTierId = eventTierType[0];
      const amount = eventTierType[1].quantity;
      for (let i = 0; i < amount; i++) {
        dispatch({
          type: POST_EVENT_TRANSACTION,
          payload: {
            transaction_id: uuidv4(),
            paymentIntent: paymentIntentId,
            timestamp: new Date().getTime(),
            event_id: event.event_id,
            user_id: currentUser?.user_id,
            event_tier_id: eventTierId,
          },
        });
      }
    });

    const res = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${frontend}transactionResult?paymentIntentId=${paymentIntentId}&eventId=${event.event_id}`,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (res.error.type === "card_error" || res.error.type === "validation_error") {
      console.log(res.error.message);
    } else {
      console.log(`An unexpected error occurred: ${res.error.message}`);
    }
  };

  return (
    <div className="PayButton" onClick={handleSubmit}>
      <div className="PayButtonText">Confirm & Pay</div>
    </div>
  );
}

export default PayButton;
