import { call, put, takeLatest } from "redux-saga/effects";
import { GET_EVENTS_BY_KEYWORD, GET_NEARBY_EVENTS, GET_NEARLY_FUNDED_EVENTS, GET_NEW_EVENTS, SET_EVENTS_BY_KEYWORD, SET_NEARBY_EVENTS, SET_NEARLY_FUNDED_EVENTS, SET_NEW_EVENTS } from "../action/eventAction";
import { getEventsByKeywordApi, getNearbyEventsApi, getNearlyFundedEventsApi, getNewEventsApi } from "../api/landingApi";

export function* getNearbyEvents({ payload }) {
  const nearbyEvents = yield call(getNearbyEventsApi, payload);
  yield put({ type: SET_NEARBY_EVENTS, payload: nearbyEvents?.data ?? [] });
}

export function* getNearlyFundedEvents() {
  const nearlyFunded = yield call(getNearlyFundedEventsApi);
  yield put({ type: SET_NEARLY_FUNDED_EVENTS, payload: nearlyFunded?.data ?? [] });
}

export function* getNewEvents({ payload }) {
  const newEvents = yield call(getNewEventsApi, payload);
  yield put({ type: SET_NEW_EVENTS, payload: newEvents?.data ?? [] });
}

export function* getEventsByKeyword({ payload }) {
  const searchResults = yield call(getEventsByKeywordApi, payload);
  yield put({ type: SET_EVENTS_BY_KEYWORD, payload: searchResults?.data ?? [] });
}

export default function* landingSaga() {
  yield takeLatest(GET_NEARBY_EVENTS, getNearbyEvents);
  yield takeLatest(GET_NEARLY_FUNDED_EVENTS, getNearlyFundedEvents);
  yield takeLatest(GET_NEW_EVENTS, getNewEvents);
  yield takeLatest(GET_EVENTS_BY_KEYWORD, getEventsByKeyword);
}