import { combineReducers } from "redux";
import { SET_EVENT, SET_EVENT_GUESTLIST, SET_EVENT_TICKET, SET_EVENT_TIERS, SET_EVENT_TRANSACTIONS } from "../action/eventAction";

const defaultEvent = {};

const currentEvent = (state = defaultEvent, action) => {
  switch (action.type) {
    case SET_EVENT: {
      const event = action.payload;
      return event;
    }
    default: {
      return state;
    }
  }
};

const currentEventTiers = (state = [], action) => {
  switch (action.type) {
    case SET_EVENT_TIERS: {
      const eventTiers = action.payload;
      return eventTiers;
    }
    default: {
      return state;
    }
  }
};

const currentEventTransactions = (state = [], action) => {
  switch (action.type) {
    case SET_EVENT_TRANSACTIONS: {
      const eventTransactions = action.payload;
      return eventTransactions;
    }
    default: {
      return state;
    }
  }
};

const eventGuestlist = (state = [], action) => {
  switch (action.type) {
    case SET_EVENT_GUESTLIST: {
      const eventGuestlist = action.payload;
      return eventGuestlist;
    }
    default: {
      return state;
    }
  }
};

const currentEventTicket = (state = [], action) => {
  switch (action.type) {
    case SET_EVENT_TICKET: {
      const eventTicket = action.payload;
      return eventTicket;
    }
    default: {
      return state;
    }
  }
}

export default combineReducers({
  currentEvent,
  currentEventTiers,
  currentEventTransactions,
  eventGuestlist,
  currentEventTicket,
});
