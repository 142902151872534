/* eslint-disable indent */
import React, { useEffect, useState } from "react";
import "./transactionResult.css";
import { useDispatch, useSelector } from "react-redux";
import { Elements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { POST_PAID_EVENT_TRANSACTION } from "../../../redux/action/eventAction";

const stripePromise = loadStripe(
  "pk_test_51NwsNvLBv7oNjOnOMkzV35qJLlfYdGgh5Gpvj19slaMrIsK2eQAxavsHxh31D8voU5VU8SkZM0cDPklB5Tta9zIy00J4aQJYZZ",
);

function TransactionMessage() {
  const stripe = useStripe();
  const dispatch = useDispatch();
  // const event = useSelector((state) => state.eventReducer.currentEvent);
  const cart = useSelector((state) => state.cartReducer.currentCart);
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get("payment_intent_client_secret");
    const paymentIntentId = new URLSearchParams(window.location.search).get("paymentIntentId");
    const eventId = new URLSearchParams(window.location.search).get("eventId");

    if (!clientSecret || !paymentIntentId) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage(`Payment succeeded! Charged for: $${paymentIntent.amount / 100}. Redirecting to ticket page...`);
          dispatch({
            type: POST_PAID_EVENT_TRANSACTION,
            payload: { paymentIntent: paymentIntentId, eventId: eventId },
          });
          setTimeout(() => {
            window.location.href = `/myTicket/${eventId}`;
          }, 3000);
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe, cart, dispatch]);

  return <div className="transactionResultPage">{message}</div>;
}

function TransactionResult() {
  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    const clientSecret = new URLSearchParams(window.location.search).get("payment_intent_client_secret");
    setClientSecret(clientSecret);
  }, []);

  if (clientSecret === "") {
    return null;
  }

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <div>
      Transaction Result Page!
      <Elements options={options} stripe={stripePromise}>
        <TransactionMessage />
      </Elements>
    </div>
  );
}

export default TransactionResult;
