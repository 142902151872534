import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
import "./search.css";
import ArrowIconBlack from "../../assets/ArrowIconBlack.png";
import SearchBar from "../../components/searchBar/searchBar";
import { GET_EVENTS_BY_KEYWORD } from "../../redux/action/eventAction";
import EventTileOuter from "../../components/eventTileOuter/eventTileOuter";

function Search() {
  const keyword = window.location.pathname.split("/")[2];
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const searchResults = useSelector((state) => state.landingReducer.eventSearchResults);

  useEffect(() => {
    dispatch({ type: GET_EVENTS_BY_KEYWORD, payload: keyword });
  }, [dispatch]);

  return (
    <div className="searchPage">
      <div className="searchPageSearchBox">
        <SearchBar />
      </div>
      <span className="searchPageDefaultText">
        Showing results for <span className="searchPageSearchText">{keyword}</span>
      </span>
      <div className="resultBox">
        {Object.values(searchResults).map((event) => {
          return <EventTileOuter key={event} event={event} />;
        })}
      </div>
      <div className="searchPageDefaultText">End of results</div>
      <div
        className="backToTopButton"
        onClick={() => {
          window.scrollTo(0, 0);
        }}
      >
        <div className="backToTopText">Back to top</div>
        <img className="upArrow" src={ArrowIconBlack} alt="Up" />
      </div>
    </div>
  );
}

export default Search;
