import { call, put, takeLatest } from "redux-saga/effects";
import {
  DELETE_EVENT,
  DELETE_RSVP,
  GET_EVENT,
  GET_EVENT_GUESTLIST,
  GET_EVENT_TICKET,
  GET_EVENT_TIERS,
  GET_EVENT_TRANSACTIONS,
  POST_EVENT_TRANSACTION,
  POST_PAID_EVENT_TRANSACTION,
  SET_EVENT,
  SET_EVENT_GUESTLIST,
  SET_EVENT_TICKET,
  SET_EVENT_TIERS,
  SET_EVENT_TRANSACTIONS,
} from "../action/eventAction";
import { deleteEventApi, getEventApi, getEventGuestlistApi, getEventTierApi, postEventApi, putEventApi } from "../api/eventApi";
import { getEventTransactionApi, postEventTransactionApi, postPaidEventTransactionApi } from "../api/eventTransactionApi";
import { GET_EDIT_EVENT, POST_EVENT, SET_EDIT_CREATION_STEP, UPDATE_EVENT } from "../action/createAction";
import {
  GET_MY_FUTURE_EVENTS,
  GET_MY_FUTURE_HOSTED_EVENTS,
  GET_MY_PAST_EVENTS,
  GET_MY_PAST_HOSTED_EVENTS,
  SET_MY_FUTURE_EVENTS,
  SET_MY_FUTURE_HOSTED_EVENTS,
  SET_MY_PAST_EVENTS,
  SET_MY_PAST_HOSTED_EVENTS,
} from "../action/myEventsAction";
import { getCurrentEventTicketApi, getMyFutureEventsApi, getMyFutureHostedEventsApi, getMyPastEventsApi, getMyPastHostedEventsApi, removeEventRSVPApi } from "../api/myEventApi";
import { postEmailConfirmationApi } from "../api/emailApi";
import { S3Bucket } from "../../Constants";

export function* getEvent({ payload }) {
  const event = yield call(getEventApi, payload);
  yield put({ type: SET_EVENT, payload: event.data });
}

export function* postEvent({ payload }) {
  yield call(postEventApi, payload);
}

export function* putEvent({ payload }) {
  yield call(putEventApi, payload);
}

export function* getEventTiers({ payload }) {
  const eventTiers = yield call(getEventTierApi, payload);
  yield put({ type: SET_EVENT_TIERS, payload: eventTiers.data });
}

export function* getEventTransactions({ payload }) {
  const eventTransactions = yield call(getEventTransactionApi, payload);
  yield put({ type: SET_EVENT_TRANSACTIONS, payload: eventTransactions.data });
}

export function* postEventTransaction({ payload }) {
  yield call(postEventTransactionApi, payload);
  yield put({ type: GET_EVENT_TICKET, payload });
}

export function* postPaidEventTransaction({ payload }) {
  yield call(postPaidEventTransactionApi, payload);
  yield call(postEmailConfirmationApi, payload);
}

export function* getMyPastEvents({ payload }) {
  const events = yield call(getMyPastEventsApi, payload);
  yield put({ type: SET_MY_PAST_EVENTS, payload: events.data });
}

export function* getMyFutureEvents({ payload }) {
  const events = yield call(getMyFutureEventsApi, payload);
  yield put({ type: SET_MY_FUTURE_EVENTS, payload: events.data });
}

export function* getMyPastHostedEvents({ payload }) {
  const events = yield call(getMyPastHostedEventsApi, payload);
  yield put({ type: SET_MY_PAST_HOSTED_EVENTS, payload: events.data });
}

export function* getMyFutureHostedEvents({ payload }) {
  const events = yield call(getMyFutureHostedEventsApi, payload);
  yield put({ type: SET_MY_FUTURE_HOSTED_EVENTS, payload: events.data });
}

export function* getEditEvent({ payload }) {
  const event = yield call(getEventApi, payload);
  yield put({
    type: SET_EDIT_CREATION_STEP,
    payload: {
      ...event.data,
      event_picture_cache: `${S3Bucket}event/${event.data.event_id}/${event.data.event_id}.jpg`,
    },
  });
}

export function* deleteEvent({ payload }) {
  yield call(deleteEventApi, payload);
}

export function* getEventGuestlist({ payload }) {
  const eventGuestlist = yield call(getEventGuestlistApi, payload);
  yield put({ type: SET_EVENT_GUESTLIST, payload: eventGuestlist.data });
}

export function* getEventTicket({ payload }) {
  const eventTicket = yield call(getCurrentEventTicketApi, payload);
  if (eventTicket.data.length > 0) {
    yield put({ type: SET_EVENT_TICKET, payload: eventTicket.data });
  } else {
    yield put({ type: SET_EVENT_TICKET, payload: [] });
  }
}

export function* removeRSVP({ payload }) {
  yield call(removeEventRSVPApi, payload);
  yield put({ type: GET_EVENT_TICKET, payload });
}

export default function* eventSaga() {
  yield takeLatest(GET_EVENT, getEvent);
  yield takeLatest(GET_EVENT_TIERS, getEventTiers);
  yield takeLatest(GET_EVENT_TRANSACTIONS, getEventTransactions);
  yield takeLatest(POST_EVENT_TRANSACTION, postEventTransaction);
  yield takeLatest(POST_PAID_EVENT_TRANSACTION, postPaidEventTransaction);
  yield takeLatest(POST_EVENT, postEvent);
  yield takeLatest(UPDATE_EVENT, putEvent);
  yield takeLatest(GET_EDIT_EVENT, getEditEvent);
  yield takeLatest(GET_MY_PAST_EVENTS, getMyPastEvents);
  yield takeLatest(GET_MY_FUTURE_EVENTS, getMyFutureEvents);
  yield takeLatest(GET_MY_PAST_HOSTED_EVENTS, getMyPastHostedEvents);
  yield takeLatest(GET_MY_FUTURE_HOSTED_EVENTS, getMyFutureHostedEvents);
  yield takeLatest(DELETE_EVENT, deleteEvent);
  yield takeLatest(GET_EVENT_GUESTLIST, getEventGuestlist);
  yield takeLatest(GET_EVENT_TICKET, getEventTicket);
  yield takeLatest(DELETE_RSVP, removeRSVP);
}
