import React, { useEffect, useState } from "react";
import "./eventWhenCreate.css";
import CreationNavBar from "../../../components/eventCreate/creationNavBar/creationNavBar";
import UncheckedSquare from "../../../assets/UncheckedSquare.png";
import CheckedSquare from "../../../assets/CheckedSquare.png";
import { useDispatch, useSelector } from "react-redux";
import { SET_CREATION_STEP } from "../../../redux/action/createAction";
import EventWhenTags from "./eventWhenTags/eventWhenTags";
import { nearbyDateTagList } from "./eventWhenCreateUtils";
import { ConfigProvider, DatePicker, TimePicker } from "antd";
import dayjs from "dayjs";
import CalendarPreview from "./calendarPreview/calendarPreview";

function EventWhenCreate() {
  const dispatch = useDispatch();
  const creation = useSelector((state) => state.createReducer.currentCreation);
  const [hasExactDate, setHasExactDate] = useState(true);

  useEffect(() => {
    setHasExactDate(creation.exact_date);
    if (creation.exact_date) {
      dispatch({
        type: SET_CREATION_STEP,
        payload: {
          ...creation,
          event_date_tags: [],
          event_start_granularity: "Exact date",
        },
      });
    } else {
      dispatch({
        type: SET_CREATION_STEP,
        payload: {
          ...creation,
          event_start_granularity: "",
        },
      });
    }
  }, [creation.exact_date]);

  return (
    <>
      <div className="eventCreationLeft invisiScroll">
        <div>
          <div className="eventCreationTitle">When is it all going to happen</div>
          <div className="eventCreationSubTitle">Decide a date & time, or give a general timeline for your event.</div>
          <div className="eventCreationOptionsHolder">
            {/* Exact Date */}
            <div className="eventCreationOption">
              <img
                src={hasExactDate ? CheckedSquare : UncheckedSquare}
                className="whereCheckIcon clickable"
                onClick={() => {
                  dispatch({
                    type: SET_CREATION_STEP,
                    payload: {
                      ...creation,
                      exact_date: true,
                    },
                  });
                }}
              />
              <div className="eventCreationEntry">
                <div
                  className={`eventCreationOptionTitle ${hasExactDate ? "" : "whereHiddenText"}`}
                  onClick={() => {
                    dispatch({
                      type: SET_CREATION_STEP,
                      payload: {
                        ...creation,
                        exact_date: true,
                      },
                    });
                  }}
                >
                  I have an exact date range & time.
                </div>
                {(hasExactDate || hasExactDate === 1) && (
                  <div>
                    <div className={`eventCreationOptionSubTitle ${hasExactDate ? "" : "whereHiddenText"}`}>Enter your chosen date & time.</div>
                    <div className="roughlyWhen">
                      <ConfigProvider
                        theme={{
                          token: {
                            // Seed Token
                            colorPrimary: "#686868",
                          },
                        }}
                      >
                        <DatePicker
                          className={`eventCreationInput ${hasExactDate ? "" : "whereHiddenText"}`}
                          defaultValue={creation.event_start_date ? dayjs(creation.event_start_date) : ""}
                          showNow={false}
                          minDate={dayjs()}
                          onChange={(_, dateString) => {
                            let updatedTimestamp = new Date(dateString);
                            if (creation.event_start_time) {
                              updatedTimestamp = new Date(dateString + " " + creation.event_start_time);
                            }
                            dispatch({
                              type: SET_CREATION_STEP,
                              payload: {
                                ...creation,
                                event_start_date: dateString,
                                event_start_timestamp: updatedTimestamp,
                              },
                            });
                          }}
                        />
                        <div className="eventCreationOptionTitle whereHiddenText">at</div>
                        <TimePicker
                          className={`eventCreationInput ${hasExactDate ? "" : "whereHiddenText"}`}
                          defaultValue={creation.event_start_time ? dayjs(creation.event_start_time, "HH:mm") : ""}
                          format={"HH:mm"}
                          showNow={false}
                          minuteStep={15}
                          needConfirm={false}
                          onChange={(_, timeString) => {
                            const updatedTimestamp = new Date(creation.event_start_date + " " + timeString);
                            dispatch({
                              type: SET_CREATION_STEP,
                              payload: {
                                ...creation,
                                event_start_time: timeString !== "" ? timeString : "00:00",
                                event_start_timestamp: updatedTimestamp,
                              },
                            });
                          }}
                        />
                      </ConfigProvider>
                    </div>
                    <div className="whereTags">
                      <EventWhenTags text="Exact date" isGranularity={true} />
                      <EventWhenTags text="±1 day" isGranularity={true} />
                      <EventWhenTags text="±2 days" isGranularity={true} />
                      <EventWhenTags text="±3 days" isGranularity={true} />
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/* Rough Date */}
            <div className="eventCreationOption lowerOption">
              <img
                src={!hasExactDate ? CheckedSquare : UncheckedSquare}
                className="whereCheckIcon clickable"
                onClick={() => {
                  dispatch({
                    type: SET_CREATION_STEP,
                    payload: {
                      ...creation,
                      exact_date: false,
                    },
                  });
                }}
              />
              <div className="eventCreationEntry">
                <div
                  className={`eventCreationOptionTitle ${hasExactDate ? "whereHiddenText" : ""}`}
                  onClick={() => {
                    dispatch({
                      type: SET_CREATION_STEP,
                      payload: {
                        ...creation,
                        exact_date: false,
                      },
                    });
                  }}
                >
                  I&apos;m still deciding on a date.
                </div>
                {!hasExactDate && (
                  <div>
                    <div className={`eventCreationOptionSubTitle ${hasExactDate ? "whereHiddenText" : ""}`}>
                      Give us an idea of when your event might take place.
                    </div>
                    <div className="whereTags">
                      {nearbyDateTagList().map((tag, index) => (
                        <EventWhenTags key={index} text={tag} isGranularity={false} />
                      ))}
                    </div>
                    <div className="whereTagsWeekend">
                      <EventWhenTags text="Weekday" isGranularity={false} />
                      <EventWhenTags text="Weekend" isGranularity={false} />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <CreationNavBar />
      </div>
      <div className="eventCreationRight">
        <CalendarPreview hasExactDate={hasExactDate} />
      </div>
    </>
  );
}

export default EventWhenCreate;
