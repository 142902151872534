import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  DELETE_CONTRIBUTION,
  DELETE_DASHBOARD,
  DELETE_POLL,
  GET_DASHBOARD,
  POST_CONTRIBUTION,
  POST_DASHBOARD,
  POST_POLL,
  REQUEST_CONTRIBUTION,
  SET_DASHBOARD,
  SET_EDIT_WIDGET_CONTENT,
  TOGGLE_EDIT_WIDGET,
  UPDATE_DASHBOARD_INDEX,
  VOTE_POLL,
  VOTE_PROMPT,
} from "../action/dashboardAction";
import { deleteDashboardApi, getDashboardApi, postDashboardApi, updateDashboardIndexApi } from "../api/dashboardApi";
import { deletePollApi, postPollApi, votePollApi } from "../api/pollApi";
import { v4 as uuidv4 } from "uuid";
import { votePromptApi } from "../api/promptApi";
import { deleteContributionApi, postContributionApi, postContributionRequestApi } from "../api/widgets/contributionApi";

export function* postPoll({ payload }) {
  console.log("saving poll");
  yield call(postPollApi, payload);
}

export function* postDashboard({ payload }) {
  yield call(postDashboardApi, payload);
  console.log(payload);
  if (payload.type === "poll") {
    yield all(
      payload.answers.map((answer) => {
        const responseBody = {
          id: answer.poll_id ?? uuidv4(),
          dashboard_id: payload.id,
          question: answer.question,
        };
        return call(postPollApi, responseBody);
      }),
    );
  }
  yield put({ type: TOGGLE_EDIT_WIDGET });
  yield put({ type: SET_EDIT_WIDGET_CONTENT, payload: {} });
  yield put({ type: GET_DASHBOARD, payload: payload.event_id });
}

export function* getDashboard({ payload }) {
  const dashboard = yield call(getDashboardApi, payload);
  if (dashboard) {
    yield put({ type: SET_DASHBOARD, payload: dashboard });
  }
}

export function* votePoll({ payload }) {
  yield call(votePollApi, payload);
  yield put({ type: GET_DASHBOARD, payload: payload.event_id });
}

export function* deletePoll({ payload }) {
  yield call(deletePollApi, payload);
}

export function* deleteDashboard({ payload }) {
  yield call(deleteDashboardApi, payload.dashboard_id);
  yield put({ type: TOGGLE_EDIT_WIDGET });
  yield put({ type: SET_EDIT_WIDGET_CONTENT, payload: {} });
  yield put({ type: GET_DASHBOARD, payload: payload.event_id });
}

export function* updateDashboardIndex({ payload }) {
  yield call(updateDashboardIndexApi, payload);
}

export function* votePrompt({ payload }) {
  yield call(votePromptApi, payload);
}

export function* postContribution({ payload }) {
  yield call(postContributionApi, payload);
}

export function* postContributionRequest({ payload }) {
  yield call(postContributionRequestApi, payload);
}

export function* deleteContribution({ payload }) {
  yield call(deleteContributionApi, payload);
}

export default function* dashboardSaga() {
  yield takeLatest(POST_DASHBOARD, postDashboard);
  yield takeLatest(GET_DASHBOARD, getDashboard);
  yield takeLatest(POST_POLL, postPoll);
  yield takeLatest(VOTE_POLL, votePoll);
  yield takeLatest(DELETE_POLL, deletePoll);
  yield takeLatest(DELETE_DASHBOARD, deleteDashboard);
  yield takeLatest(UPDATE_DASHBOARD_INDEX, updateDashboardIndex);
  yield takeLatest(VOTE_PROMPT, votePrompt);
  yield takeLatest(POST_CONTRIBUTION, postContribution);
  yield takeLatest(REQUEST_CONTRIBUTION, postContributionRequest);
  yield takeLatest(DELETE_CONTRIBUTION, deleteContribution);
}
