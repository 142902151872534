import React from "react";
import { useEffect, useState } from "react";
import "./upcomingEvents.css";
import { useDispatch, useSelector } from "react-redux";
import HorizontalEventCard from "../../cards/horizontalEventCard/horizontalEventCard";
import { AutoComplete } from "antd";
import searchIcon from "../../../assets/Search.png";
import ngeohash from "ngeohash";

import Radar from "radar-sdk-js";
import "radar-sdk-js/dist/radar.css";
import { GET_NEW_EVENTS } from "../../../redux/action/eventAction";

Radar.initialize(process.env.REACT_APP_RADAR_API);

function UpcomingEvents() {
  const dispatch = useDispatch();
  const [options, setOptions] = useState([]);
  const [coOrdinates, setCoOrdinates] = useState([0, 0]);
  const [addressQuery, setAddressQuery] = useState("");
  const [inputwidth, setinputWidth] = useState(130);
  const [loadTo, setLoadTo] = useState(10);
  const newEvents = useSelector((state) => state.landingReducer.newEvents);
  const [nextEventMap, setNextEventMap] = useState({});

  const widths = [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.2796875, 0.2765625, 0.3546875, 0.5546875, 0.5546875,
    0.8890625, 0.665625, 0.190625, 0.3328125, 0.3328125, 0.3890625, 0.5828125, 0.2765625, 0.3328125, 0.2765625, 0.3015625, 0.5546875, 0.5546875, 0.5546875,
    0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.2765625, 0.2765625, 0.584375, 0.5828125, 0.584375, 0.5546875, 1.0140625,
    0.665625, 0.665625, 0.721875, 0.721875, 0.665625, 0.609375, 0.7765625, 0.721875, 0.2765625, 0.5, 0.665625, 0.5546875, 0.8328125, 0.721875, 0.7765625,
    0.665625, 0.7765625, 0.721875, 0.665625, 0.609375, 0.721875, 0.665625, 0.94375, 0.665625, 0.665625, 0.609375, 0.2765625, 0.3546875, 0.2765625, 0.4765625,
    0.5546875, 0.3328125, 0.5546875, 0.5546875, 0.5, 0.5546875, 0.5546875, 0.2765625, 0.5546875, 0.5546875, 0.221875, 0.240625, 0.5, 0.221875, 0.8328125,
    0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.3328125, 0.5, 0.2765625, 0.5546875, 0.5, 0.721875, 0.5, 0.5, 0.5, 0.3546875, 0.259375, 0.353125, 0.5890625,
  ];
  const avg = 0.5279276315789471;

  function measureText(str, fontSize) {
    return Array.from(str).reduce((acc, cur) => acc + (widths[cur.charCodeAt(0)] ?? avg), 0) * fontSize;
  }

  const onChange = (e) => {
    let length = e.length;
    if (length === 0) {
      setinputWidth(130);
    }
    if (inputwidth <= 405) {
      if (inputwidth === 405) {
        const newWidth = measureText(e, 26);
        setinputWidth(Math.min(newWidth, 405));
      } else {
        const newWidth = measureText(e, 26);
        setinputWidth(Math.max(130, newWidth));
      }
    }
  };

  useEffect(() => {
    if (addressQuery === "") {
      return;
    }
    const timeOutId = setTimeout(() => {
      Radar.autocomplete({
        query: addressQuery,
        limit: 3,
        layers: ["locality", "country"],
      })
        .then((result) => {
          const { addresses } = result;
          const addressStringList = addresses.map((address) => {
            return { value: address.addressLabel, key: address.distance};
          });
          setOptions(addressStringList);
          const coOrdinates = addresses.map((address) => {
            return { location: address.addressLabel, coordinates: address.geometry.coordinates };
          });
          setCoOrdinates(coOrdinates);
        })
        .catch((err) => {
          // handle error
          console.log(err);
        });
    }, 300);
    return () => clearTimeout(timeOutId);
  }, [addressQuery]);

  useEffect(() => {
    const nextEventMap = {};
    newEvents.slice(0, loadTo).forEach((event) => {
      const eventDate = new Date(event.event_start_time);
      const eventDateString = eventDate.toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
        weekday: "long",
      });

      if (nextEventMap[eventDateString]) {
        nextEventMap[eventDateString].push(event);
      } else {
        nextEventMap[eventDateString] = [event];
      }
    });

    setNextEventMap(nextEventMap);
  }, [newEvents, loadTo]);

  return (
    <div className="upcomingEvents">
      <div className="upcomingEventsTitle">
        <div>Upcoming events in </div>
        <AutoComplete
          id="location-autocomplete"
          className="location-autocomplete"
          options={options}
          defaultValue="Vancouver"
          placeholder="Vancouver"
          onChange={onChange}
          style={{ width: inputwidth }}
          onSelect={(e) => {
            // match e with coOrdinates and log the coOrdinates
            const selectedCoOrdinates = coOrdinates.find((coOrdinate) => coOrdinate.location === e);
            const hash = ngeohash.encode(selectedCoOrdinates.coordinates[1], selectedCoOrdinates.coordinates[0])
            dispatch({ type: GET_NEW_EVENTS, payload: hash.slice(0, 4) });
          }}
          onSearch={(e) => {
            setAddressQuery(e);
          }}
        />
        <img className="search-icon" src={searchIcon} alt="search" />
      </div>
      {Object.keys(nextEventMap).map((date) => (
        <div className="upcomingEventsHolder" key={date}>
          <div className="date-text">
            {date.split(",")[1]}
            <span className="upcomingDayOfWeek">{date.split(",")[0]}</span>
          </div>
          {nextEventMap[date].map((event) => (
            <HorizontalEventCard key={event.event_id} event={event} />
          ))}
        </div>
      ))}
      {loadTo < 100 && loadTo < newEvents.length ? (
        <div
          className="see-more"
          onClick={() => {
            setLoadTo(loadTo + 10);
          }}
        >
          See more
        </div>
      ) : null}
    </div>
  );
}

export default UpcomingEvents;
