/* eslint-disable indent */
import React, { useEffect, useState } from "react";
import "./cardFundingBar.css";

function CardFundingBar({ event, mode }) {
  const percentage = Math.min((event.event_backers / Math.max(event.event_capacity ?? 1, 1)) * 100, 100);
  const [tileFundingBarClass, setTileFundingBarClass] = useState("EventTileFundingBar");
  const [backBarClass, setBackBarClass] = useState("EventTileFundingBarBack");

  useEffect(() => {
    switch (mode) {
      case "whiteOutline":
        setTileFundingBarClass("EventTileFundingBar FundingBarOutlineBlack");
        setBackBarClass("FundingBarBackWhite");
        return;
      case true:
        setBackBarClass("EventTileFundingBarBackDark");
        return;
      default:
        setBackBarClass("EventTileFundingBarBack");
        return;
    }
  }, [mode]);

  return (
    <div className="EventTileProgress">
      <div className={tileFundingBarClass}>
        <div className={backBarClass} />
        <div
          className={mode ? "EventTileFundingBarFilledDark" : "EventTileFundingBarFilled"}
          style={{ width: `${percentage}%` }}
        />
      </div>
      <div className={mode ? "EventTileInfoTitleFundingDark" : "EventTileInfoTitleFunding"}>
        {percentage.toFixed(0)}%
      </div>
    </div>
  );
}

export default CardFundingBar;
