import React from "react";
import "./invoiceWidget.css";
import { useSelector } from "react-redux";
import { getReceiptApi } from "../../../redux/api/paymentApi";

function InvoiceWidget() {
  const currentUser = useSelector((state) => state.userReducer.currentUser);
  const event = useSelector((state) => state.eventReducer.currentEvent);
  const eventTransactions = useSelector((state) => state.eventReducer.currentEventTransactions);
  const myTickets = eventTransactions.filter((transaction) => transaction.transaction_ticket_holder === currentUser.user_id);

  return (
    <div className="widgetItem">
      <div className="widgetTitle">Your Order</div>
      <div className="widgetContent">
        <div>
          {myTickets.map((ticket, index) => {
            if (index > 1 || event?.event_tiers === undefined || event?.event_tiers === null || event?.event_tiers?.length === 0) return null;
            return (
              <div className="invoiceWidgetEntry" key={ticket}>
                1x {event.event_tiers.find((tier) => tier.event_tier_id === ticket.transaction_tier)?.event_tier_name ?? "Unknown Tier"}
              </div>
            );
          })}
          {myTickets.length > 2 ? <div className="invoiceWidgetMoreEntry">& {myTickets.length - 2} more ticket types</div> : null}
          {myTickets.length === 0 ? <div className="noTicketText">Your order will appear here once you have purchased one. </div> : null}
        </div>
        {myTickets.length > 0 ? (
          <div
            className="invoiceWidgetView"
            onClick={async () => {
              const paymentIntent = myTickets[0].payment_intent_id;
              const receiptLink = await getReceiptApi(paymentIntent);
              if (receiptLink !== undefined && receiptLink !== null) {
                window.location.href = receiptLink;
              }
            }}
          >
            View Invoice
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default InvoiceWidget;
