import React, { useState } from "react";
import "./ticketWidget.css";
import ArrowIconBlack from "../../../assets/ArrowIconBlack.png";
import { useSelector } from "react-redux";
import QRCode from "react-qr-code";

function TicketWidget() {
  const currentUser = useSelector((state) => state.userReducer.currentUser);
  const eventTransactions = useSelector((state) => state.eventReducer.currentEventTransactions);
  const myTickets = eventTransactions.filter((transaction) => transaction.transaction_ticket_holder === currentUser.user_id);
  const [ticketPage, setTicketPage] = useState(0);

  return (
    <div className="ticketHolder">
      <div className="qrTitle">
        <div className="widgetTitle">Your Ticket</div>
        {myTickets.length > 0 ? <div className="qrPages">{`${ticketPage + 1}/${myTickets.length}`}</div> : null}
      </div>
      <div className="qrSlider">
        <div className="sliderArrow sliderArrowLeft">
          {ticketPage > 1 ? (
            <img
              src={ArrowIconBlack}
              alt="Next"
              onClick={() => {
                if (ticketPage > 0) {
                  setTicketPage(ticketPage - 1);
                }
              }}
            />
          ) : null}
        </div>
        <div className="qrCode">
          {myTickets.length > 0 ? (
            <QRCode style={{ height: "100%" }} value={myTickets[ticketPage].transaction_id} />
          ) : (
            <div className="noTicketText">Your ticket will appear here once you have purchased one. </div>
          )}
        </div>
        <div className="sliderArrow">
          {ticketPage + 1 < myTickets.length ? (
            <img
              src={ArrowIconBlack}
              alt="Next"
              onClick={() => {
                if (ticketPage < myTickets.length - 1) {
                  setTicketPage(ticketPage + 1);
                }
              }}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default TicketWidget;
