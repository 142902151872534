import React, { useEffect, useState } from "react";
import "./hostCardView.css";
import { useSelector } from "react-redux";
import ProfilePicture from "../user/profilePicture/profilePicture";
import { getEventGuestlistApi } from "../../redux/api/eventApi";

function HostCardView({ event }) {
  const currentUser = useSelector((state) => state.userReducer.currentUser);
  const [attendees, setAttendees] = useState([]);

  useEffect(() => {
    getEventGuestlistApi(event.event_id).then((res) => {
      const otherAttendees = res.data.filter((attendee) => attendee.user_id !== currentUser.user_id && attendee.user_id !== event.user_id);
      setAttendees(otherAttendees);
    });
  }, [event]);

  return (
    <div className="host-details">
      <ProfilePicture className={"host-image"} userId={event.user_id} />
      <div className="host-name">{currentUser.user_id === event.user_id ? (attendees.length >= 1 ? "You" : "Your Event") : event.user_first_name}</div>
      {attendees.length >= 1 && (
        <div className="attendee-details">
          &amp;
          <div className="attendees">
            {attendees.map((attendee) => (
              <ProfilePicture key={attendee.user_id} className={"attendee-image"} userId={attendee.user_id} hideInitials={true} />
            ))}
          </div>
          <div className="attendee-count">{attendees.length !== 1 ? `${attendees.length} others` : `${attendees[0].user_first_name}`}</div>
        </div>
      )}
    </div>
  );
}

export default HostCardView;
