import React, { useEffect, useState } from "react";
import "./bringWidget.css";
import "../widgetStyling.css";
import { useDispatch, useSelector } from "react-redux";
import {
  DELETE_CONTRIBUTION,
  POST_CONTRIBUTION,
  SET_EDIT_WIDGET_CONTENT,
  SET_EDIT_WIDGET_STEP,
  TOGGLE_EDIT_WIDGET,
} from "../../../redux/action/dashboardAction";
import { getContributionApi } from "../../../redux/api/widgets/contributionApi";
import { BringingTag, IBringingTag, RequestItemTag, RequestedTag } from "./bringingWidgetTags";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import { ExpandAll, ExpandBring } from "./bringingWidgetExpand";

function BringWidget({ live, data }) {
  const dispatch = useDispatch();
  const isDesktop = useMediaQuery("(min-width: 768px)");
  const currentUser = useSelector((state) => state.userReducer.currentUser);
  const eventTicket = useSelector((state) => state.eventReducer.currentEventTicket);
  const showEditWidget = useSelector((state) => state.dashboardReducer.showEditWidget);
  const [showExpand, setShowExpand] = useState(false);
  const [expandMode, setExpandMode] = useState("bring"); // bring, all
  const [bringingList, setBringingList] = useState([]);
  const [myBringingList, setMyBringingList] = useState([]);
  const [requestedList, setRequestedList] = useState([]);
  const [contributions, setContributions] = useState([]);

  if (data === undefined) {
    return null;
  }

  useEffect(() => {
    getContributions();
  }, []);

  function getContributions() {
    if (live) {
      getContributionApi(data.dashboard_id).then((response) => {
        if (response && response.length > 0) {
          setContributions(response);
        } else {
          setContributions([]);
        }
      });
    }
  }

  useEffect(() => {
    if (contributions.length > 0) {
      const bringing = [];
      const myBringing = [];
      const requested = [];
      contributions.forEach((contribution) => {
        if (contribution.brought) {
          if (contribution.brought_by === currentUser.user_id) {
            myBringing.push(contribution);
          } else {
            bringing.push(contribution);
          }
        } else {
          requested.push(contribution);
        }
      });
      setBringingList(bringing);
      setMyBringingList(myBringing);
      setRequestedList(requested);
    } else {
      setBringingList([]);
      setMyBringingList([]);
      setRequestedList([]);
    }
  }, [contributions, currentUser]);

  function expandWidget(mode) {
    if (currentUser.user_id && eventTicket.length > 0) {
      setExpandMode(mode);
      setShowExpand(true);
    } else {
      alert("Please RSVP to contribute.");
    }
  }

  return (
    <div
      id="bringWidget"
      className={`widgetView bringingLists ${!live && !showEditWidget ? "clickable" : ""}`}
      onClick={() => {
        if (!live && !showEditWidget) {
          dispatch({ type: TOGGLE_EDIT_WIDGET });
          dispatch({
            type: SET_EDIT_WIDGET_CONTENT,
            payload: {
              type: "bring",
              size: data.component_size,
              id: data.dashboard_id,
              index: data.component_index,
              title: data.title,
            },
          });
          dispatch({ type: SET_EDIT_WIDGET_STEP, payload: "bring" });
        }
      }}
    >
      <div className="widgetTitle">{"Who's Bringing What?"}</div>
      <div className="bringWidgetHolder">
        {bringingList.length <= 0 && myBringingList.length <= 0 ? (
          <div className="noContributionArea">
            <div className="semibold noContributionText">Be the first to contribute.</div>
          </div>
        ) : null}
        {bringingList.length > 0 ? (
          <div className="currentlyBringingSection">
            <div className="currentlyBringingLimited">
              {bringingList.map((contribution) => (
                <BringingTag key={contribution.contribution_id} name={contribution.item_name} userId={contribution.brought_by} />
              ))}
            </div>
            <div
              className="moreBringingText"
              onClick={() => {
                expandWidget("all");
              }}
            >
              {bringingList.length} contributions
            </div>
          </div>
        ) : null}
        {myBringingList.length > 0 ? (
          <div className="myBringingSection">
            <div className="semibold">I am bringing...</div>
            <div className="currentlyBringingLimited">
              {myBringingList.map((contribution) => (
                <IBringingTag
                  key={contribution.contribution_id}
                  name={contribution.item_name}
                  clickAction={() => {
                    if (contribution && contribution.requested_by === currentUser.user_id) {
                      dispatch({
                        type: DELETE_CONTRIBUTION,
                        payload: contribution.contribution_id,
                      });
                    } else if (currentUser.user_id && eventTicket.length > 0) {
                      dispatch({
                        type: POST_CONTRIBUTION,
                        payload: {
                          ...contribution,
                          id: contribution.contribution_id,
                          brought_by: null,
                          brought: false,
                        },
                      });
                    } else {
                      alert("Please RSVP to contribute.");
                    }
                    setTimeout(() => {
                      getContributions();
                    }, 1000);
                  }}
                />
              ))}
            </div>
          </div>
        ) : null}
        <div className="requestedSection">
          {isDesktop && requestedList.length > 0 ? <div className="semibold">Contribute to the list</div> : null}
          <div className="requestedBringing">
            {isDesktop ? (
              <div className="currentlyBringing">
                {requestedList.slice(0, 3).map((contribution) => (
                  <RequestedTag
                    key={contribution.contribution_id}
                    contribution={contribution}
                    clickAction={() => {
                      if (currentUser.user_id && eventTicket.length > 0) {
                        dispatch({
                          type: POST_CONTRIBUTION,
                          payload: {
                            ...contribution,
                            id: contribution.contribution_id,
                            brought_by: currentUser.user_id,
                            brought: true,
                          },
                        });
                        setTimeout(() => {
                          getContributions();
                        }, 1000);
                      } else {
                        alert("Please RSVP to contribute.");
                      }
                    }}
                  />
                ))}
              </div>
            ) : null}
            <RequestItemTag
              clickAction={() => {
                expandWidget("bring");
              }}
            />
          </div>
        </div>
      </div>
      {showExpand ? (
        <div
          className="popupBackground"
          onClick={(e) => {
            e.stopPropagation();
            setShowExpand(false);
          }}
        >
          {expandMode === "all" ? (
            <ExpandAll
              bringingList={bringingList}
              actionRefresh={(close) => {
                setTimeout(() => {
                  getContributions();
                }, 1000);
                if (close) {
                  setShowExpand(false);
                }
              }}
            />
          ) : null}
          {expandMode === "bring" ? (
            <ExpandBring
              widgetData={data}
              requestedList={requestedList}
              actionRefresh={(close) => {
                setTimeout(() => {
                  getContributions();
                }, 1000);
                if (close) {
                  setShowExpand(false);
                }
              }}
            />
          ) : null}
        </div>
      ) : null}
    </div>
  );
}

export default BringWidget;
