import React from "react";
import "./transactionBackButton.css";
import { useDispatch, useSelector } from "react-redux";
import { SET_CART } from "../../../../redux/action/cartAction";
import { useNavigate } from "react-router-dom";

function TransactionBackButton() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cart = useSelector((state) => state.cartReducer.currentCart);

  const navigationMap = {
    price: "price",
    time: "price",
    note: "time",
    payment: "note",
  };

  return (
    <div
      className="TransactionBackButton"
      onClick={() => {
        if (cart.stage === "price") {
          navigate(-1);
        } else {
          dispatch({
            type: SET_CART,
            payload: {
              ...cart,
              stage: navigationMap[cart.stage],
            },
          });
        }
      }}
    >
      <div className="TransactionBackText">Go Back</div>
    </div>
  );
}

export default TransactionBackButton;
