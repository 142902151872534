import React, { useEffect, useState } from "react";
import "./newsletterTitle.css";
import { useSelector } from "react-redux";

function NewsletterTitle() {
  const currentUser = useSelector((state) => state.userReducer.currentUser);
  const nearbyEvents = useSelector((state) => state.landingReducer.nearbyEvents);
  const [attendees, setAttendees] = useState(0);

  useEffect(() => {
    let totalAttendees = 0;
    nearbyEvents.forEach((event) => {
      totalAttendees += event.event_backers;
    });
    setAttendees(totalAttendees);
  }, [nearbyEvents]);

  return (
    <div className="newsletterTitle">
      <div className="newsletterGreeting">Hello {currentUser.user_first_name} 👋</div>
      <div className="newsletterText">
        There are
        <span className="newsletterNumber">
          <span className="newsletterContent">
            <span>{nearbyEvents.length}</span>
          </span>
        </span>
        events near you, <br /> attended by
        <span className="newsletterNumber newsletterImg2">
          <span className="newsletterContent">
            <span>{attendees}</span>
          </span>
        </span>
        potential new connections.
      </div>
    </div>
  );
}

export default NewsletterTitle;
