export const seasonMap = {
  Spring: [2, 3, 4],
  Summer: [5, 6, 7],
  Fall: [8, 9, 10],
  Winter: [11, 0, 1],
};

export const monthMap = {
  January: 0,
  February: 1,
  March: 2,
  April: 3,
  May: 4,
  June: 5,
  July: 6,
  August: 7,
  September: 8,
  October: 9,
  November: 10,
  December: 11,
};

export function nearbyDateTagList() {
  const date = new Date();
  const currentMonth = date.getMonth();
  const currentYear = date.getFullYear();
  const nextYear = date.getFullYear() + 1;
  const nextThreeMonthsMap = {
    0: ["January", "February", "March", `Spring ${currentYear}`, `Summer ${currentYear}`, `Fall ${currentYear}`, `Winter ${currentYear}`],
    1: ["February", "March", "April", `Spring ${currentYear}`, `Summer ${currentYear}`, `Fall ${currentYear}`, `Winter ${currentYear}`],
    2: ["March", "April", "May", `Spring ${currentYear}`, `Summer ${currentYear}`, `Fall ${currentYear}`, `Winter ${currentYear}`],
    3: ["April", "May", "June", `Spring ${currentYear}`, `Summer ${currentYear}`, `Fall ${currentYear}`, `Winter ${currentYear}`],
    4: ["May", "June", "July", `Spring ${currentYear}`, `Summer ${currentYear}`, `Fall ${currentYear}`, `Winter ${currentYear}`],
    5: ["June", "July", "August", `Summer ${currentYear}`, `Fall ${currentYear}`, `Winter ${currentYear}`, `Spring ${nextYear}`],
    6: ["July", "August", "September", `Summer ${currentYear}`, `Fall ${currentYear}`, `Winter ${currentYear}`, `Spring ${nextYear}`],
    7: ["August", "September", "October", `Summer ${currentYear}`, `Fall ${currentYear}`, `Winter ${currentYear}`, `Spring ${nextYear}`],
    8: ["September", "October", "November", `Fall ${currentYear}`, `Winter ${currentYear}`, `Spring ${nextYear}`, `Summer ${nextYear}`],
    9: ["October", "November", "December", `Fall ${currentYear}`, `Winter ${currentYear}`, `Spring ${nextYear}`, `Summer ${nextYear}`],
    10: ["November", "December", "January", `Winter ${currentYear}`, `Spring ${nextYear}`, `Summer ${nextYear}`, `Fall ${nextYear}`],
    11: ["December", "January", "February", `Winter ${currentYear}`, `Spring ${nextYear}`, `Summer ${nextYear}`, `Fall ${nextYear}`],
  };
  return nextThreeMonthsMap[currentMonth];
}
