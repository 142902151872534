import React from "react";
import "./creationTopNavBar.css";
import { useDispatch, useSelector } from "react-redux";
import { SET_CREATION_STEP } from "../../../redux/action/createAction";
import { canProceedToNext } from "../../button/createEventNextButton/createEventUtils";

function CreationTopNavBar() {
  const dispatch = useDispatch();
  const creation = useSelector((state) => state.createReducer.currentCreation);
  const pageList = [1, 2, 3, 4];

  return (
    <>
      <div className="creationNavBarTop">
        {creation.stage > 0 && creation.stage < 5 && (
          <div className="creationNavBarTopHolder">
            {pageList.map((page) => {
              return (
                <div
                  key={page}
                  className={`navBarPageTab ${creation.stage > page - 1 ? "navBarPageTabSelected" : ""} ${creation.maxStage > page - 1 ? "clickable" : ""}`}
                  onClick={() => {
                    if (creation.maxStage > page - 1 && ((page - 1) < creation.stage) || canProceedToNext(creation)) {
                      dispatch({
                        type: SET_CREATION_STEP,
                        payload: {
                          ...creation,
                          stage: page,
                        },
                      });
                    }
                  }}
                />
              );
            })}
          </div>
        )}
      </div>
    </>
  );
}

export default CreationTopNavBar;
