import React, { useEffect, useState } from "react";
import "./verticalEventCard.css";
import { S3Bucket } from "../../../Constants";
import HostCardView from "../../hostCardView/hostCardView";
import { ConfigProvider, Progress } from "antd";
import { useNavigate } from "react-router-dom";

function VerticalEventCard({ event }) {
  const navigate = useNavigate();
  const [eventDateString, setEventDateString] = useState("");
  const [eventTimeString, setEventTimeString] = useState("");

  useEffect(() => {
    const eventDate = new Date(event.event_start_time);
    const eventTime = eventDate.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
    const eventDay = eventDate.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
    });
    setEventDateString(eventDay);
    setEventTimeString(eventTime);
  }, [event.event_start_time]);

  return (
    <div
      className="verticalCard"
      onClick={() => {
        navigate(`/details/${event.event_id}`);
      }}
    >
      <div className="verticalCardEventContent">
        <div className="event-details">
          <HostCardView event={event} />
          <div className="verticalCardTldr">{event.event_tldr}</div>
        </div>
        <div className="event-date">
          {eventDateString} <span className="semibold">@</span> {eventTimeString}
        </div>
      </div>
      <img
        className="verticalCardImage"
        src={event.event_picture.includes("http") ? event.event_picture : `${S3Bucket}event/${event.event_id}/${event.event_id}.jpg`}
      ></img>
      {event.event_type === "crowdfund" ? (
        <div className="funding-status-box">
          <div className="fundingCircleInnerBlack"></div>
          <div className="fundingCircleInner"></div>
          <ConfigProvider
            theme={{
              components: {
                Progress: {
                  circleTextColor: "white",
                },
              },
            }}
          >
            <Progress
              className="fundingCircle"
              type="circle"
              size={"small"}
              percent={(event.fundedAmount / (event.event_fund_target ?? 1)) * 100}
              strokeWidth={16}
              strokeLinecap="butt"
              trailColor="transparent"
              strokeColor={"white"}
            />
          </ConfigProvider>
        </div>
      ) : null}
    </div>
  );
}

export default VerticalEventCard;
