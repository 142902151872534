import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./transaction.css";
import Checkout from "../../../components/transaction/checkout/checkout";
import PriceTier from "../../../components/transaction/priceTier/priceTier";
import { GET_EVENT, GET_EVENT_TIERS } from "../../../redux/action/eventAction";
import { useNavigate } from "react-router-dom";

function EventTransaction() {
  const navigate = useNavigate();
  const eventId = window.location.pathname.split("/")[2];
  const dispatch = useDispatch();
  const event = useSelector((state) => state.eventReducer.currentEvent);
  // const cart = useSelector((state) => state.cartReducer.currentCart);

  useEffect(() => {
    console.log("eventId: ", eventId);
    dispatch({ type: GET_EVENT, payload: eventId });
    dispatch({ type: GET_EVENT_TIERS, payload: eventId });
  }, [dispatch, eventId]);

  useEffect(() => {
    handleSoldOut();
  }, [event]);

  function handleSoldOut() {
    if (event) {
      console.log("event: ", event);  
      if ((event?.event_backers ?? 0) >= (event?.event_capacity ?? 0)) {
        navigate(`/details/${event.event_id}`);
      }
    }
  }

  if (event) {
    return (
      <div className="transactionScreen">
        <div className="transactionSelection">
          <PriceTier />
        </div>
        <Checkout />
      </div>
    );
  }

  return null;
}

export default EventTransaction;
