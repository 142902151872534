import React from "react";
import "./priceTier.css";
import { useSelector } from "react-redux";
import TransactionTitle from "../transactionTitle/transactionTitle";
import TransactionNavigation from "../transactionNavigation/transactionNavigation";
import WhiteTier from "./whiteTier/whiteTier";
import BlackTier from "./blackTier/blackTier";

function PriceTier() {
  // const event = useSelector((state) => state.eventReducer.currentEvent);
  const eventTiers = useSelector((state) => state.eventReducer.currentEventTiers);
  const cart = useSelector((state) => state.cartReducer.currentCart);

  console.log(cart)

  return (
    <div className="priceTierSection">
      <TransactionTitle title={"Choose your Price Tier"} />
      <div className="priceTierEntrySection">
        {eventTiers.map((item, index) => {
          return cart.cartContent[item.event_tier_id]?.quantity ? (
            <BlackTier
              key={item.event_tier_id}
              item={item}
              first={index % 4 === 0}
              last={index % 4 === 3}
            />
          ) : (
            <WhiteTier
              key={item.event_tier_id}
              item={item}
              first={index % 4 === 0}
              last={index % 4 === 3}
            />
          );
        })}
      </div>
      <TransactionNavigation />
    </div>
  );
}

export default PriceTier;
