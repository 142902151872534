import defaultAxios from "axios";
import { backend } from "../../Constants";

const axios = defaultAxios.create({
  baseURL: backend,
  headers: { "Content-Type": "application/json" },
});

export const getEventApi = async (payload) => {
  try {
    // const token = JSON.parse(localStorage.getItem("currentUser")).token;
    // var headers = {
    //   Authorization: `Bearer ${token}`,
    // };
    if (payload) {
      const eventId = payload;
      const event = await axios.get(`event/${eventId}`, {
        // headers: headers,
      });
      return event;
    }
  } catch (err) {
    return console.error(err);
  }
};

export const getEventTierApi = async (payload) => {
  try {
    // const token = JSON.parse(localStorage.getItem("currentUser")).token;
    // var headers = {
    //   Authorization: `Bearer ${token}`,
    // };
    if (payload) {
      const eventId = payload;
      const eventTiers = await axios.get(`eventTier/${eventId}`, {
        // headers: headers,
      });
      return eventTiers;
    }
  } catch (err) {
    return console.error(err);
  }
};

export const postEventApi = async (payload) => {
  try {
    // const token = JSON.parse(localStorage.getItem("currentUser")).token;
    // var headers = {
    //   Authorization: `Bearer ${token}`,
    // };

    var data = JSON.stringify(payload);

    const event = await axios.post("event/", data, {
      // headers: headers,
    });
    return event;
  } catch (err) {
    return console.error(err);
  }
};

export const putEventApi = async (payload) => {
  try {
    // const token = JSON.parse(localStorage.getItem("currentUser")).token;
    // var headers = {
    //   Authorization: `Bearer ${token}`,
    // };

    var data = JSON.stringify(payload);

    const event = await axios.put("event/", data, {
      // headers: headers,
    });
    return event;
  } catch (err) {
    return console.error(err);
  }
};

export const postPaidEventTransactionApi = async (payload) => {
  try {
    // const token = JSON.parse(localStorage.getItem("currentUser")).token;
    // var headers = {
    //   Authorization: `Bearer ${token}`,
    // };
    var data = JSON.stringify({});

    const eventTransaction = await axios.post(`transaction/${payload.paymentIntent}/paid`, data, {
      // headers: headers,
    });
    return eventTransaction;
  } catch (err) {
    return console.error(err);
  }
};

export const deleteEventApi = async (payload) => {
  try {
    const token = JSON.parse(localStorage.getItem("currentUser"))?.token ?? "";
    var headers = {
      Authorization: `${token}`,
    };
    if (payload) {
      const eventId = payload;
      const event = await axios.delete(`event/${eventId}`, {
        headers: headers,
      });
      return event;
    }
  } catch (err) {
    return console.error(err);
  }
};

export const getEventGuestlistApi = async (payload) => {
  try {
    // const token = JSON.parse(localStorage.getItem("currentUser")).token;
    // var headers = {
    //   Authorization: `Bearer ${token}`,
    // };
    if (payload) {
      const eventId = payload;
      const guestlist = await axios.get(`event/guestlist/${eventId}`, {
        // headers: headers,
      });
      return guestlist;
    }
  } catch (err) {
    return console.error(err);
  }
};
