import { combineReducers } from "redux";
import { CLEAR_CREATION, SET_CREATION_STEP, SET_EDIT_CREATION_STEP } from "../action/createAction";

const defaultCreation = {
  stage: 0,
  maxStage: 0,
  event_type: "",
  event_name: "",
  event_tldr: "",
  event_picture: "",
  exact_location: true,
  event_location: "",
  event_location_hash: "",
  event_location_noun: "",
  event_location_place: "",
  exact_date: true,
  event_start_date: "",
  event_start_time: "",
  event_start_timestamp: "",
  event_start_granularity: "Exact date",
  event_date_tags: [],
  event_fund_target: "",
  event_tiers: [],
  terms_agreed: false,
};

const currentCreation = (state = defaultCreation, action) => {
  switch (action.type) {
  case SET_CREATION_STEP: {
    const event = action.payload;
    return event;
  }
  case SET_EDIT_CREATION_STEP: {
    // The timestamp needs to be converted to browser timezone, for edit feature only
    const date = new Date(action.payload.event_start_timestamp);
    const time = date.toTimeString().split(" ")[0];
    const event = {
      ...state,
      ...action.payload,
      event_start_date: date,
      event_start_time: time,
    };
    return event;
  }
  case CLEAR_CREATION: {
    return defaultCreation;
  }
  default: {
    return state;
  }
  }
};

export default combineReducers({
  currentCreation,
});