import React from "react";
import "../../../components/eventCreate/eventCreate.css";
import "./create.css";
import { useSelector } from "react-redux";
import EventTypeCreate from "../../../components/eventCreate/eventTypeCreate/eventTypeCreate";
import EventWhatCreate from "../../../components/eventCreate/eventWhatCreate/eventWhatCreate";
import EventWhereCreate from "../../../components/eventCreate/eventWhereCreate/eventWhereCreate";
import EventWhenCreate from "../../../views/eventCreate/eventWhenCreate/eventWhenCreate";
import EventWhoCreate from "../../../components/eventCreate/eventWhoCreate/eventWhoCreate";
import CreationTopNavBar from "../../../components/eventCreate/creationTopNavBar/creationTopNavBar";
import CreateEventConfirmation from "../../../views/eventCreate/createEventConfirmation/createEventConfirmation";

function EventCreate() {
  const creation = useSelector((state) => state.createReducer.currentCreation);

  return (
    <div className="eventCreatePage AppPadding">
      <CreationTopNavBar />
      <div className="creationPages">
        {creation.stage == 0 && <EventTypeCreate />}
        {creation.stage == 1 && <EventWhatCreate />}
        {creation.stage == 2 && <EventWhereCreate />}
        {creation.stage == 3 && <EventWhenCreate />}
        {creation.stage == 4 && <EventWhoCreate />}
        {creation.stage == 5 && <CreateEventConfirmation />}
      </div>
    </div>
  );
}

export default EventCreate;
