import React, { useEffect, useState } from "react";
import "./pollWidget.css";
import "../widgetStyling.css"
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import {
  SET_EDIT_WIDGET_CONTENT,
  SET_EDIT_WIDGET_STEP,
  TOGGLE_EDIT_WIDGET,
  VOTE_POLL,
} from "../../../redux/action/dashboardAction";
import { getUserPollVoteApi } from "../../../redux/api/pollApi";

function PollWidget({ live, data }) {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.userReducer.currentUser);
  const showEditWidget = useSelector((state) => state.dashboardReducer.showEditWidget);
  const pollMax = Math.max(...data.answers.map((poll) => poll.votes));
  const [vote, setVote] = useState({
    vote_id: uuidv4(),
    poll_id: null,
  });

  if (data === undefined) {
    return null;
  }

  useEffect(() => {
    if (live) {
      const payload = {
        dashboard_id: data.dashboard_id,
        user_id: currentUser.user_id,
      };
      getUserPollVoteApi(payload).then((response) => {
        if (response && response.length > 0) {
          setVote({
            vote_id: response[0].vote_id,
            poll_id: response[0].poll_id,
          });
        }
      });
    }
  }, []);

  return (
    <div
      className={`widgetView ${!live && !showEditWidget ? "clickable" : ""}`}
      onClick={() => {
        if (!live && !showEditWidget) {
          dispatch({ type: TOGGLE_EDIT_WIDGET });
          dispatch({
            type: SET_EDIT_WIDGET_CONTENT,
            payload: {
              type: "poll",
              title: data.title,
              answers: data.answers,
              size: data.component_size,
              id: data.dashboard_id,
              index: data.component_index,
            },
          });
          dispatch({ type: SET_EDIT_WIDGET_STEP, payload: "poll" });
        }
      }}
    >
      <div className="widgetTitle">{data.title !== "" ? data.title : "Poll"}</div>
      <div className="widgetContent">
        <div className="pollView">
          {data.answers.map((poll, index) => {
            return (
              <div
                className={`pollOption ${live ? "clickable" : ""}`}
                key={index}
                onClick={() => {
                  if (live) {
                    dispatch({
                      type: VOTE_POLL,
                      payload: {
                        vote_id: vote.vote_id,
                        poll_id: poll.poll_id,
                        dashboard_id: data.dashboard_id,
                        user_id: currentUser.user_id,
                        event_id: data.event_id,
                      },
                    });
                    setVote({
                      ...vote,
                      poll_id: poll.poll_id,
                    });
                  }
                }}
              >
                {vote?.poll_id ? (
                  <div
                    className="pollResult"
                    style={{
                      width: `${
                        pollMax === 0
                          ? 0
                          : Math.min(100, ((poll.votes + (vote.poll_id === poll.poll_id ? 1 : 0)) / pollMax) * 100)
                      }%`,
                    }}
                  />
                ) : null}
                <div className="pollQuestion">{poll.question}</div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default PollWidget;
