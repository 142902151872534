import React, { useEffect, useState } from "react";
import "./pollWidget.css";
import SaveWidgetButton from "../../button/saveWidgetButton/saveWidgetButton";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import PlusBlack from "../../../assets/PlusBlack.png";
import Close from "../../../assets/Close.png";
import PollWidget from "./pollWidget";
import RemoveWidgetButton from "../../button/removeWidgetButton/removeWidgetButton";
import CloseWidgetButton from "../../button/closeWidgetButton/closeWidgetButton";

function EditPollWidget() {
  const currentEvent = useSelector((state) => state.eventReducer.currentEvent);
  const editWidgetContent = useSelector((state) => state.dashboardReducer.editWidgetContent);
  const [widgetId, setWidgetId] = useState(uuidv4());
  const [question, setQuestion] = useState("");
  const [removedAnswers, setRemovedAnswers] = useState([]);
  const [answers, setAnswers] = useState([
    {
      poll_id: uuidv4(),
      dashboard_id: "",
      question: "",
      votes: 0,
    },
  ]);
  const [size, setSize] = useState("small");

  useEffect(() => {
    if (editWidgetContent.type === "poll") {
      console.log(editWidgetContent);
      setWidgetId(editWidgetContent.id);
      setQuestion(editWidgetContent.title);
      setAnswers(editWidgetContent.answers);
      setSize(editWidgetContent.size);
    }
  }, [editWidgetContent]);

  return (
    <div
      className="editWidgetModal"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div className="editWidgetContent">
        <div className="editWidgetTitleRow">
          <div className="editWidgetTitle">Add your poll</div>
          <CloseWidgetButton />
        </div>
        <div className="editWidgetDesc">Add your question and answer options.</div>
        <div className="editWidgetHolder">
          <div className="editWidgetQuestionTitle">Question</div>
          <div className="pollAnswerRow">
            <input
              className="editWidgetQuestionInput"
              placeholder="e.g. What food should we have?"
              type="text"
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
            />
          </div>
          <br />
          <div className="editWidgetQuestionTitle">Answers</div>
          {answers.map((answer, index) => {
            return (
              <div className="pollAnswerRow" key={index}>
                <div className="editWidgetAnswerHolder">
                  <input
                    key={answer.question ?? index}
                    className="editWidgetAnswerInput"
                    placeholder={`Option ${index + 1}`}
                    defaultValue={answer.question !== "" ? answer.question : ""}
                    type="text"
                    onBlur={(e) => {
                      if (e.target.value === "") {
                        if (answers.length > 1) {
                          const newAnswers = [...answers];
                          newAnswers.splice(index, 1);
                          setRemovedAnswers([...removedAnswers, answer]);
                          setAnswers(newAnswers);
                        }
                      } else {
                        const newAnswers = [...answers];
                        newAnswers[index] = {
                          ...newAnswers[index],
                          question: e.target.value,
                        };
                        setAnswers(newAnswers);
                      }
                    }}
                  />
                  {answers.length > 1 && (
                    <img
                      className="pollDeleteAnswerImg"
                      src={Close}
                      alt="pollDeleteAnswerImg"
                      onClick={() => {
                        if (answers.length > 1) {
                          const newAnswers = [...answers];
                          newAnswers.splice(index, 1);
                          setRemovedAnswers([...removedAnswers, answer]);
                          setAnswers(newAnswers);
                        }
                      }}
                    />
                  )}
                </div>
                {index === answers.length - 1 && (
                  <img
                    className="pollAddAnswerImg"
                    src={PlusBlack}
                    alt="pollAddAnswerImg"
                    onClick={() => {
                      if (!answers.includes("")) {
                        const newAnswers = [...answers, ""];
                        setAnswers(newAnswers);
                      } else {
                        alert("Please fill in the current answer before adding a new one.");
                      }
                    }}
                  />
                )}
              </div>
            );
          })}
        </div>
        <div className="sizeSelector">
          <div className="sizeSelectorText">
            <div
              className="editWidgetQuestionTitle"
              onClick={() => {
                setSize("small");
              }}
            >
              Small
            </div>
            <div
              className="editWidgetQuestionTitle"
              onClick={() => {
                setSize("large");
              }}
            >
              Large
            </div>
          </div>
          <div className="sizeSelectorBar">
            <div className={size === "small" ? "sizeSelectorBarSmall" : "sizeSelectorBarLarge"} />
          </div>
          <div className={`sizeSample ${size === "large" ? "sizeSampleLarge" : ""}`}>
            <PollWidget
              live={false}
              data={{
                id: widgetId,
                type: "poll",
                title: question,
                event_id: currentEvent.event_id,
                answers: answers,
                size: size,
                index: editWidgetContent?.index ?? 0,
              }}
            />
          </div>
        </div>
        <div className="saveAction">
          {editWidgetContent?.id ? <RemoveWidgetButton widget={{ dashboard_id: widgetId, event_id: currentEvent.event_id }} /> : null}
          <SaveWidgetButton
            saveContent={{
              id: widgetId,
              type: "poll",
              title: question,
              event_id: currentEvent.event_id,
              answers: answers,
              size: size,
              index: editWidgetContent?.index ?? 0,
            }}
            removeContent={removedAnswers}
          />
        </div>
      </div>
    </div>
  );
}

export default EditPollWidget;
