import { combineReducers } from "redux";
import { SET_CART } from "../action/cartAction";

const defaultCart = {
  stage: "price", // price, time, note, payment
  cartContent: {},
  eventPreference: {
    time: "",
    note: "",
  },
};

const currentCart = (state = defaultCart, action) => {
  switch (action.type) {
  case SET_CART: {
    const cart = action.payload;
    return cart;
  }
  default: {
    return state;
  }
  }
};

export default combineReducers({
  currentCart,
});
