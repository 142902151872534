import React from "react";
import "./forYou.css";
import VerticalEventCard from "../../cards/verticalEventCard/verticalEventCard";
import { useSelector } from "react-redux";
import { useMediaQuery } from "../../../hooks/useMediaQuery";

function ForYou() {
  const isDesktop = useMediaQuery("(min-width: 768px)");
  const nearlyFundedEvents = useSelector((state) => state.landingReducer.nearlyFundedEvents);

  return (
    <div className="forYou">
      <div className="forYouTitle">Events for you</div>
      <div className={`forYouHolder ${!isDesktop ? "forYouMobile" : ""}`}>
        {nearlyFundedEvents.map((event) => (
          <VerticalEventCard key={event.event_id} event={event} />
        ))}
      </div>
    </div>
  );
}

export default ForYou;
