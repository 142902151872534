import { all, fork } from "redux-saga/effects";
import eventSaga from "./eventSaga";
import landingSaga from "./landingSaga";
import userSaga from "./userSaga";
import dashboardSaga from "./dashboardSaga";

export default function* rootSaga() {
  yield all([
    fork(eventSaga),
    fork(landingSaga),
    fork(userSaga),
    fork(dashboardSaga),
  ]);
}