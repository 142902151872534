import React from "react";
import Markdown from "react-markdown";
import { v4 as uuidv4 } from "uuid";

export function convertMarkdown(text) {
  if (!text) {
    return "";
  }
  return text.split(/\\n/g).map((subStr) => {
    return (
      <span key={uuidv4()}>
        <Markdown>{subStr}</Markdown>
      </span>
    );
  });
}

// Function converts line breaks to <br /> tags and replaces dashes with bullets.
export function convertLineBreaks(text, doubleBreak = false) {
  if (!text) {
    return "";
  }
  return dashReplace(text)
    .split(/\\n/g)
    .map((subStr) => {
      return (
        <span key={uuidv4()}>
          {subStr}
          <br />
          {doubleBreak && <br />}
        </span>
      );
    });
}

function dashReplace(text) {
  if (!text) {
    return "";
  }
  return text.replaceAll("- ", "• ");
}

export default convertMarkdown;
