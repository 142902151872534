import defaultAxios from "axios";
import { backend } from "../../Constants";

const axios = defaultAxios.create({
  baseURL: backend,
  headers: { "Content-Type": "application/json" },
});

export const getPollApi = async (payload) => {
  try {
    // const token = JSON.parse(localStorage.getItem("currentUser")).token;
    // var headers = {
    //   Authorization: `Bearer ${token}`,
    // };
    const poll = await axios.get(`poll/${payload}`, {
      // headers: headers,
    });
    return poll.data;
  } catch (err) {
    return console.error(err);
  }
};

export const getUserPollVoteApi = async (payload) => {
  try {
    // const token = JSON.parse(localStorage.getItem("currentUser")).token;
    // var headers = {
    //   Authorization: `Bearer ${token}`,
    // };
    const poll = await axios.get(`poll/${payload.dashboard_id}/${payload.user_id}`, {
      // headers: headers,
    });
    return poll.data;
  } catch (err) {
    return console.error(err);
  }
};

export const postPollApi = async (payload) => {
  try {
    // const token = JSON.parse(localStorage.getItem("currentUser")).token;
    // var headers = {
    //   Authorization: `Bearer ${token}`,
    // };

    var data = JSON.stringify({
      id: payload.id,
      dashboard_id: payload.dashboard_id,
      question: payload.question,
    });

    const poll = await axios.post("poll/", data, {
      // headers: headers,
    });
    return poll;
  } catch (err) {
    return console.error(err);
  }
};

export const votePollApi = async (payload) => {
  try {
    // const token = JSON.parse(localStorage.getItem("currentUser")).token;
    // var headers = {
    //   Authorization: `Bearer ${token}`,
    // };
    var data = JSON.stringify({
      vote_id: payload.vote_id,
      poll_id: payload.poll_id,
      user_id: payload.user_id,
      dashboard_id: payload.dashboard_id,
    });

    const poll = await axios.post("poll/vote/", data, {
      // headers: headers,
    });
    return poll.data;
  } catch (err) {
    return console.error(err);
  }
};

export const deletePollApi = async (payload) => {
  try {
    // const token = JSON.parse(localStorage.getItem("currentUser")).token;
    // var headers = {
    //   Authorization: `Bearer ${token}`,
    // };
    const poll = await axios.delete(`poll/${payload}`, {
      // headers: headers,
    });
    return poll.data;
  } catch (err) {
    return console.error(err);
  }
};
