import defaultAxios from "axios";
import { backend } from "../../Constants";

const axios = defaultAxios.create({
  baseURL: backend,
  headers: { "Content-Type": "application/json" },
});

export const votePromptApi = async (payload) => {
  try {
    // const token = JSON.parse(localStorage.getItem("currentUser")).token;
    // var headers = {
    //   Authorization: `Bearer ${token}`,
    // };
    var data = JSON.stringify({
      vote_id: payload.vote_id,
      answer: payload.answer,
      user_id: payload.user_id,
      dashboard_id: payload.dashboard_id,
    });

    const prompt = await axios.post("prompt/", data, {
      // headers: headers,
    });
    return prompt.data;
  } catch (err) {
    return console.error(err);
  }
};
