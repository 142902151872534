import defaultAxios from "axios";
import { backend } from "../../Constants";

const axios = defaultAxios.create({
  baseURL: backend,
  headers: { "Content-Type": "application/json" },
});

export const getEventTransactionApi = async (payload) => {
  try {
    // const token = JSON.parse(localStorage.getItem("currentUser")).token;
    // var headers = {
    //   Authorization: `Bearer ${token}`,
    // };
    if (payload) {
      const eventId = payload;
      const eventTransactions = await axios.get(`transaction/${eventId}`, {
        // headers: headers,
      });
      return eventTransactions;
    }
  } catch (err) {
    return console.error(err);
  }
};

export const postEventTransactionApi = async (payload) => {
  try {
    // const token = JSON.parse(localStorage.getItem("currentUser")).token;
    // var headers = {
    //   Authorization: `Bearer ${token}`,
    // };

    var data = JSON.stringify({
      id: payload.transaction_id,
      paymentIntent: payload.paymentIntent,
      timestamp: payload.timestamp,
      event_id: payload.event_id,
      user_id: payload.user_id,
      event_tier_id: payload.event_tier_id
    });

    const eventTransaction = await axios.post("transaction/", data, {
      // headers: headers,
    });
    return eventTransaction;
  } catch (err) {
    return console.error(err);
  }
};

export const postPaidEventTransactionApi = async (payload) => {
  try {
    // const token = JSON.parse(localStorage.getItem("currentUser")).token;
    // var headers = {
    //   Authorization: `Bearer ${token}`,
    // };

    var data = JSON.stringify({});

    const eventTransaction = await axios.post(`transaction/${payload.paymentIntent}/paid`, data, {
      // headers: headers,
    });
    return eventTransaction;
  } catch (err) {
    return console.error(err);
  }
};