import React from "react";
import "./promptWidget.css";
import "../widgetStyling.css";
import { useDispatch, useSelector } from "react-redux";
import {
  SET_EDIT_WIDGET_CONTENT,
  SET_EDIT_WIDGET_STEP,
  TOGGLE_EDIT_WIDGET,
  VOTE_PROMPT,
} from "../../../redux/action/dashboardAction";
import { v4 as uuidv4 } from "uuid";

function PromptWidget({ live, data }) {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.userReducer.currentUser);
  const showEditWidget = useSelector((state) => state.dashboardReducer.showEditWidget);

  return (
    <div
      className={`widgetView ${!live && !showEditWidget ? "clickable" : ""}`}
      onClick={() => {
        if (!live && !showEditWidget) {
          dispatch({ type: TOGGLE_EDIT_WIDGET });
          dispatch({
            type: SET_EDIT_WIDGET_CONTENT,
            payload: {
              type: "prompt",
              size: data.component_size,
              id: data.dashboard_id,
              index: data.component_index,
              title: data.title,
            },
          });
          dispatch({ type: SET_EDIT_WIDGET_STEP, payload: "prompt" });
        }
      }}
    >
      <div className="widgetTitle">{data.title !== "" ? data.title : "Prompt Question"}</div>
      <textarea
        className="promptInput"
        placeholder="Answer the prompt here..."
        type="text"
        onClick={(e) => {
          e.stopPropagation();
        }}
        onBlur={(e) => {
          if (live) {
            if (e.target.value === "") {
              console.log(e.target.value);
            } else {
              dispatch({
                type: VOTE_PROMPT,
                payload: {
                  vote_id: uuidv4(),
                  answer: e.target.value,
                  user_id: currentUser.user_id,
                  dashboard_id: data.dashboard_id,
                },
              });
            }
          }
        }}
      />
    </div>
  );
}

export default PromptWidget;
