import React, { useEffect, useState } from "react";
import "./getTicketButton.css";
import ArrowIcon from "../../../assets/ArrowIcon.png";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { POST_EVENT_TRANSACTION } from "../../../redux/action/eventAction";
import { v4 as uuidv4 } from "uuid";

function GetTicketButton() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const event = useSelector((state) => state.eventReducer.currentEvent);
  const currentUser = useSelector((state) => state.userReducer.currentUser);
  const [soldOut, setSoldOut] = useState(false);

  useEffect(() => {
    if (event) {
      setSoldOut((event?.event_backers ?? 0) >= (event?.event_capacity ?? 0));
    }
  }, [event]);

  return (
    <div
      className="GetTicketButton"
      style={soldOut ? { cursor: "default" } : { cursor: "pointer" }}
      onClick={() => {
        if (!soldOut && currentUser.user_id) {
          if (event.event_type === "free") {
            dispatch({
              type: POST_EVENT_TRANSACTION,
              payload: {
                transaction_id: uuidv4(),
                paymentIntent: "free",
                timestamp: new Date().getTime(),
                event_id: event.event_id,
                user_id: currentUser?.user_id,
                event_tier_id: "free",
              },
            });
          } else {
            navigate(`/transaction/${event.event_id}`);
          }
        } else {
          window.location.href = "/login";
        }
      }}
    >
      <div className="getTicketText">{soldOut ? "Sold Out" : event.event_type === "free" ? "RSVP" : "Get Tickets"}</div>
      {!soldOut ? <img className="getTicketArrow" src={ArrowIcon} alt="ArrowIcon" /> : null}
    </div>
  );
}

export default GetTicketButton;
