export const GET_EVENT = "GET_EVENT";
export const GET_EVENT_TIERS = "GET_EVENT_TIERS";
export const GET_EVENT_TRANSACTIONS = "GET_EVENT_TRANSACTIONS";
export const GET_NEARBY_EVENTS = "GET_NEARBY_EVENTS";
export const GET_NEARLY_FUNDED_EVENTS = "GET_NEARLY_FUNDED_EVENTS";
export const GET_NEW_EVENTS = "GET_NEW_EVENTS";
export const GET_EVENTS_BY_KEYWORD = "GET_EVENTS_BY_KEYWORD";
export const GET_EVENT_GUESTLIST = "GET_EVENT_GUESTLIST";
export const GET_EVENT_TICKET = "GET_EVENT_TICKET";

export const SET_EVENT = "SET_EVENT";
export const SET_EVENT_TIERS = "SET_EVENT_TIERS";
export const SET_EVENT_TRANSACTIONS = "SET_EVENT_TRANSACTIONS";
export const SET_NEARBY_EVENTS = "SET_NEARBY_EVENTS";
export const SET_NEARLY_FUNDED_EVENTS = "SET_NEARLY_FUNDED_EVENTS";
export const SET_NEW_EVENTS = "SET_NEW_EVENTS";
export const SET_EVENTS_BY_KEYWORD = "SET_EVENTS_BY_KEYWORD";
export const SET_EVENT_GUESTLIST = "SET_EVENT_GUESTLIST";
export const SET_EVENT_TICKET = "SET_EVENT_TICKET";

export const POST_EVENT_TRANSACTION = "POST_EVENT_TRANSACTION";
export const POST_PAID_EVENT_TRANSACTION = "POST_PAID_EVENT_TRANSACTION";

export const POST_EMAIL_CONFIRMATION = "POST_EMAIL_CONFIRMATION";

export const DELETE_EVENT = "DELETE_EVENT";
export const DELETE_RSVP = "DELETE_RSVP";