import defaultAxios from "axios";
import { backend } from "../../Constants";

const axios = defaultAxios.create({
  baseURL: backend,
  headers: { "Content-Type": "application/json" },
});

export const getAccountCreationLinkApi = async (payload) => {
  try {
    // const token = JSON.parse(localStorage.getItem("currentUser")).token;
    // var headers = {
    //   Authorization: `Bearer ${token}`,
    // };
    const creationLink = await axios.get(`payment/getAccountCreationLink/${payload}`, {
      // headers: headers,
    });
    return creationLink.data?.url ?? null;
  } catch (err) {
    return console.error(err);
  }
};

export const getReceiptApi = async (payload) => {
  try {
    // const token = JSON.parse(localStorage.getItem("currentUser")).token;
    // var headers = {
    //   Authorization: `Bearer ${token}`,
    // };
    const creationLink = await axios.get(`payment/getReceipt/${payload}`, {
      // headers: headers,
    });
    return creationLink.data;
  } catch (err) {
    return console.error(err);
  }
};