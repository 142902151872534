import defaultAxios from "axios";
import { backend } from "../../Constants";

const axios = defaultAxios.create({
  baseURL: backend,
  headers: { "Content-Type": "application/json" },
});

export const postUserApi = async (payload) => {
  try {
    const token = JSON.parse(localStorage.getItem("currentUser"))?.token ?? "";
    var headers = {
      Authorization: `${token}`,
    };

    var data = JSON.stringify({
      user_id: payload.user_id,
      first_name: payload.first_name,
      last_name: payload.last_name,
      email_address: payload.email_address,
      phone: 0,
    });

    const userSignup = await axios.post("user/", data, {
      headers: headers,
    });
    return userSignup;
  } catch (err) {
    return console.error(err);
  }
};

export const getUserApi = async (payload) => {
  try {
    const token = JSON.parse(localStorage.getItem("currentUser"))?.token ?? "";
    var headers = {
      Authorization: `${token}`,
    };
    if (payload) {
      const userId = payload;
      const user = await axios.get(`user/${userId}`, {
        headers: headers,
      });
      return user;
    }
  } catch (err) {
    return console.error(err);
  }
};

export const getUserCancellableApi = async (payload, source) => {
  try {
    const token = JSON.parse(localStorage.getItem("currentUser"))?.token ?? "";
    var headers = {
      Authorization: `${token}`,
    };
    if (payload) {
      const userId = payload;
      const user = await axios.get(`user/${userId}`, {
        cancelToken: source.token,
        headers: headers,
      });
      return user;
    }
  } catch (err) {
    return console.error(err);
  }
};

export const getUserConnectionsApi = async (payload) => {
  try {
    const token = JSON.parse(localStorage.getItem("currentUser"))?.token ?? "";
    var headers = {
      Authorization: `${token}`,
    };
    if (payload) {
      const userId = payload;
      const userConnections = await axios.get(`connection/directConnections/${userId}`, {
        headers: headers,
      });
      return userConnections;
    }
  } catch (err) {
    return console.error(err);
  }
};
