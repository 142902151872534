import React, { useEffect, useState } from "react";
import "./eventWhereCreate.css";
import CreationNavBar from "../creationNavBar/creationNavBar";
import UncheckedSquare from "../../../assets/UncheckedSquare.png";
import CheckedSquare from "../../../assets/CheckedSquare.png";
import Map from "../../map/map";
import { useDispatch, useSelector } from "react-redux";
import { getCoordinatesApi } from "../../../redux/api/third-party/locationApi";
import { SET_CREATION_STEP } from "../../../redux/action/createAction";
import ngeohash from "ngeohash";
import { AutoComplete } from "antd";

import Radar from "radar-sdk-js";
import "radar-sdk-js/dist/radar.css";

Radar.initialize(process.env.REACT_APP_RADAR_API);

function EventWhereCreate() {
  const dispatch = useDispatch();
  const [hasExactLocation, setHasExactLocation] = useState(true);

  const creation = useSelector((state) => state.createReducer.currentCreation);
  const [searchLocation, setSearchLocation] = useState();
  const [noLocation, setNoLocation] = useState(false);
  const [reloadMap, setReloadMap] = useState(false);
  const [options, setOptions] = useState([]);
  const [addressQuery, setAddressQuery] = useState("");

  useEffect(() => {
    if (addressQuery === "") {
      return;
    }
    const timeOutId = setTimeout(() => {
      Radar.autocomplete({
        query: addressQuery,
        limit: 3,
      })
        .then((result) => {
          const { addresses } = result;
          const addressStringList = addresses.map((address) => {
            return { value: address.formattedAddress };
          });
          setOptions(addressStringList);
        })
        .catch((err) => {
          // handle error
          console.log(err);
        });
      dispatch({
        type: SET_CREATION_STEP,
        payload: {
          ...creation,
          event_location: addressQuery,
        },
      });
    }, 300);
    return () => clearTimeout(timeOutId);
  }, [addressQuery]);

  useEffect(() => {
    if (creation.event_location_hash === "NO_LOCATION") {
      setNoLocation(true);
    } else if (creation.exact_location && creation.event_location) {
      getCoordinatesApi(creation.event_location).then((locationData) => {
        if (locationData.status !== "ZERO_RESULTS") {
          setSearchLocation(locationData);
        }
      });
    } else if (!creation.exact_location && creation.event_location_place) {
      getCoordinatesApi(creation.event_location_place).then((locationData) => {
        if (locationData.status !== "ZERO_RESULTS") {
          setSearchLocation(locationData);
        }
      });
    }
  }, [reloadMap]);

  useEffect(() => {
    setHasExactLocation(creation.exact_location);
  }, [creation.exact_location]);

  return (
    <>
      <div className="eventCreationLeft invisiScroll">
        <div>
          <div className="eventCreationTitle">Let people know where to be</div>
          <div className="eventCreationSubTitle">Give your event a location or pinpoint a general area it will take place.</div>
          <div className="eventCreationOptionsHolder">
            {/* Exact Location */}
            <div
              className="eventCreationOption"
              onClick={() => {
                dispatch({
                  type: SET_CREATION_STEP,
                  payload: {
                    ...creation,
                    exact_location: true,
                  },
                });
                setReloadMap(!reloadMap);
              }}
            >
              <img
                src={hasExactLocation ? CheckedSquare : UncheckedSquare}
                className="whereCheckIcon clickable"
                onClick={() => {
                  dispatch({
                    type: SET_CREATION_STEP,
                    payload: {
                      ...creation,
                      exact_location: true,
                    },
                  });
                  setReloadMap(!reloadMap);
                }}
              />
              <div className="eventCreationEntry">
                <div className={`eventCreationOptionTitle ${hasExactLocation ? "" : "whereGrayText"}`}>I have an exact address</div>
                {hasExactLocation && (
                  <AutoComplete
                    id="autocompleteField"
                    className={`eventAutocompleteInput ${hasExactLocation ? "" : "whereGrayText"}`}
                    options={options}
                    defaultValue={creation.event_location}
                    onSelect={(e) => {
                      getCoordinatesApi(e).then((locationData) => {
                        if (locationData?.results?.length > 0) {
                          const locationCoord = locationData.results[0].geometry.location;
                          dispatch({
                            type: SET_CREATION_STEP,
                            payload: {
                              ...creation,
                              event_location_hash: ngeohash.encode(locationCoord.lat, locationCoord.lng),
                              event_location: e,
                            },
                          });
                          setReloadMap(!reloadMap);
                          document.getElementById("autocompleteField").blur();
                        }
                      });
                    }}
                    onSearch={(e) => {
                      setAddressQuery(e);
                    }}
                    onBlur={() => {
                      getCoordinatesApi(creation.event_location).then((locationData) => {
                        if (locationData?.results?.length > 0) {
                          const locationCoord = locationData.results[0].geometry.location;
                          dispatch({
                            type: SET_CREATION_STEP,
                            payload: {
                              ...creation,
                              event_location_hash: ngeohash.encode(locationCoord.lat, locationCoord.lng),
                            },
                          });
                        }
                      });
                      setReloadMap(!reloadMap);
                    }}
                    placeholder="e.g City, Address, Postcode"
                  />
                )}
              </div>
            </div>
            {/* Rough Location */}
            <div
              className="eventCreationOption lowerOption"
              onClick={() => {
                dispatch({
                  type: SET_CREATION_STEP,
                  payload: {
                    ...creation,
                    exact_location: false,
                  },
                });
                setReloadMap(!reloadMap);
              }}
            >
              <img
                src={!hasExactLocation ? CheckedSquare : UncheckedSquare}
                className="whereCheckIcon clickable"
                onClick={() => {
                  dispatch({
                    type: SET_CREATION_STEP,
                    payload: {
                      ...creation,
                      exact_location: false,
                    },
                  });
                  setReloadMap(!reloadMap);
                }}
              />
              <div className="eventCreationEntry">
                <div className={`eventCreationOptionTitle ${hasExactLocation ? "whereGrayText" : ""}`}>{"I'm still deciding on a location"}</div>
                {!hasExactLocation && (
                  <div className={`eventCreationOptionSubTitle ${hasExactLocation ? "whereGrayText" : ""}`}>
                    Give us an idea of where your event might take place
                  </div>
                )}
                {!hasExactLocation && (
                  <div className="roughlyWhere">
                    <input
                      className={`eventCreationInput ${hasExactLocation ? "whereGrayText" : ""}`}
                      type="text"
                      placeholder="e.g Park"
                      defaultValue={creation.event_location_noun}
                      onChange={(e) => {
                        dispatch({
                          type: SET_CREATION_STEP,
                          payload: {
                            ...creation,
                            event_location_noun: e.target.value,
                          },
                        });
                      }}
                    />
                    <div className="eventCreationOptionTitle whereGrayText">in</div>
                    <input
                      className={`eventCreationInput ${hasExactLocation ? "whereGrayText" : ""}`}
                      type="text"
                      placeholder="e.g Vancouver"
                      defaultValue={creation.event_location_place}
                      onChange={(e) => {
                        dispatch({
                          type: SET_CREATION_STEP,
                          payload: {
                            ...creation,
                            event_location_place: e.target.value,
                          },
                        });
                      }}
                      onBlur={() => {
                        getCoordinatesApi(creation.event_location_place).then((locationData) => {
                          if (locationData?.results?.length > 0) {
                            const locationCoord = locationData.results[0].geometry.location;
                            dispatch({
                              type: SET_CREATION_STEP,
                              payload: {
                                ...creation,
                                event_location_hash: ngeohash.encode(locationCoord.lat, locationCoord.lng),
                              },
                            });
                          }
                        });
                        setReloadMap(!reloadMap);
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <CreationNavBar />
      </div>
      <div className="eventCreationRight">{!noLocation && <Map searchLocation={searchLocation} />}</div>
    </>
  );
}

export default EventWhereCreate;
