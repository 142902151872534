import React, { useEffect, useState } from "react";
import "./eventOverview.css";
import GetTicketButton from "../getTicketButton/getTicketButton";
import { useSelector, useDispatch } from "react-redux";
import EventFunding from "../eventFunding/eventFunding";
import UsernameComponent from "../../user/usernameComponent/usernameComponent";
import EventDetailTagSection from "../../tags/eventDetailTagSection/eventDetailTagSection";
import RSVPConfirmation from "../rsvpConfirmation/rsvpConfirmation";
import { GET_EVENT_TICKET } from "../../../redux/action/eventAction";
import EditEventButton from "../../button/editEventButton/editEventButton";
import Map from "../../map/map";
import ngeohash from "ngeohash";

function EventOverview() {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.userReducer.currentUser);
  const event = useSelector((state) => state.eventReducer.currentEvent);
  const eventTicket = useSelector((state) => state.eventReducer.currentEventTicket);
  const [isOwner, setIsOwner] = useState(false);
  const [locationCoords, setLocationCoords] = useState();

  useEffect(() => {
    if (event.event_id && currentUser.user_id) {
      dispatch({ type: GET_EVENT_TICKET, payload: { event_id: event.event_id, user_id: currentUser.user_id } });
    }
    if (event.event_location_hash) {
      const coords = ngeohash.decode(event.event_location_hash);
      setLocationCoords([coords.latitude, coords.longitude]);
    }
  }, [event, currentUser, dispatch]);

  useEffect(() => {
    if (event.user_id && currentUser.user_id === event.user_id) {
      setIsOwner(true);
    }
  }, [currentUser, event]);

  return (
    <div className="eventOverview">
      <div className="eventOverviewBasic">
        {console.log(event)}
        {isOwner ? (
          <EditEventButton event={event} />
        ) : (
          <UsernameComponent userId={event.user_id} firstName={event.user_first_name} lastName={event.user_last_name} />
        )}
        <div className="eventOverviewTitle">{event.event_name}</div>
        <div className="eventOverviewTldr">{event.event_tldr}</div>
        <div className="eventOverviewSection">
          <EventDetailTagSection event={event} />
        </div>
        {locationCoords ? (
          <div className="eventDetailMapHolder">
            <Map lat={locationCoords[0]} lng={locationCoords[1]} mode={"Preview"} />
          </div>
        ) : null}
      </div>
      <div>
        {event.event_type === "crowdfund" ? <EventFunding /> : null}
        {new Date(event.event_start_timestamp) > new Date() && <div className="eventOverviewAction">{eventTicket.length > 0 ? <RSVPConfirmation /> : <GetTicketButton />}</div>}
      </div>
    </div>
  );
}

export default EventOverview;
