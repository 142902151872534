import React, { useEffect, useState } from "react";
import "./createInspiration.css";
import CreateEventButton from "../../button/createEventButton/createEventButton";
import { getSonderSays } from "../../../utils/dynamodb/dynamodbController";

function CreateInspiration() {
  const [inspiration, setInspiration] = useState([]);

  useEffect(() => {
    getSonderSays().then((data) => {
      let ideaArray = data.map((item) => item.event.replace(/['"]+/g, "").replace(/\\/g, ""));
      setInspiration(ideaArray);
    });
  }, []);

  return (
    <div className="createInspiration">
      <div className="createInspirationTitle">Inspired? Create your own event.</div>
      <div className="createInspirationSubtitle">Host your own event where your guests are now your co-planners and stakeholders.</div>
      <div className="createInspirationH4">Dream your wildest dreams, here are ours this week:</div>
      <div className="inspirationHolder">
        {inspiration.map((insp) => (
          <div className="inspirationTile" key={insp}>
            {insp}
          </div>
        ))}
      </div>

      <CreateEventButton />
    </div>
  );
}

export default CreateInspiration;
