import React from "react";
import "./editEventButton.css";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
// import { DELETE_EVENT } from "../../../redux/action/eventAction";
import { GET_EDIT_EVENT } from "../../../redux/action/createAction";

function EditEventButton({ event }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  var deleteAvailableTime = new Date(event.event_start_time);
  deleteAvailableTime.setDate(deleteAvailableTime.getDate() + 1);
  const eventExpired = deleteAvailableTime < new Date();
  return (
    <div
      className="editEventButtonHolder buttonHolderBlack"
      onClick={() => {
        if (eventExpired) {
          // dispatch({ type: DELETE_EVENT, payload: event.event_id });
          // navigate("/myEvents");
          alert("Event deletion is not supported yet!");
        } else {
          dispatch({ type: GET_EDIT_EVENT, payload: event.event_id });
          navigate(`/edit/${event.event_id}`);
        }
      }}
    >
      {eventExpired ? "Delete Your Event" : "Edit Your Event"}
    </div>
  );
}

export default EditEventButton;
