import React, { useEffect, useState } from "react";
import "./promptWidget.css";
import SaveWidgetButton from "../../button/saveWidgetButton/saveWidgetButton";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import PromptWidget from "./promptWidget";
import RemoveWidgetButton from "../../button/removeWidgetButton/removeWidgetButton";
import CloseWidgetButton from "../../button/closeWidgetButton/closeWidgetButton";

function EditPromptWidget() {
  const currentEvent = useSelector((state) => state.eventReducer.currentEvent);
  const editWidgetContent = useSelector((state) => state.dashboardReducer.editWidgetContent);
  const [widgetId, setWidgetId] = useState(uuidv4());
  const [question, setQuestion] = useState("");
  const [size, setSize] = useState("small");

  useEffect(() => {
    if (editWidgetContent.type === "prompt") {
      console.log(editWidgetContent);
      setWidgetId(editWidgetContent.id);
      setSize(editWidgetContent.size);
      setQuestion(editWidgetContent.title);
    }
  }, [editWidgetContent]);

  return (
    <div
      className="editWidgetModal"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div className="editWidgetContent">
        <div className="editWidgetMainContent">
          <div className="editWidgetTitleRow">
            <div className="editWidgetTitle">Edit Prompt</div>
            <CloseWidgetButton />
          </div>
          <div className="editWidgetDesc">Allow your community to see who is attending.</div>
          <div className="editWidgetHolder">
            <div className="editWidgetQuestionTitle">Question</div>
            <div className="pollAnswerRow">
              <input
                className="editWidgetQuestionInput"
                placeholder="e.g. What food should we have?"
                type="text"
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
              />
            </div>
          </div>
          <div className="sizeSelector">
            <div className="sizeSelectorText">
              <div
                className="editWidgetQuestionTitle"
                onClick={() => {
                  setSize("small");
                }}
              >
                Small
              </div>
              <div
                className="editWidgetQuestionTitle"
                onClick={() => {
                  setSize("large");
                }}
              >
                Large
              </div>
            </div>
            <div className="sizeSelectorBar">
              <div className={size === "small" ? "sizeSelectorBarSmall" : "sizeSelectorBarLarge"} />
            </div>
            <div className={`sizeSample ${size === "large" ? "sizeSampleLarge" : ""}`}>
              <PromptWidget
                live={false}
                data={{
                  id: widgetId,
                  type: "prompt",
                  event_id: currentEvent.event_id,
                  title: question,
                  size: size,
                  index: editWidgetContent?.index ?? 0,
                }}
              />
            </div>
          </div>
        </div>
        <div className="saveAction">
          {editWidgetContent?.id ? (
            <RemoveWidgetButton widget={{ dashboard_id: widgetId, event_id: currentEvent.event_id }} />
          ) : null}
          <SaveWidgetButton
            saveContent={{
              id: widgetId,
              type: "prompt",
              title: question,
              event_id: currentEvent.event_id,
              answers: [],
              size: size,
              index: editWidgetContent?.index ?? 0,
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default EditPromptWidget;
