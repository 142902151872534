import React from "react";
import "./saveWidgetButton.css";
import "../buttonStyle.css";
import ArrowIcon from "../../../assets/ArrowIcon.png";
import { useDispatch } from "react-redux";
import { DELETE_POLL, POST_DASHBOARD } from "../../../redux/action/dashboardAction";

function SaveWidgetButton({ saveContent, removeContent }) {
  const dispatch = useDispatch();
  return (
    <div
      className="buttonHolder buttonHolderBlack"
      onClick={() => {
        dispatch({ type: POST_DASHBOARD, payload: saveContent });
        if (saveContent.type === "poll") {
          removeContent.forEach((answer) => {
            if (answer.poll_id) {
              dispatch({ type: DELETE_POLL, payload: answer.poll_id });
            }
          });
        }
      }}
    >
      <div>Add Widget</div>
      <img className="buttonArrow" src={ArrowIcon} alt="ArrowIcon" />
    </div>
  );
}

export default SaveWidgetButton;
