import { call, put, takeLatest } from "redux-saga/effects";
import { GET_USER, GET_USER_CONNECTIONS, POST_USER, SET_USER, SET_USER_CONNECTIONS } from "../action/userAction";
import { getUserApi, getUserConnectionsApi, postUserApi } from "../api/userApi";

export function* postUser({ payload }) {
  yield call(postUserApi, payload);
}

export function* getUser({ payload }) {
  const user = yield call(getUserApi, payload);
  yield put({ type: SET_USER, payload: user.data });
}

export function* getUserConnections({ payload }) {
  const userConnections = yield call(getUserConnectionsApi, payload);
  yield put({ type: SET_USER_CONNECTIONS, payload: userConnections.data });
}

export default function* eventSaga() {
  yield takeLatest(POST_USER, postUser)
  yield takeLatest(GET_USER, getUser)
  yield takeLatest(GET_USER_CONNECTIONS, getUserConnections)
}