import { useEffect, useState } from "react";

export function useMediaQuery(query) {
  const [matches, setMatches] = useState(false);

  function handleChange(e) {
    setMatches(e.matches);
  }

  useEffect(() => {
    const matchQueryList = window.matchMedia(query);
    setMatches(matchQueryList.matches);
  }, []);

  useEffect(() => {
    const matchQueryList = window.matchMedia(query);
    
    matchQueryList.addEventListener("change", handleChange);
    return () => {
      matchQueryList.removeEventListener("change", handleChange);
    };
  }, [query, handleChange]);

  return matches;
}
