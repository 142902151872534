import React, { useEffect, useState } from "react";
import "./profilePicture.css";
import AccountIcon from "../../../assets/AccountIcon.png";
import { getUserProfilePicture } from "../../../utils/s3/s3Controller";
import { getUserCancellableApi } from "../../../redux/api/userApi";
import axios from "axios";

function ProfilePicture({ className, userId, hideInitials }) {
  const [userImage, setUserImage] = useState(AccountIcon);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [showInitial, setShowInitial] = useState(false && !hideInitials);

  useEffect(() => {
    if (userId === "") return;
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    getUserProfilePicture(userId).then((url) => {
      setUserImage(url ?? AccountIcon);
      if (!url) {
        getUserCancellableApi(userId, source).then((response) => {
          if (response) {
            setFirstName(response?.data?.user_first_name ?? "");
            setLastName(response?.data?.user_last_name ?? "");
            setShowInitial(true && !hideInitials);
          }
        });
      } else {
        setShowInitial(false);
      }
    });

    return () => {
      source.cancel();
    };
  }, [userId]);

  return (
    <>
      <div className="profilePicArea">
        <img className={`${className ?? "AccountIcon"} fitCover`} src={userImage} alt="User Profile Picture" />
        {showInitial && <div className="initialOverlay">{`${firstName ? firstName.charAt(0) : ""}${lastName ? lastName.charAt(0) : ""}`}</div>}
      </div>
    </>
  );
}

export default ProfilePicture;
