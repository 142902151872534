import { combineReducers } from "redux";
import { SET_MY_FUTURE_EVENTS, SET_MY_FUTURE_HOSTED_EVENTS, SET_MY_PAST_EVENTS, SET_MY_PAST_HOSTED_EVENTS } from "../action/myEventsAction";

const defaultEvents = [];

const myPastEvents = (state = defaultEvents, action) => {
  switch (action.type) {
  case SET_MY_PAST_EVENTS: {
    const events = action.payload;
    return events;
  }
  default: {
    return state;
  }
  }
};

const myFutureEvents = (state = defaultEvents, action) => {
  switch (action.type) {
  case SET_MY_FUTURE_EVENTS: {
    const events = action.payload;
    return events;
  }
  default: {
    return state;
  }
  }
};

const myPastHostedEvents = (state = defaultEvents, action) => {
  switch (action.type) {
  case SET_MY_PAST_HOSTED_EVENTS: {
    const events = action.payload;
    return events;
  }
  default: {
    return state;
  }
  }
};

const myFutureHostedEvents = (state = defaultEvents, action) => {
  switch (action.type) {
  case SET_MY_FUTURE_HOSTED_EVENTS: {
    const events = action.payload;
    return events;
  }
  default: {
    return state;
  }
  }
};

export default combineReducers({
  myPastEvents,
  myFutureEvents,
  myPastHostedEvents,
  myFutureHostedEvents
});