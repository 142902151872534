import React from "react";
import "./transactionNavigation.css";
// import TransactionNextButton from "./transactionNextButton/transactionNextButton";
import TransactionBackButton from "./transactionBackButton/transactionBackButton";

function TransactionNavigation() {
  return (
    <div className="transactionNavigationSection">
      <TransactionBackButton />
      {/* <TransactionNextButton /> */}
    </div>
  );
}

export default TransactionNavigation;
