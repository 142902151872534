import React from "react";
import "./eventTypeButton.css";
import "../buttonStyle.css";
import { useDispatch, useSelector } from "react-redux";
import { SET_CREATION_STEP } from "../../../redux/action/createAction";

function EventTypeButton({ type }) {
  const dispatch = useDispatch();
  const creation = useSelector((state) => state.createReducer.currentCreation);

  const titleMap = {
    crowdfund: "Crowdfund Your Event",
    paid: "Plan a Paid Event",
    free: "Free RSVP",
  };

  const descriptionMap = {
    crowdfund: "Sell tickets to reach a funding goal and make your event happen.",
    paid: "Sell tickets to your pre-planned event.",
    free: "Collect RSVPs for your pre-planned free event.",
  };

  return (
    <div
      className={`eventTypeButton clickable ${creation.event_type === type ? "eventTypeButtonSelected" : ""}`}
      onClick={() => {
        dispatch({
          type: SET_CREATION_STEP,
          payload: {
            ...creation,
            event_type: type,
          },
        });
      }}
    >
      <div className="eventTypeButtonTitle">{titleMap[type]}</div>
      <div className="eventTypeButtonDesc">{descriptionMap[type]}</div>
    </div>
  );
}

export default EventTypeButton;
