import React, { useEffect, useState } from "react";
import "./checkoutEntry.css";
import { useSelector } from "react-redux";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, PaymentElement } from "@stripe/react-stripe-js";
import PayButton from "../payButton/payButton";
import { backend } from "../../../../Constants";

const stripePromise = loadStripe(
  "pk_test_51NwsNvLBv7oNjOnOMkzV35qJLlfYdGgh5Gpvj19slaMrIsK2eQAxavsHxh31D8voU5VU8SkZM0cDPklB5Tta9zIy00J4aQJYZZ",
);

function CheckoutEntry() {
  const cart = useSelector((state) => state.cartReducer.currentCart);
  const [clientSecret, setClientSecret] = useState("");
  const [paymentIntentId, setPaymentIntentId] = useState("");
  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };
  const paymentElementOptions = {
    layout: "tabs",
  };

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    fetch(`${backend}payment/createPaymentIntent`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ items: cart.cartContent }),
    })
      .then((res) => res.json())
      .then((data) => {
        setClientSecret(data.clientSecret);
        setPaymentIntentId(data.paymentIntent);
      });
  }, [cart]);

  return (
    <div>
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <form id="payment-form">
            <PaymentElement id="payment-element" options={paymentElementOptions} />
            <PayButton clientSecret={clientSecret} paymentIntentId={paymentIntentId} />
          </form>
        </Elements>
      )}
    </div>
  );
}

export default CheckoutEntry;
