import React, { useEffect, useState } from "react";
import "./eventFunding.css";
import { useSelector } from "react-redux";

function EventFunding() {
  const event = useSelector((state) => state.eventReducer.currentEvent);
  const eventTiers = useSelector((state) => state.eventReducer.currentEventTiers);
  const eventTransactions = useSelector((state) => state.eventReducer.currentEventTransactions);
  const [percentFunded, setPercentFunded] = useState(0);
  const [amountFunded, setAmountFunded] = useState(0);
  const [ticketsSold, setTicketsSold] = useState(0);

  useEffect(() => {
    if (eventTiers.length === 0) return;
    if (event.event_fund_target === 0) return;
    setAmountFunded(0);
    setTicketsSold(0);
    eventTransactions.forEach((transaction) => {
      const tier = eventTiers.filter((eventTier) => eventTier.event_tier_id === transaction.transaction_tier)[0];
      setAmountFunded((amountFunded) => amountFunded + (tier?.event_tier_price ?? 0));
      setTicketsSold((ticketsSold) => ticketsSold + 1);
    });
    setPercentFunded((amountFunded / event.event_fund_target) * 100);
  }, [event, amountFunded, eventTiers, eventTransactions]);

  return (
    <div className="eventFunding">
      {/* <h3 className="fundingTitle">Funding Progress</h3> */}
      <div className="fundingBarSection">
        <div className="fundingBar">
          <div className="fundingBarFilled" style={{ width: `${Math.min(percentFunded, 100)}%` }} />
        </div>
        <div className="fundingValue">{percentFunded.toFixed(0)}%</div>
      </div>
      <div className="fundingStats">
        <div className="fundingValueBox">
          <div className="fundingValueTitle">${amountFunded.toLocaleString()}</div>
          <div className="fundingText">{`raised of $${event.event_fund_target?.toLocaleString()}`}</div>
        </div>
        <div className="fundingValueBox">
          <div className="fundingValueTitle">{ticketsSold.toLocaleString()}</div>
          <div className="fundingText">tickets sold</div>
        </div>
      </div>
    </div>
  );
}

export default EventFunding;
