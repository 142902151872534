import { combineReducers } from "redux";
import { SET_USER, SET_USER_CONNECTIONS } from "../action/userAction";

const defaultUser = null;

const currentUser = (state = defaultUser, action) => {
  switch (action.type) {
    case SET_USER: {
      const event = action.payload;
      return event;
    }
    default: {
      return state;
    }
  }
};

const currentUserConnections = (state = [], action) => {
  switch (action.type) {
    case SET_USER_CONNECTIONS: {
      const connections = action.payload;
      return connections;
    }
    default: {
      return state;
    }
  }
};

export default combineReducers({
  currentUser,
  currentUserConnections,
});
