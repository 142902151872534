import React, { useEffect, useState } from "react";
import "./calendarPreview.css";
import { useSelector } from "react-redux";
import Calendar from "react-calendar";
import { monthMap, seasonMap } from "../eventWhenCreateUtils";

function CalendarPreview({ hasExactDate }) {
  const creation = useSelector((state) => state.createReducer.currentCreation);
  const [calendarDate, setCalendarDate] = useState(null);
  const [calendarRefresh, setCalendarRefresh] = useState(false);

  useEffect(() => {
    configureCalendarDate();
  }, [creation.event_start_date]);

  function configureCalendarDate() {
    if (hasExactDate && new Date(creation.event_start_date).toString() !== "Invalid Date") {
        const date = new Date(creation.event_start_date);
        const offset = date.getTimezoneOffset();
        const localDate = new Date(date.getTime() + offset * 60000);
        setCalendarDate(localDate);
      }
      setCalendarRefresh(!calendarRefresh);
  }

  function dateShouldHighlight(date) {
    if ((!calendarDate && hasExactDate) || !date) {
      return false;
    }

    if (date < new Date(new Date().setHours(0, 0, 0, 0))) {
      return false;
    }

    if (hasExactDate) {
      if (creation.event_start_granularity === "Exact date") {
        // Exact Date
        return date.getDate() === calendarDate.getDate() && date.getMonth() === calendarDate.getMonth();
      }

      if (creation.event_start_granularity === "±1 day") {
        // +-1 day
        const calendarDatePlusOne = new Date(calendarDate);
        calendarDatePlusOne.setDate(calendarDatePlusOne.getDate() + 1);
        const calendarDateMinusOne = new Date(calendarDate);
        calendarDateMinusOne.setDate(calendarDateMinusOne.getDate() - 1);
        return date >= calendarDateMinusOne && date <= calendarDatePlusOne;
      }

      // +-2 days
      if (creation.event_start_granularity === "±2 days") {
        const calendarDatePlusTwo = new Date(calendarDate);
        calendarDatePlusTwo.setDate(calendarDatePlusTwo.getDate() + 2);
        const calendarDateMinusTwo = new Date(calendarDate);
        calendarDateMinusTwo.setDate(calendarDateMinusTwo.getDate() - 2);
        return date >= calendarDateMinusTwo && date <= calendarDatePlusTwo;
      }

      // +-3 days
      if (creation.event_start_granularity === "±3 days") {
        const calendarDatePlusThree = new Date(calendarDate);
        calendarDatePlusThree.setDate(calendarDatePlusThree.getDate() + 3);
        const calendarDateMinusThree = new Date(calendarDate);
        calendarDateMinusThree.setDate(calendarDateMinusThree.getDate() - 3);
        return date >= calendarDateMinusThree && date <= calendarDatePlusThree;
      }
    }

    let shouldHighlight = false;

    // Weekday
    if (creation.event_date_tags.includes("Weekday")) {
      shouldHighlight = shouldHighlight || (date.getDay() >= 1 && date.getDay() <= 5);
    }

    // Weekend
    if (creation.event_date_tags.includes("Weekend")) {
      shouldHighlight = shouldHighlight || date.getDay() === 0 || date.getDay() === 6;
    }

    // Month
    for (let i = 0; i < 12; i++) {
      if (creation.event_date_tags.includes(Object.keys(monthMap)[i])) {
        shouldHighlight = shouldHighlight || date.getMonth() === monthMap[Object.keys(monthMap)[i]];
      }
    }

    // Season
    const thisYear = new Date().getFullYear();
    for (let i = 0; i < 4; i++) {
      if (creation.event_date_tags.includes(Object.keys(seasonMap)[i] + " " + thisYear)) {
        if (Object.keys(seasonMap)[i] === "Winter") {
          shouldHighlight =
            shouldHighlight ||
            (date.getMonth() > 10 && seasonMap[Object.keys(seasonMap)[i]].includes(date.getMonth()) && date.getFullYear() === thisYear) ||
            (date.getMonth() < 10 && seasonMap[Object.keys(seasonMap)[i]].includes(date.getMonth()) && date.getFullYear() === thisYear + 1);
        } else {
          shouldHighlight = shouldHighlight || (seasonMap[Object.keys(seasonMap)[i]].includes(date.getMonth()) && date.getFullYear() === thisYear);
        }
      }
    }

    return shouldHighlight;
  }

  return (
    <>
      <div className="calendar-container">
        <div className="preview-label">Timeline Calendar</div>
        {(calendarRefresh || !calendarRefresh) && (
          <Calendar
            value={calendarDate}
            // onChange={(e) => {
            //   const dateString = e.toDateString().split(" ");
            //   const date = dateString[1] + " " + e.getDate() + ", " + e.getFullYear();
            //   dispatch({
            //     type: SET_CREATION_STEP,
            //     payload: {
            //       ...creation,
            //       event_start_date: date,
            //     },
            //   });
            // }}
            view="month"
            next2Label={null}
            prev2Label={null}
            tileDisabled={({ date }) => date < new Date(new Date().setHours(0, 0, 0, 0))}
            tileClassName={({ date }) => {
              if (dateShouldHighlight(date)) {
                const nextDate = new Date(date);
                nextDate.setDate(nextDate.getDate() + 1);
                const prevDate = new Date(date);
                prevDate.setDate(prevDate.getDate() - 1);
                if (!dateShouldHighlight(nextDate) && !dateShouldHighlight(prevDate)) {
                  // Single Selected Date
                  return "selected-date";
                } else if (!dateShouldHighlight(prevDate)) {
                  // First Date in Range
                  return "selected-date-start lightRange";
                } else if (!dateShouldHighlight(nextDate)) {
                  // Last Date in Range
                  return "selected-date-end lightRange";
                } else {
                  if (hasExactDate) {
                    const dateIsSelectedDate = date.getDate() === calendarDate.getDate() && date.getMonth() === calendarDate.getMonth();
                    return `selected-date-middle ${!dateIsSelectedDate ? "lightRange" : ""}`;
                  } else {
                    return "selected-date-middle lightRange";
                  }
                }
              }
            }}
          />
        )}
      </div>
    </>
  );
}

export default CalendarPreview;
