import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./details.css";
import EventOverview from "../../../components/event/eventOverview/eventOverview";
import EventImage from "../../../components/event/eventImage/eventImage";
import { GET_EVENT, GET_EVENT_TIERS, GET_EVENT_TRANSACTIONS } from "../../../redux/action/eventAction";
import EventAbout from "../../../components/event/eventAbout/eventAbout";
import ResponseManagement from "../../../components/eventManagement/responseManagement/responseManagement";
import EditWidgetOverlay from "../../../components/eventManagement/editWidgetOverlay/editWidgetOverlay";
import { useMediaQuery } from "../../../hooks/useMediaQuery";

function EventDetails() {
  const eventId = window.location.pathname.split("/")[2];
  const dispatch = useDispatch();
  const isDesktop = useMediaQuery("(min-width: 768px)");
  const event = useSelector((state) => state.eventReducer.currentEvent);
  const showEditWidget = useSelector((state) => state.dashboardReducer.showEditWidget);

  useEffect(() => {
    console.log("eventId: ", eventId);
    dispatch({ type: GET_EVENT, payload: eventId });
    dispatch({ type: GET_EVENT_TIERS, payload: eventId });
    dispatch({ type: GET_EVENT_TRANSACTIONS, payload: eventId });
  }, [dispatch, eventId]);

  if (event) {
    return (
      <div className="centerPage">
        <div className="eventDetailPage invisiScroll">
          {isDesktop ? (
            <div className="eventSplash grid">
              <EventOverview />
              <EventImage />
            </div>
          ) : (
            <div className="eventSplashMobile">
              <EventImage />
              <EventOverview />
            </div>
          )}
          <div className="eventGridContent grid">
            <EventAbout />
          </div>
          <ResponseManagement eventId={eventId} />
        </div>
        {showEditWidget && <EditWidgetOverlay />}
      </div>
    );
  }

  return null;
}

export default EventDetails;
