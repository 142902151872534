import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { SET_CREATION_STEP } from "../../../../redux/action/createAction";

function EventWhenTags({ text, isGranularity }) {
  const dispatch = useDispatch();
  const creation = useSelector((state) => state.createReducer.currentCreation);
  const hasExactDate = creation.exact_date;
  if (isGranularity) {
    return (
      <div
        className={`whereTag ${hasExactDate ? "" : "whereGrayText"} ${creation.event_start_granularity === text ? "whereTagSelected" : ""}`}
        onClick={() => {
          dispatch({
            type: SET_CREATION_STEP,
            payload: {
              ...creation,
              exact_date: true,
              event_start_granularity: text,
            },
          });
        }}
      >
        {text}
      </div>
    );
  }

  return (
    <div
      className={`whereTag ${hasExactDate ? "whereGrayText" : ""} ${creation.event_date_tags.includes(text) ? "whereTagSelected" : ""}`}
      onClick={() => {
        if (creation.event_date_tags.includes(text)) {
          dispatch({
            type: SET_CREATION_STEP,
            payload: {
              ...creation,
              exact_date: false,
              event_date_tags: creation.event_date_tags.filter((tag) => tag !== text),
            },
          });
        } else {
          dispatch({
            type: SET_CREATION_STEP,
            payload: {
              ...creation,
              exact_date: false,
              event_date_tags: [...creation.event_date_tags, text],
              event_start_date: new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toISOString().split("T")[0],
            },
          });
        }
      }}
    >
      {text}
    </div>
  );
}

export default EventWhenTags;
