import React, { useEffect } from "react";
import "./eventTypeCreate.css";
import EventTypeButton from "../../button/eventTypeButton/eventTypeButton";
import CreationNavBar from "../creationNavBar/creationNavBar";
import EventTileY from "../../eventTile/eventTileY";
import { useDispatch, useSelector } from "react-redux";
import { GET_NEARBY_EVENTS } from "../../../redux/action/eventAction";

function EventTypeCreate() {
  const dispatch = useDispatch();
  const nearbyEvents = useSelector((state) => state.landingReducer.nearbyEvents);

  useEffect(() => {
    dispatch({ type: GET_NEARBY_EVENTS, payload: "c2b2" });
  }, [dispatch]);

  useEffect(() => {
    document.documentElement.style.setProperty("--no-of-slides", nearbyEvents.length);
  }, [nearbyEvents]);

  return (
    <>
      <div className="eventCreationLeft invisiScroll">
        <div>
          <div className="eventCreationTitle">{"Let's get started"}</div>
          <div className="eventCreationSubTitle">
            Select an event type based on how you want to organise and sell tickets.
          </div>
          <div className="eventTypeCreateButtonOptions">
            <EventTypeButton type={"crowdfund"} />
            <EventTypeButton type={"paid"} />
            <EventTypeButton type={"free"} />
          </div>
        </div>
        <CreationNavBar />
      </div>
      <div className="eventCreationRight eventTypePadding">
        <div className="eventTypeSlideComponent">
          {Object.values(nearbyEvents.slice(0, 5)).map((slide) => (
            <EventTileY key={slide.event_id} event={slide} />
          ))}
          {Object.values(nearbyEvents.slice(0, 5)).map((slide) => (
            <EventTileY key={slide.event_id + "b"} event={slide} />
          ))}
        </div>
        <div className="eventTypeSlideComponent eventTypeSlideComponentOffset">
          {Object.values(nearbyEvents.slice(5)).map((slide) => (
            <EventTileY key={slide.event_id} event={slide} isOffset={true} />
          ))}
          {Object.values(nearbyEvents.slice(5)).map((slide) => (
            <EventTileY key={slide.event_id + "b"} event={slide} isOffset={true} />
          ))}
        </div>
      </div>
    </>
  );
}

export default EventTypeCreate;
