import React from "react";
import "./eventDetailTags.css";

function EventDetailTags({ image, text, action }) {
  return (
    <div key={text} className={`confirmationTag ${action ? "clickable" : ""}`} onClick={() => {
      if (action) {
        action();
      }
    }}>
      <img src={image} className="confirmationTagIcon" alt="Tag" />
      <div className="confirmationTagText">{text}</div>
    </div>
  );
}

export default EventDetailTags;
