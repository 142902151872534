import React, { useEffect, useRef, useState } from "react";
import "./photoWidget.css";
import "../widgetStyling.css";
import { useDispatch, useSelector } from "react-redux";
import { SET_EDIT_WIDGET_CONTENT, SET_EDIT_WIDGET_STEP, TOGGLE_EDIT_WIDGET } from "../../../redux/action/dashboardAction";
import { S3Bucket } from "../../../Constants";
import { getRandomImageFromPath, uploadGalleryPictureToS3 } from "../../../utils/s3/s3Controller";
import Plus from "../../../assets/Plus.png";
import { v4 as uuidv4 } from "uuid";

function PhotoWidget({ live, data }) {
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const currentUser = useSelector((state) => state.userReducer.currentUser);
  const eventTicket = useSelector((state) => state.eventReducer.currentEventTicket);
  const currentEvent = useSelector((state) => state.eventReducer.currentEvent);
  const showEditWidget = useSelector((state) => state.dashboardReducer.showEditWidget);
  const [imageList, setImageList] = useState([]);
  const [showExpand, setShowExpand] = useState(false);

  useEffect(() => {
    if (currentEvent.event_id) {
      getRandomImageFromPath("event/" + currentEvent.event_id).then((res) => {
        res.sort(() => Math.random() - 0.5);
        setImageList(res);
      });
    }
  }, [currentEvent]);

  const onChangePicture = (e) => {
    if (e.target.files[0]) {
      uploadGalleryPictureToS3(`event/${currentEvent.event_id}/${uuidv4()}.jpg`, e.target.files[0]);
    }
  };

  return (
    <div
      className={`widgetView relativeView ${!live && !showEditWidget ? "clickable" : ""}`}
      onClick={() => {
        if (!live && !showEditWidget) {
          dispatch({ type: TOGGLE_EDIT_WIDGET });
          dispatch({
            type: SET_EDIT_WIDGET_CONTENT,
            payload: {
              type: "photo",
              size: data.component_size,
              id: data.dashboard_id,
              index: data.component_index,
            },
          });
          dispatch({ type: SET_EDIT_WIDGET_STEP, payload: "photo" });
        }
      }}
    >
      {!live && <div className="widgetTitle">Gallery</div>}
      {data.component_size == "small"
        ? live &&
          imageList.length > 0 && (
            <div className="galleryImageSmallContainer">
              <img src={`${S3Bucket}${imageList[0]}`} className="galleryImageSmall" alt="event" />
              <img src={`${S3Bucket}${imageList[1]}`} className="galleryImageSmall" alt="event" />
            </div>
          )
        : live && imageList.length > 0 && <img src={`${S3Bucket}${imageList[0]}`} className="galleryImageLarge" alt="event" />}
      <div
        className="galleryOverlay"
        onClick={() => {
          setShowExpand(true);
        }}
      >
        {currentUser.user_id && eventTicket.length > 0 && (
          <div
            className="addGalleryImage"
            onClick={(e) => {
              e.stopPropagation();
              inputRef.current.click();
            }}
          >
            <img src={Plus} className="addGalleryIcon" alt="plus" />
            Add
            <input ref={inputRef} style={{ display: "none" }} type="file" onChange={onChangePicture} />
          </div>
        )}
      </div>
      {showExpand ? (
        <div
          className="photoExpandView"
          onClick={(e) => {
            e.stopPropagation();
            setShowExpand(false);
          }}
        >
          <div
            className="photoExpand invisiScroll"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {imageList.map((image, index) => (
              <img key={index} src={`${S3Bucket}${image}`} className="galleryImageSmallContainerExpand" alt="event" />
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default PhotoWidget;
