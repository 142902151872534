import React from "react";
import "../widgetStyling.css";
import "./newWidget.css";
import { useDispatch } from "react-redux";
import { SET_EDIT_WIDGET_CONTENT, SET_EDIT_WIDGET_STEP, TOGGLE_EDIT_WIDGET } from "../../../redux/action/dashboardAction";

function NewWidget({ nextIndex }) {
  const dispatch = useDispatch();
  return (
    <div
      className="newWidgetHolder"
      onClick={() => {
        dispatch({ type: TOGGLE_EDIT_WIDGET });
        dispatch({ type: SET_EDIT_WIDGET_STEP, payload: "new" });
        dispatch({ type: SET_EDIT_WIDGET_CONTENT, payload: {
          index: nextIndex,
        } });
      }}
    >
      <div className="newWidgetTitle">Click to add</div>
    </div>
  );
}

export default NewWidget;
