import React, { useEffect, useState } from "react";
import "./header.css";
import Logo from "../../assets/Logo.png";
import { useSelector } from "react-redux";
import ProfilePicture from "../user/profilePicture/profilePicture";
import { useMediaQuery } from "../../hooks/useMediaQuery";

function Header() {
  const [isAccountPage, setIsAccountPage] = useState(false);
  const currentUser = useSelector((state) => state.userReducer.currentUser);
  const authenticated = currentUser !== "Guest";

  useEffect(() => {
    const urlPath = window.location.pathname;
    setIsAccountPage(urlPath === "/signup" || urlPath === "/login");
  }, [window]);

  function HeaderAccountContainer() {
    const isDesktop = useMediaQuery("(min-width: 768px)");
    const [showMenu, setShowMenu] = useState(false);

    if (!isDesktop) {
      const outsideClickListener = (event) => {
        if (!event.target.closest(".AccountContainer")) {
          setShowMenu(false);
          removeClickListener();
        }
      };

      const removeClickListener = () => {
        document.removeEventListener("click", outsideClickListener);
      };

      return (
        <div className="AccountContainer">
          <div
            className="AccountContainerInner"
            onClick={() => {
              setShowMenu(true);
              document.addEventListener("touchstart", outsideClickListener);
            }}
          >
            <ProfilePicture userId={currentUser.user_id} />
          </div>
          {showMenu && (
            <div className="Menu">
              <div
                className="AccountContainerInner"
                onClick={() => {
                  setShowMenu(false);
                  window.location.href = "/create";
                }}
              >
                <div className="SubNavText">Propose Event</div>
              </div>
              <div
                className="AccountContainerInner"
                onClick={() => {
                  setShowMenu(false);
                  window.location.href = "/myEvents";
                }}
              >
                <div className="SubNavText">Your Events</div>
              </div>
              <div
                className="AccountContainerInner"
                onClick={() => {
                  setShowMenu(false);
                  window.location.href = "/myProfile";
                }}
              >
                <div className="SubNavText">My Profile</div>
              </div>
            </div>
          )}
        </div>
      );
    }

    return (
      <div className="AccountContainer">
        <div
          className="AccountContainerInner"
          onClick={() => {
            window.location.href = "/create";
          }}
        >
          <div className="SubNavText">Propose Event</div>
        </div>
        <div
          className="AccountContainerInner"
          onClick={() => {
            window.location.href = "/myEvents";
          }}
        >
          <div className="SubNavText">Your Events</div>
        </div>
        <div
          className="AccountContainerInner"
          onClick={() => {
            window.location.href = "/myProfile";
          }}
        >
          <div className="AccountText">Hi, {currentUser.user_first_name}</div>
          <ProfilePicture userId={currentUser.user_id} />
        </div>
      </div>
    );
  }

  return (
    <div className="Header" style={{ visibility: isAccountPage ? "hidden" : "visible" }}>
      <div
        className="LogoContainer"
        onClick={() => {
          window.location.href = "/";
        }}
      >
        <img className="Logo" src={Logo} alt="SonderLogo" />
      </div>
      {authenticated ? (
        <HeaderAccountContainer />
      ) : (
        <div className="AccountContainer">
          <div
            className="AccountActionText"
            onClick={() => {
              window.location.href = "/signup";
            }}
          >
            Sign Up
          </div>
        </div>
      )}
    </div>
  );
}

export default Header;
