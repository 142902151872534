import defaultAxios from "axios";
import { backend } from "../../Constants";

const axios = defaultAxios.create({
  baseURL: backend,
  headers: { "Content-Type": "application/json" },
});

export const getNearbyEventsApi = async (payload) => {
  try {
    const token = JSON.parse(localStorage.getItem("currentUser"))?.token ?? "";
    var headers = {
      Authorization: `${token}`,
    };
    if (payload) {
      const location_hash = payload;
      const nearbyEvents = await axios.get(`landing/nearby/${location_hash}`, {
        headers: headers,
      });
      return nearbyEvents;
    }
  } catch (err) {
    return console.error(err);
  }
};

export const getNearlyFundedEventsApi = async () => {
  try {
    const token = JSON.parse(localStorage.getItem("currentUser"))?.token ?? "";
    var headers = {
      Authorization: `${token}`,
    };
    const nearlyFundedEvents = await axios.get("landing/nearly", {
      headers: headers,
    });
    return nearlyFundedEvents;
  } catch (err) {
    return console.error(err);
  }
};

export const getNewEventsApi = async (payload) => {
  try {
    const token = JSON.parse(localStorage.getItem("currentUser"))?.token ?? "";
    var headers = {
      Authorization: `${token}`,
    };
    if (payload) {
      const location_hash = payload;
      const newEvents = await axios.get(`landing/new/${location_hash}`, {
        headers: headers,
      });
      return newEvents;
    } else {
      const newEvents = await axios.get("landing/new/", {
        headers: headers,
      });
      return newEvents;
    }
  } catch (err) {
    return console.error(err);
  }
};

export const getEventsByKeywordApi = async (payload) => {
  try {
    const token = JSON.parse(localStorage.getItem("currentUser"))?.token ?? "";
    var headers = {
      Authorization: `${token}`,
    };
    if (payload) {
      const keyword = payload;
      const searchResults = await axios.get(`landing/search/${keyword}`, {
        headers: headers,
      });
      return searchResults;
    }
  } catch (err) {
    return console.error(err);
  }
};
