import { combineReducers } from "redux";
import {
  SET_DASHBOARD,
  SET_EDIT_WIDGET_CONTENT,
  SET_EDIT_WIDGET_STEP,
  TOGGLE_EDIT_MODE,
  TOGGLE_EDIT_WIDGET,
} from "../action/dashboardAction";

const defaultDashboard = [];

const currentDashboard = (state = defaultDashboard, action) => {
  switch (action.type) {
    case SET_DASHBOARD: {
      const mappedPayload = action.payload.map((data) => {
        return {
          ...data,
          type: data.component_type,
        };
      });
      const dashboard = [...defaultDashboard, ...mappedPayload];
      return dashboard;
    }
    default: {
      return state;
    }
  }
};

const dashboardEditMode = (state = false, action) => {
  switch (action.type) {
    case TOGGLE_EDIT_MODE: {
      return !state;
    }
    default: {
      return state;
    }
  }
};

const showEditWidget = (state = false, action) => {
  switch (action.type) {
    case TOGGLE_EDIT_WIDGET: {
      return !state;
    }
    default: {
      return state;
    }
  }
};

const editWidgetStep = (state = "new", action) => {
  switch (action.type) {
    case SET_EDIT_WIDGET_STEP: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
};

const editWidgetContent = (state = {}, action) => {
  switch (action.type) {
    case SET_EDIT_WIDGET_CONTENT: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
};

export default combineReducers({
  currentDashboard,
  dashboardEditMode,
  showEditWidget,
  editWidgetStep,
  editWidgetContent,
});
