import React from "react";
import "./eventTile.css";
import { S3Bucket } from "../../Constants";
import { useNavigate } from "react-router-dom";
// import CardFundingBar from "../fundingBar/cardFundingBar/cardFundingBar";

function EventTileY({ event, isOffset }) {
  const navigate = useNavigate();
  return (
    <div
      className={`EventTileY ${isOffset ? "EventTileYFast" : ""}`}
      // onMouseEnter={() => {
      //   // stop animation on hover
      //   let tiles = document.getElementsByClassName("EventTileY");
      //   for (let element of tiles) {
      //     element.style.animationPlayState = "paused";
      //   }
      // }}
      // onMouseLeave={() => {
      //   setTimeout(() => {
      //     // resume animation on hover
      //     let tiles = document.getElementsByClassName("EventTileY");
      //     for (let element of tiles) {
      //       element.style.animationPlayState = "running";
      //     }
      //   }, 1000);
      // }}
      onClick={() => {
        navigate(`/details/${event.event_id}`);
      }}
    >
      <div
        className="EventContentY"
        style={{
          backgroundImage: `url('${event.event_picture.includes("http") ? event.event_picture : `${S3Bucket}event/${event.event_id}/${event.event_id}.jpg`}')`,
        }}
      >
        <div className="ImageMaskY" />
        {/* <CardFundingBar event={event} /> */}
        <div className="EventTileInfo">
          <div className="EventTileText EventTileInfoTitle">{event.event_name}</div>
          {/* <div className="EventTileText EventTileInfoHost">
            {event.user_first_name} {event.user_last_name}
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default EventTileY;
