import React from "react";
import "./liveDashboardToggleWidget.css";
import ArrowIconBlack from "../../../assets/ArrowIconBlack.png";
import { useDispatch, useSelector } from "react-redux";
import { TOGGLE_EDIT_MODE } from "../../../redux/action/dashboardAction";

function LiveDashboardToggleWidget() {
  const dispatch = useDispatch();
  const dashboardEdit = useSelector((state) => state.dashboardReducer.dashboardEditMode);

  return (
    <div
      className="liveDashboardWidget"
      onClick={() => {
        dispatch({ type: TOGGLE_EDIT_MODE });
      }}
    >
      <div className="widgetTitle">{dashboardEdit ? "View Live Dashboard" : "Edit Dashboard"}</div>
      <img className="buttonArrow" src={ArrowIconBlack} alt="ArrowIcon" />
    </div>
  );
}

export default LiveDashboardToggleWidget;
