import React from "react";
import "./rsvpConfirmation.css";
import Check from "../../../assets/Check.png";
import { useDispatch, useSelector } from "react-redux";
import { DELETE_RSVP } from "../../../redux/action/eventAction";

function RSVPConfirmation() {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.userReducer.currentUser);
  const event = useSelector((state) => state.eventReducer.currentEvent);
  const currentEventTicket = useSelector((state) => state.eventReducer.currentEventTicket);

  return (
    <div className="RSVPHolder">
      <div className="rsvpImage">
        <img className="rsvpCheck" src={Check} alt="rsvpCheck" />
      </div>
      <div className="rsvpContent">
        <div className="rsvpTitle">{"You're Signed Up!"}</div>
        <div className="rsvpSubtitle">Sit back, relax and get excited.</div>
        <div
          className="removeRsvp"
          onClick={() => {
            currentEventTicket.forEach((ticket) => {
              dispatch({ type: DELETE_RSVP, payload: { event_id: event.event_id, user_id: currentUser.user_id, transaction_id: ticket.transaction_id } });
            });
          }}
        >
          Remove RSVP
        </div>
      </div>
    </div>
  );
}

export default RSVPConfirmation;
