import React from "react";
import "./usernameComponent.css";
import ProfilePicture from "../profilePicture/profilePicture";

function UsernameComponent({ userId, firstName, lastName }) {
  return (
    <>
      <div className="creationBy">
        <ProfilePicture userId={userId} />
        <div>{`${firstName} ${lastName}'s`}</div>
      </div>
    </>
  );
}

export default UsernameComponent;
