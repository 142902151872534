import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { Provider } from "react-redux";
import Store from "./redux/store";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { GridProvider } from "./components/eventManagement/responseManagement/Grid";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={Store}>
    <DndProvider backend={HTML5Backend}>
      <GridProvider>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </GridProvider>
    </DndProvider>
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
