import React, { createContext, memo, useEffect, useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { useDispatch, useSelector } from "react-redux";
import { SET_DASHBOARD, UPDATE_DASHBOARD_INDEX } from "../../../redux/action/dashboardAction";

function move(array, oldIndex, newIndex) {
  if (newIndex >= array.length) {
    newIndex = array.length - 1;
  }
  array.splice(newIndex, 0, array.splice(oldIndex, 1)[0]);
  return array;
}

function moveElement(array, index, offset) {
  const newIndex = index + offset;
  return move(array, index, newIndex);
}

export const GridContext = createContext({ items: [] });

export function GridProvider({ children }) {
  const dispatch = useDispatch();
  const currentDashboard = useSelector((state) => state.dashboardReducer.currentDashboard);
  const dashboardEdit = useSelector((state) => state.dashboardReducer.dashboardEditMode);
  const dashL = currentDashboard.filter((data, index) => index % 2 === 0);
  const dashR = currentDashboard.filter((data, index) => index % 2 !== 0);

  function useCancelableFunction(func) {
    const isRunningRef = useRef(false);

    useEffect(() => {
      return () => {
        // Ensure that isRunning is set to false when the component unmounts
        isRunningRef.current = false;
      };
    }, []);

    const executeFunction = async (...args) => {
      if (!isRunningRef.current) {
        try {
          isRunningRef.current = true;
          await func(...args);
        } finally {
          isRunningRef.current = false;
        }
      } else {
        // console.log("Function is already running and cannot be called again.");
      }
    };

    return executeFunction;
  }

  const moveItem = useCancelableFunction(async (sourceId, destinationId) => {
    if (!dashboardEdit) {
      return null;
    }

    let fromList = "l";
    let toList = "l";
    let sourceIndex = dashL.findIndex((item) => item.dashboard_id === sourceId);
    if (sourceIndex === -1) {
      sourceIndex = dashR.findIndex((item) => item.dashboard_id === sourceId);
      fromList = "r";
    }
    let destinationIndex = dashL.findIndex((item) => item.dashboard_id === destinationId);
    if (destinationIndex === -1) {
      destinationIndex = dashR.findIndex((item) => item.dashboard_id === destinationId);
      toList = "r";
    }

    // If source/destination is unknown, do nothing.
    if (sourceId === -1 || destinationId === -1) {
      return;
    }

    const offset = destinationIndex - sourceIndex;
    let newDashL = dashL;
    let newDashR = dashR;
    if (fromList === "l" && toList === "l") {
      moveElement(newDashL, sourceIndex, offset);
    } else if (fromList === "r" && toList === "r") {
      moveElement(newDashR, sourceIndex, offset);
    } else if (fromList === "l" && toList === "r") {
      newDashR.push(newDashL[sourceIndex]);
      const newSourceIndex = newDashR.length - 1;
      const newOffset = destinationIndex - newSourceIndex;
      moveElement(newDashR, newSourceIndex, newOffset);
      newDashL = newDashL.filter((_, index) => index !== sourceIndex);
    } else if (fromList === "r" && toList === "l") {
      newDashL.push(newDashR[sourceIndex]);
      const newSourceIndex = newDashL.length - 1;
      const newOffset = destinationIndex - newSourceIndex;
      moveElement(newDashL, newSourceIndex, newOffset);
      newDashR = newDashR.filter((_, index) => index !== sourceIndex);
    }

    let newDash = [];
    let longestDash = newDashL.length > newDashR.length ? newDashL : newDashR;
    for (let i = 0; i < longestDash.length; i++) {
      if (newDashL[i]) {
        newDash.push(newDashL[i]);
      }
      if (newDashR[i]) {
        newDash.push(newDashR[i]);
      }
    }
    dispatch({ type: SET_DASHBOARD, payload: newDash });
    await new Promise((resolve) => setTimeout(resolve, 1000));
    newDash.forEach((item, index) => {
      dispatch({ type: UPDATE_DASHBOARD_INDEX, payload: { dashboard_id: item.dashboard_id, index } });
    });
  });

  return <GridContext.Provider value={{ currentDashboard, moveItem }}>{children}</GridContext.Provider>;
}

/* eslint-disable react/display-name */
export const DragItem = memo(({ id, onMoveItem, children }) => {
  const ref = useRef(null);

  const [{ isDragging }, connectDrag] = useDrag({
    type: "IMG",
    item: { id },
    collect: (monitor) => {
      return {
        isDragging: monitor.isDragging(),
      };
    },
  });

  const [, connectDrop] = useDrop({
    accept: "IMG",
    hover(hoveredOverItem) {
      if (hoveredOverItem.id !== id) {
        onMoveItem(hoveredOverItem.id, id);
      }
    },
  });

  connectDrag(ref);
  connectDrop(ref);

  const opacity = isDragging ? 0.5 : 1;
  const containerStyle = { opacity };

  return React.Children.map(children, (child) =>
    React.cloneElement(child, {
      forwardedRef: ref,
      style: { ...child.props.style, ...containerStyle },
    }),
  );
});

function GridItem({ forwardedRef, ...props }) {
  return <div ref={forwardedRef} {...props} />;
}

export default GridItem;
