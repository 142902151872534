import React from "react";
import "./createEventNextButton.css";
import ArrowIcon from "../../../assets/ArrowIcon.png";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { CLEAR_CREATION, POST_EVENT, SET_CREATION_STEP } from "../../../redux/action/createAction";
import { canProceedToNext, validateInput } from "./createEventUtils";
import { uploadEventPictureToS3 } from "../../../utils/s3/s3Controller";

function CreateEventNextButton() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const creation = useSelector((state) => state.createReducer.currentCreation);
  const currentUser = useSelector((state) => state.userReducer.currentUser);

  return (
    <div
      className="CreateEventNextButton"
      onClick={() => {
        console.log(creation);
        if (!canProceedToNext(creation)) {
          return;
        }
        let nextStage = creation.stage + 1;
        if (creation.stage === 3 && creation.event_type === "free") {
          nextStage = 5;
        }
        dispatch({
          type: SET_CREATION_STEP,
          payload: {
            ...creation,
            stage: Math.min(nextStage, 5),
            maxStage: Math.max(nextStage, creation.maxStage),
          },
        });
        if (creation.stage === 5 && currentUser && validateInput(creation)) {
          const eventId = creation.event_id ?? uuidv4();
          if (creation.event_picture && creation.event_picture.type !== undefined) {
            uploadEventPictureToS3(eventId, creation);
          }

          const yearLater = new Date();
          yearLater.setFullYear(yearLater.getFullYear() + 1);

          dispatch({
            type: POST_EVENT,
            payload: {
              event_id: eventId,
              event_name: creation.event_name ?? "My Mystery Event",
              event_tldr: creation.event_tldr ?? "This is a mystery event",
              event_description: creation.event_description ?? "",
              event_picture: `s3://host-sonder/event/${eventId}/${eventId}.jpg`,
              exact_date: creation.exact_date ?? true,
              event_start_timestamp: creation?.event_start_timestamp ? new Date(creation.event_start_timestamp).toISOString() : yearLater.toISOString(),
              event_start_granularity: creation.event_start_granularity ?? "Exact date",
              event_date_tags: creation.event_date_tags ?? [],
              event_duration: creation.event_duration ?? 86400,
              event_host: currentUser.user_id ?? "user1",
              exact_location: creation.exact_location ?? true,
              event_location: creation.event_location ?? "Vancouver",
              event_location_hash: creation.event_location_hash ?? "c2b2qd45q",
              event_location_noun: creation.event_location_noun ?? "Venue",
              event_location_place: creation.event_location_place ?? "Vancouver",
              event_capacity: 100,
              event_category: creation.event_category ?? "",
              event_fund_target: creation.event_fund_target ?? 0,
              event_status: creation.event_status ?? "Open",
              event_type: creation.event_type ?? "crowdfund",
              event_backers: creation.event_backers ?? 0,
              event_tiers: (creation.event_tiers ?? []).map((t) => {
                return {
                  ...t,
                  tier_event: eventId,
                };
              }),
              event_sponsors: creation.event_sponsors ?? [],
              event_categories: creation.event_categories ?? [],
            },
          });
          setTimeout(() => {
            dispatch({ type: CLEAR_CREATION });
            navigate(`/details/${eventId}`);
          }, 2000);
        } else if (creation.stage === 5) {
          alert("Please fill out all the fields.");
        }
      }}
    >
      <div className="CreateEventNextText">{creation.stage < 7 ? "Continue" : "Create Event"}</div>
      <img className="CreateEventNextArrow" src={ArrowIcon} alt="ArrowIcon" />
    </div>
  );
}

export default CreateEventNextButton;
