import React from "react";
import "./eventCreationBackButton.css";
import "../buttonStyle.css";
import ArrowIconGray from "../../../assets/ArrowIconGray.png";
import { useDispatch, useSelector } from "react-redux";
import { SET_CREATION_STEP } from "../../../redux/action/createAction";

function EventCreationBackButton() {
  const dispatch = useDispatch();
  const creation = useSelector((state) => state.createReducer.currentCreation);
  return (
    <div
      className="buttonHolder buttonHolderGray"
      onClick={() => {
        let nextStage = creation.stage - 1;
        if (creation.stage === 5 && creation.event_type === "free") {
          nextStage = 3;
        }
        dispatch({
          type: SET_CREATION_STEP,
          payload: {
            ...creation,
            stage: Math.min(nextStage, 6),
          },
        });
      }}
    >
      <img className="buttonArrow arrowFlip" src={ArrowIconGray} alt="ArrowIcon" />
    </div>
  );
}

export default EventCreationBackButton;
