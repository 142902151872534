export function validateSignup(firstName, lastName, email, password, confirmPassword) {
  if (password !== confirmPassword) {
    alert("Passwords do not match");
    return false;
  }
  if (password.length < 6) {
    alert("Password must be at least 6 characters");
    return false;
  }
  if (firstName.length === 0) {
    alert("First name cannot be empty");
    return false;
  }
  if (lastName.length === 0) {
    alert("Last name cannot be empty");
    return false;
  }
  if (email.length === 0) {
    alert("Email cannot be empty");
    return false;
  }
  if (password.length === 0) {
    alert("Password cannot be empty");
    return false;
  }
}

export function validateLogin(email, password) {
  if (email.length === 0) {
    alert("Email cannot be empty");
    return false;
  }
  if (password.length === 0) {
    alert("Password cannot be empty");
    return false;
  }
}