/* eslint-disable react/no-unknown-property */
import React, { useEffect, useState } from "react";
import "./map.css";
import GoogleMap from "google-maps-react-markers";
import { v4 as uuidv4 } from "uuid";
// import { useDispatch, useSelector } from "react-redux";
// import { SET_CREATION_STEP } from "../../redux/action/createAction";
// import ngeohash from "ngeohash";
import LocationPin from "../../assets/LocationPin.png";

function Map({ searchLocation, lat, lng, mode }) {
  const defaultLocation = {
    address: "Vancouver, BC, Canada",
    lat: 49.260833,
    lng: -123.113889,
  };
  // const dispatch = useDispatch();
  // const creation = useSelector((state) => state.createReducer.currentCreation);

  const [eventCoords, setEventCoords] = useState();
  const [key, setKey] = useState(0);
  const mapRef = React.useRef(null);

  const onGoogleApiLoaded = ({ map }) => {
    mapRef.current = map;
    // window.google.maps.event.addDomListener(mapRef.current, "click", (e) => {
    //   console.log(e.latLng.lat(), e.latLng.lng());
    //   setKey(uuidv4());
    //   setEventCoords({
    //     lat: e.latLng.lat(),
    //     lng: e.latLng.lng(),
    //   });
    //   dispatch({
    //     type: SET_CREATION_STEP,
    //     payload: {
    //       ...creation,
    //       event_location: searchLocation ? creation.event_location : `${e.latLng.lat()}, ${e.latLng.lng()}`,
    //       event_location_hash: ngeohash.encode(e.latLng.lat(), e.latLng.lng()),
    //     },
    //   });
    // });
  };

  useEffect(() => {
    if (searchLocation) {
      setKey(uuidv4());
      setEventCoords({
        lat: searchLocation.results[0].geometry.location.lat,
        lng: searchLocation.results[0].geometry.location.lng,
      });
    }

    if (lat && lng) {
      setKey(uuidv4());
      setEventCoords({
        lat: lat,
        lng: lng,
      });
    }
  }, [searchLocation, lat, lng]);

  function zoomLevel() {
    if (mode === "Preview") {
      return 13;
    }

    return 15;
  }

  return (
    <div className="mapHolder" key={key}>
      <GoogleMap
        ref={mapRef}
        apiKey={process.env.REACT_APP_GOOGLE_MAPS_API}
        onGoogleApiLoaded={onGoogleApiLoaded}
        defaultCenter={eventCoords ? eventCoords : defaultLocation}
        defaultZoom={zoomLevel()}
        options={{
          mapTypeControl: false,
          streetViewControl: false,
          fullscreenControl: false,
          zoomControl: false,
          styles: [
            {
              featureType: "poi",
              elementType: "labels",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
            {
              featureType: "road.arterial",
              elementType: "labels",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
          ],
        }}
      >
        {eventCoords && (
          <div lat={eventCoords.lat} lng={eventCoords.lng} className="eventMarker">
            <img className="pinImage" src={LocationPin} />
          </div>
        )}
      </GoogleMap>
    </div>
  );
}

export default Map;
