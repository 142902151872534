import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import Landing from "./screens/landing/landing";
import EventCreate from "./screens/event/create/create";
import EventDetails from "./screens/event/details/details";
import EventTransaction from "./screens/event/transaction/transaction";
import TransactionResult from "./screens/event/transactionResult/transactionResult";
import Header from "./components/header/header";
import MyEvents from "./screens/myEvents/myEvents";
import SignUp from "./screens/account/signup/signup";
import Login from "./screens/account/login/login";
import { useDispatch, useSelector } from "react-redux";
import { GET_USER, SET_USER } from "./redux/action/userAction";
import Search from "./screens/search/search";
import TicketScan from "./screens/ticketScan/ticketScan";
import Profile from "./screens/account/profile/profile";

function App() {
  // Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  const firebaseConfig = {
    apiKey: "AIzaSyBrd3gyW3Xq3nJuk8VFUDWoSmArd9XK2V8",
    authDomain: "host-sonder.firebaseapp.com",
    projectId: "host-sonder",
    storageBucket: "host-sonder.appspot.com",
    messagingSenderId: "528139638736",
    appId: "1:528139638736:web:b95f8a02e457c387b64bd5",
    measurementId: "G-WVDYECM9QL",
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  getAnalytics(app);
  const auth = getAuth(app);

  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.userReducer.currentUser);

  // Handle user state changes
  function onAuthStateChanged(newUser) {
    if (newUser) {
      dispatch({ type: GET_USER, payload: newUser.uid });
      newUser.getIdToken(true).then((token) => {
        localStorage.setItem("currentUser", JSON.stringify({ token: token }));
      });
    } else {
      dispatch({ type: SET_USER, payload: "Guest" });
    }
  }

  useEffect(() => {
    const subscriber = auth.onAuthStateChanged(onAuthStateChanged);
    return subscriber; // unsubscribe on unmount
  }, []);

  if (currentUser === null) {
    return null;
  }

  const authenticated = currentUser !== "Guest";

  return (
    <div className="App">
      <Header />
      <div className="AppContent">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/login" element={<Login />} />
            <Route path="/create" element={authenticated ? <EventCreate /> : <Login />} />
            <Route path="/edit/:eventId" element={authenticated ? <EventCreate /> : <Login />} />
            <Route path="/details/:eventId" element={<EventDetails />} />
            <Route path="/transaction/:eventId" element={<EventTransaction />} />
            <Route path="/transactionResult" element={<TransactionResult />} />
            <Route path="/search/:keyword" element={<Search />} />
            <Route path="/myEvents" element={authenticated ? <MyEvents /> : <Login />} />
            <Route path="/scan/:eventId" element={authenticated ? <TicketScan /> : <Login />} />
            <Route path="/myProfile" element={authenticated ? <Profile /> : <Login />} />
          </Routes>
        </BrowserRouter>
      </div>
    </div>
  );
}

export default App;
