import React, { useEffect, useState } from "react";
import "./guestlistWidget.css";
import SaveWidgetButton from "../../button/saveWidgetButton/saveWidgetButton";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import GuestlistWidget from "./guestlistWidget";
import RemoveWidgetButton from "../../button/removeWidgetButton/removeWidgetButton";
import CloseWidgetButton from "../../button/closeWidgetButton/closeWidgetButton";

function EditGuestListWidget() {
  const currentEvent = useSelector((state) => state.eventReducer.currentEvent);
  const editWidgetContent = useSelector((state) => state.dashboardReducer.editWidgetContent);
  const [widgetId, setWidgetId] = useState(uuidv4());
  const [size, setSize] = useState("small");

  useEffect(() => {
    if (editWidgetContent.type === "guestlist") {
      console.log(editWidgetContent);
      setWidgetId(editWidgetContent.id);
      setSize(editWidgetContent.size);
    }
  }, [editWidgetContent]);

  return (
    <div
      className="editWidgetModal"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div className="editWidgetContent">
        <div className="editWidgetMainContent">
          <div className="editWidgetTitleRow">
            <div className="editWidgetTitle">Show off your Guestlist</div>
            <CloseWidgetButton />
          </div>
          <div className="editWidgetDesc">Allow your community to see who is attending.</div>
          <div className="sizeSelector">
            {/* <div className="sizeSelectorText">
              <div
                className="editWidgetQuestionTitle"
                onClick={() => {
                  setSize("small");
                }}
              >
                Small
              </div>
              <div
                className="editWidgetQuestionTitle"
                onClick={() => {
                  setSize("large");
                }}
              >
                Large
              </div>
            </div>
            <div className="sizeSelectorBar">
              <div className={size === "small" ? "sizeSelectorBarSmall" : "sizeSelectorBarLarge"} />
            </div> */}
            <div className={`sizeSample ${size === "large" ? "sizeSampleLarge" : ""}`}>
              <GuestlistWidget
                live={false}
                data={{
                  id: widgetId,
                  type: "guestlist",
                  event_id: currentEvent.event_id,
                  component_size: size,
                  index: editWidgetContent?.index ?? 0,
                }}
              />
            </div>
          </div>
        </div>
        <div className="saveAction">
          {editWidgetContent?.id ? (
            <RemoveWidgetButton widget={{ dashboard_id: widgetId, event_id: currentEvent.event_id }} />
          ) : null}
          <SaveWidgetButton
            saveContent={{
              id: widgetId,
              type: "guestlist",
              title: "",
              event_id: currentEvent.event_id,
              answers: [],
              size: size,
              index: editWidgetContent?.index ?? 0,
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default EditGuestListWidget;
