import defaultAxios from "axios";
import { backend } from "../../../Constants";

const axios = defaultAxios.create({
  baseURL: backend,
  headers: { "Content-Type": "application/json" },
});

export const postContributionApi = async (payload) => {
  try {
    // const token = JSON.parse(localStorage.getItem("currentUser")).token;
    // var headers = {
    //   Authorization: `Bearer ${token}`,
    // };
    const data = JSON.stringify({
      id: payload.id,
      dashboard_id: payload.dashboard_id,
      item_name: payload.item_name,
      requested_by: payload.requested_by,
      brought_by: payload.brought_by,
      brought: payload.brought,
    });

    const contribution = await axios.post("contribution/", data, {
      // headers: headers,
    });
    return contribution;
  } catch (err) {
    return console.error(err);
  }
};

export const postContributionRequestApi = async (payload) => {
  try {
    // const token = JSON.parse(localStorage.getItem("currentUser")).token;
    // var headers = {
    //   Authorization: `Bearer ${token}`,
    // };
    const data = JSON.stringify({
      id: payload.id,
      dashboard_id: payload.dashboard_id,
      item_name: payload.item_name,
      request_by: payload.request_by,
    });

    const contribution = await axios.post("contribution/request/", data, {
      // headers: headers,
    });
    return contribution;
  } catch (err) {
    return console.error(err);
  }
};

export const getContributionApi = async (payload) => {
  try {
    // const token = JSON.parse(localStorage.getItem("currentUser")).token;
    // var headers = {
    //   Authorization: `Bearer ${token}`,
    // };
    const contribution = await axios.get(`contribution/${payload}`, {
      // headers: headers,
    });
    return contribution.data;
  } catch (err) {
    return console.error(err);
  }
};

export const getUserContributionApi = async (payload) => {
  try {
    // const token = JSON.parse(localStorage.getItem("currentUser")).token;
    // var headers = {
    //   Authorization: `Bearer ${token}`,
    // };
    const contribution = await axios.get(`contribution/${payload.dashboard_id}/${payload.user_id}`, {
      // headers: headers,
    });
    return contribution.data;
  } catch (err) {
    return console.error(err);
  }
};

export const deleteContributionApi = async (payload) => {
  try {
    // const token = JSON.parse(localStorage.getItem("currentUser")).token;
    // var headers = {
    //   Authorization: `Bearer ${token}`,
    // };
    const contribution = await axios.delete(`contribution/${payload}`, {
      // headers: headers,
    });
    return contribution.data;
  } catch (err) {
    return console.error(err);
  }
};
