import React from "react";
import "./closeWidgetButton.css";
import { useDispatch } from "react-redux";
import { TOGGLE_EDIT_WIDGET } from "../../../redux/action/dashboardAction";
import Close from "../../../assets/Close.png";

function CloseWidgetButton() {
  const dispatch = useDispatch();

  return (
    <img
      className="editWidgetCloseImg"
      src={Close}
      alt="Close"
      onClick={() => {
        dispatch({ type: TOGGLE_EDIT_WIDGET });
      }}
    />
  );
}

export default CloseWidgetButton;
