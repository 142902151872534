import defaultAxios from "axios";

export const getCoordinatesApi = async (location) => {
  const axios = defaultAxios.create({
    baseURL: "https://maps.googleapis.com/",
    headers: { "Content-Type": "application/json" },
  });

  try {
    const locationResult = await axios.post(`maps/api/geocode/json?address=${location}&key=${process.env.REACT_APP_GOOGLE_MAPS_API}`);
    const locationData = locationResult.data;
    return locationData;
  } catch (err) {
    return console.error(err);
  }
};