import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./profile.css";
// import { getAccountCreationLinkApi } from "../../../redux/api/paymentApi";
// import { getAuth } from "firebase/auth";
import AccountIcon from "../../../assets/AccountIcon.png";
import Edit from "../../../assets/Edit.png";
import { GET_MY_FUTURE_HOSTED_EVENTS, GET_MY_PAST_EVENTS, GET_MY_PAST_HOSTED_EVENTS } from "../../../redux/action/myEventsAction";
import HorizontalEventCard from "../../../components/cards/horizontalEventCard/horizontalEventCard";
import VerticalEventCard from "../../../components/cards/verticalEventCard/verticalEventCard";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import { getUserProfilePicture, uploadUserPictureToS3 } from "../../../utils/s3/s3Controller";
import ConnectionsList from "../../../components/connections/connectionsList/connectionsList";
import { GET_USER_CONNECTIONS } from "../../../redux/action/userAction";

function Profile() {
  const dispatch = useDispatch();
  const isDesktop = useMediaQuery("(min-width: 768px)");
  const currentUser = useSelector((state) => state.userReducer.currentUser);
  const myPastEvents = useSelector((state) => state.myEventsReducer.myPastEvents);
  const myPastHostedEvents = useSelector((state) => state.myEventsReducer.myPastHostedEvents);
  const myFutureHostedEvents = useSelector((state) => state.myEventsReducer.myFutureHostedEvents);
  const connections = useSelector((state) => state.userReducer.currentUserConnections);
  const [pastEvents, setPastEvents] = useState([]);
  const [userImage, setUserImage] = useState(AccountIcon);
  const [showConnections, setShowConnections] = useState(false);

  useEffect(() => {
    dispatch({ type: GET_USER_CONNECTIONS, payload: currentUser.user_id });
  }, [dispatch]);

  useEffect(() => {
    dispatch({ type: GET_MY_PAST_EVENTS, payload: currentUser.user_id ?? "" });
    dispatch({ type: GET_MY_PAST_HOSTED_EVENTS, payload: currentUser.user_id ?? "" });
    dispatch({ type: GET_MY_FUTURE_HOSTED_EVENTS, payload: currentUser.user_id ?? "" });

    getUserProfilePicture(currentUser.user_id).then((url) => {
      setUserImage(url ?? AccountIcon);
    });
  }, [dispatch, currentUser]);

  useEffect(() => {
    let combinedPastEvents = [...myPastEvents, ...myPastHostedEvents];
    combinedPastEvents.sort((a, b) => new Date(b.event_date) - new Date(a.event_date));
    combinedPastEvents = combinedPastEvents.filter((v, i, a) => a.findIndex((t) => t.event_id === v.event_id) === i);
    setPastEvents(combinedPastEvents);
  }, [myPastEvents, myPastHostedEvents]);

  const onChangePicture = (e) => {
    if (e.target.files[0]) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        uploadUserPictureToS3(currentUser.user_id, e.target.files[0]);
        setUserImage(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  return (
    <div className="centerPage">
      <div className="profilePage">
        <div className="currentProfile">
          <div className="profileTopHeader">
            <div className="profilePicture">
              <img
                className="profilePicture fitCover clickable"
                src={userImage}
                alt="User Profile Picture"
                onClick={(e) => {
                  e.stopPropagation();
                  document.querySelector("input[type='file']").click();
                }}
              />
              <div className="editPictureText">Edit Picture</div>
              <input type="file" hidden onChange={onChangePicture} />
            </div>
            <div className="profileInfo">
              <div className="usernameHolder">
                <div className="usernameText">
                  {currentUser.user_first_name} {currentUser.user_last_name}
                </div>
                <span
                  className="clickable"
                  onClick={() => {
                    setShowConnections(true);
                    document.body.style.overflow = "hidden";
                  }}
                >
                  <b>{connections.length}</b> Connections
                </span>
              </div>
              <div className="attendenceText">
                <div>Attended {myPastEvents.length}</div> <div>Hosted {myPastHostedEvents.length}</div>
              </div>
              {/* TODO: MOVE BELOW INTO PROFILE DROP DOWN */}
              {/* <div className="tempButtons">
                <div
                  className="SetupPaymentButton"
                  onClick={async () => {
                    const stripeLink = await getAccountCreationLinkApi(currentUser.user_id);
                    window.location.href = stripeLink;
                  }}
                >
                  Payout Account
                </div>

                <div
                  className="SetupPaymentButton"
                  onClick={() => {
                    getAuth().signOut();
                    window.location.href = "/";
                  }}
                >
                  Logout
                </div>
              </div> */}
            </div>
            <img className="editIcon" src={Edit} alt="EditIcon" />
          </div>
          <div className="hostingSection">
            <div className="profileTitleText">
              {currentUser.user_first_name} {myFutureHostedEvents.length > 0 ? "is hosting" : "has no events planned yet."}
            </div>
            <div className="currentHostHolder">
              {myFutureHostedEvents.map((event) => (
                <HorizontalEventCard key={event.event_id} event={event} />
              ))}
            </div>
          </div>
        </div>
        {isDesktop ? (
          <div className="pastEventsHolder">
            <div className="pastEventsHeader">
              <div className="pastEventsTitle">Past events</div>
            </div>
            <div className="pastHostHolder">
              {pastEvents.map((event) => (
                <VerticalEventCard key={event.event_id} event={event} />
              ))}
            </div>
          </div>
        ) : null}
      </div>
      {showConnections ? (
        <div
          className="popupBackground"
          onClick={(e) => {
            e.stopPropagation();
            setShowConnections(false);
            document.body.style.overflow = "auto";
          }}
        >
          <ConnectionsList />
        </div>
      ) : null}
    </div>
  );
}

export default Profile;
