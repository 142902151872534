export const GET_DASHBOARD = "GET_DASHBOARD";
export const POST_DASHBOARD = "POST_DASHBOARD";
export const DELETE_DASHBOARD = "DELETE_DASHBOARD";
export const POST_POLL = "POST_POLL";
export const VOTE_POLL = "VOTE_POLL";
export const VOTE_PROMPT = "VOTE_PROMPT";
export const DELETE_POLL = "DELETE_POLL";
export const REQUEST_CONTRIBUTION = "REQUEST_CONTRIBUTION";
export const POST_CONTRIBUTION = "POST_CONTRIBUTION";
export const GET_CONTRIBUTION = "GET_CONTRIBUTION";
export const DELETE_CONTRIBUTION = "DELETE_CONTRIBUTION";
export const UPDATE_DASHBOARD_INDEX = "UPDATE_DASHBOARD_INDEX";

export const SET_DASHBOARD = "SET_DASHBOARD";
export const TOGGLE_EDIT_MODE = "TOGGLE_EDIT_MODE";
export const TOGGLE_EDIT_WIDGET = "TOGGLE_EDIT_WIDGET";
export const SET_EDIT_WIDGET_STEP = "SET_EDIT_WIDGET_STEP";
export const SET_EDIT_WIDGET_CONTENT = "SET_EDIT_WIDGET_CONTENT";