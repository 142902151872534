import React from "react";
import "./eventTileOuter.css";
import { S3Bucket } from "../../Constants";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import CardFundingBar from "../fundingBar/cardFundingBar/cardFundingBar";

function EventTileOuter({ event, origin }) {
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.userReducer.currentUser);
  const managementPage = window.location.pathname === "/myEvents" && event.event_host === currentUser.user_id;
  return (
    <div
      className="EventTileOuter"
      onClick={() => {
        if (managementPage) {
          if (origin === "MyTickets") {
            navigate(`/myTicket/${event.event_id}`);
          } else {
            navigate(`/manageEvent/${event.event_id}`);
          }
        } else {
          navigate(`/details/${event.event_id}`);
        }
      }}
    >
      <div
        className="EventContentOuter"
        style={{
          backgroundImage: `url('${event.event_picture.includes("http") ? event.event_picture : `${S3Bucket}event/${event.event_id}/${event.event_id}.jpg`}')`,
        }}
      >
        <CardFundingBar event={event} />
      </div>
      <div className="EventTileOuterInfo">
        <div className="EventTileOuterInfoTitleBlack">{event.event_name}</div>
        {!managementPage ? (
          <div className="EventTileOuterInfoHost">
            • {event.user_first_name} {event.user_last_name}
          </div>
        ) : null}
      </div>
      <div className="EventTileOuterInfoLocation">
        <div className="EventTileOuterInfoHost">{event.event_location}</div>
      </div>
    </div>
  );
}

export default EventTileOuter;
