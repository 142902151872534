import defaultAxios from "axios";
import { backend } from "../../Constants";

const axios = defaultAxios.create({
  baseURL: backend,
  headers: { "Content-Type": "application/json" },
});

export const getMyPastEventsApi = async (payload) => {
  try {
    const token = JSON.parse(localStorage.getItem("currentUser"))?.token ?? "";
    var headers = {
      Authorization: `${token}`,
    };
    if (payload) {
      const userId = payload;
      const events = await axios.get(`event/${userId}/getMyPastEvents`, {
        headers: headers,
      });
      return events;
    }
  } catch (err) {
    return console.error(err);
  }
};

export const getMyFutureEventsApi = async (payload) => {
  try {
    const token = JSON.parse(localStorage.getItem("currentUser"))?.token ?? "";
    var headers = {
      Authorization: `${token}`,
    };
    if (payload) {
      const userId = payload;
      const events = await axios.get(`event/${userId}/getMyFutureEvents`, {
        headers: headers,
      });
      return events;
    }
  } catch (err) {
    return console.error(err);
  }
};

export const getMyPastHostedEventsApi = async (payload) => {
  try {
    const token = JSON.parse(localStorage.getItem("currentUser"))?.token ?? "";
    var headers = {
      Authorization: `${token}`,
    };
    if (payload) {
      const userId = payload;
      const events = await axios.get(`event/${userId}/getMyPastHostedEvents`, {
        headers: headers,
      });
      return events;
    }
  } catch (err) {
    return console.error(err);
  }
};

export const getMyFutureHostedEventsApi = async (payload) => {
  try {
    const token = JSON.parse(localStorage.getItem("currentUser"))?.token ?? "";
    var headers = {
      Authorization: `${token}`,
    };
    if (payload) {
      const userId = payload;
      const events = await axios.get(`event/${userId}/getMyFutureHostedEvents`, {
        headers: headers,
      });
      return events;
    }
  } catch (err) {
    return console.error(err);
  }
};

export const getCurrentEventTicketApi = async (payload) => {
  try {
    const token = JSON.parse(localStorage.getItem("currentUser"))?.token ?? "";
    var headers = {
      Authorization: `${token}`,
    };
    if (payload.event_id && payload.user_id) {
      const ticket = await axios.get(`transaction/user/${payload.event_id}/${payload.user_id}`, {
        headers: headers,
      });
      return ticket;
    }
  } catch (err) {
    return console.error(err);
  }
};

export const removeEventRSVPApi = async (payload) => {
  try {
    const token = JSON.parse(localStorage.getItem("currentUser"))?.token ?? "";
    var headers = {
      Authorization: `${token}`,
    };
    if (payload) {
      const response = await axios.delete(`transaction/${payload.event_id}/${payload.transaction_id}`, {
        headers: headers,
      });
      return response;
    }
  } catch (err) {
    return console.error(err);
  }
}