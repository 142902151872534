import AWS from "aws-sdk";

export async function getSonderSays() {
  const awsConfig = new AWS.Config({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    region: "us-west-2",
  });

  AWS.config.update(awsConfig);
  const dynamoDb = new AWS.DynamoDB.DocumentClient();

  const params = {
    TableName: "SonderSaysTable",
  };

  return dynamoDb
    .scan(params)
    .promise()
    .then((data) => {
      return data.Items;
    })
    .catch((err) => {
      console.error(err);
      return [];
    });
}
