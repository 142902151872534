import React, { useEffect, useState } from "react";
import "./guestlistWidget.css";
import "../widgetStyling.css";
import { useDispatch, useSelector } from "react-redux";
import { SET_EDIT_WIDGET_CONTENT, SET_EDIT_WIDGET_STEP, TOGGLE_EDIT_WIDGET } from "../../../redux/action/dashboardAction";
import { GET_EVENT_GUESTLIST } from "../../../redux/action/eventAction";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import ProfilePicture from "../../user/profilePicture/profilePicture";

function GuestlistWidget({ live, data }) {
  const dispatch = useDispatch();
  const isDesktop = useMediaQuery("(min-width: 768px)");
  const currentEvent = useSelector((state) => state.eventReducer.currentEvent);
  const showEditWidget = useSelector((state) => state.dashboardReducer.showEditWidget);
  const eventGuestlist = useSelector((state) => state.eventReducer.eventGuestlist);
  const [ghostFriends, setGhostFriends] = useState([]);
  const [expandGuestlist, setExpandGuestlist] = useState(false);
  let guestList = eventGuestlist.slice(0, 4);
  if (!isDesktop) {
    guestList = eventGuestlist.slice(0, 2);
  }

  useEffect(() => {
    if (currentEvent && currentEvent.event_id) {
      dispatch({ type: GET_EVENT_GUESTLIST, payload: currentEvent.event_id });
    }
  }, [currentEvent]);

  useEffect(() => {
    if (isDesktop) {
      if (eventGuestlist.length < 4) {
        setGhostFriends(new Array(4 - eventGuestlist.length).fill(0));
      } else {
        setGhostFriends([]);
      }
    } else {
      if (eventGuestlist.length < 2) {
        setGhostFriends(new Array(2 - eventGuestlist.length).fill(0));
      } else {
        setGhostFriends([]);
      }
    }
  }, [eventGuestlist]);

  return (
    <div
      className={`widgetView ${!live && !showEditWidget ? "clickable" : ""}`}
      onClick={() => {
        if (!live && !showEditWidget) {
          dispatch({ type: TOGGLE_EDIT_WIDGET });
          dispatch({
            type: SET_EDIT_WIDGET_CONTENT,
            payload: {
              type: "guestlist",
              size: data.component_size,
              id: data.dashboard_id,
              index: data.component_index,
            },
          });
          dispatch({ type: SET_EDIT_WIDGET_STEP, payload: "guestlist" });
        }
      }}
    >
      <div className="widgetTitle">Guestlist</div>
      <div className="guestListContent">
        {data.component_size === "small" ? (
          <div className="guestListPreview">
            {guestList.map((guest, index) => {
              if (index % 2 === 0) {
                return (
                  <div className="guestlistDown" key={index}>
                    <ProfilePicture className={"guestlistPhoto"} userId={guest.user_id} />
                    <div className="guestlistText">{guest.user_first_name}</div>
                  </div>
                );
              } else {
                return (
                  <div className="guestlistUp" key={index}>
                    <ProfilePicture className={"guestlistPhoto"} userId={guest.user_id} />
                    <div className="guestlistText">{guest.user_first_name}</div>
                  </div>
                );
              }
            })}
            {ghostFriends.map((_, index) => {
              if ((index + eventGuestlist.length) % 2 === 0) {
                return (
                  <div className="guestlistDown" key={index}>
                    <ProfilePicture className={"guestlistPhoto"} userId={null} />
                    <div className="guestlistText">New Friend #{index + 1}</div>
                  </div>
                );
              } else {
                return (
                  <div className="guestlistUp" key={index}>
                    <ProfilePicture className={"guestlistPhoto"} userId={null} />
                    <div className="guestlistText">New Friend #{index + 1}</div>
                  </div>
                );
              }
            })}
          </div>
        ) : (
          <div className="guestListPreview">
            {eventGuestlist.slice(0, 1).map((guest, index) => {
              return (
                <div key={index}>
                  <ProfilePicture className={"guestlistPhoto"} userId={guest.user_id} />
                  <div className="guestlistText">{guest.user_first_name}</div>
                </div>
              );
            })}
          </div>
        )}
        <div className="goingSection">
          {eventGuestlist.length > 4 && (
            <div
              className="clickable"
              onClick={() => {
                setExpandGuestlist(true);
              }}
            >
              <div className="goingBigText">+{eventGuestlist.length - 4}</div>
              <div className="guestlistText">{`${eventGuestlist.length - 4 > 1 ? "others" : "other"} going`}</div>
            </div>
          )}
          {eventGuestlist.length <= 4 && (
            <div>
              <div className="goingBigText">{eventGuestlist.length}</div>
              <div className="guestlistText">going</div>
            </div>
          )}
        </div>
      </div>
      {expandGuestlist ? (
        <div
          className="guestlistExpandView"
          onClick={(e) => {
            e.stopPropagation();
            setExpandGuestlist(false);
          }}
        >
          <div className="guestlistExpand invisiScroll">
            <div className="guestlistRows">
              {eventGuestlist.map((guest, index) => {
                return (
                  <div key={index}>
                    <ProfilePicture className={"guestlistPhoto"} userId={guest.user_id} />
                    <div className="guestlistText">{guest.user_first_name}</div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default GuestlistWidget;
