import React from "react";
import { useSelector } from "react-redux";
import ProfilePicture from "../../user/profilePicture/profilePicture";
import Close from "../../../assets/Close.png";
import CloseWhite from "../../../assets/CloseWhite.png";

export function BringingTag({ name, userId }) {
  return (
    <div className="bringingTag">
      <div className="bringingTagText">{name}</div>
      <ProfilePicture userId={userId} />
    </div>
  );
}

export function RequestItemTag({ clickAction }) {
  return (
    <div
      className="requestTag clickable"
      onClick={(e) => {
        e.stopPropagation();
        clickAction();
      }}
    >
      Add to the List
    </div>
  );
}

export function IBringingTag({ name, clickAction }) {
  return (
    <div className="bringingTag">
      <div className="bringingTagText">{name}</div>
      <img
        className="deleteBringingIcon clickable"
        src={CloseWhite}
        alt="AccountIcon"
        onClick={(e) => {
          e.stopPropagation();
          clickAction();
        }}
      />
    </div>
  );
}

export function RequestedTag({ contribution, clickAction, deleteAction }) {
  const currentUser = useSelector((state) => state.userReducer.currentUser);
  const currentEvent = useSelector((state) => state.eventReducer.currentEvent);
  return (
    <div
      className="requestItemTag clickable"
      onClick={(e) => {
        e.stopPropagation();
        clickAction();
      }}
    >
      <div>{contribution.item_name}</div>
      {(contribution.requested_by === currentUser.user_id || currentEvent.event_host === currentUser.user_id) && deleteAction && (
        <img
          className="deleteBringingIcon clickable"
          src={Close}
          alt="AccountIcon"
          onClick={(e) => {
            e.stopPropagation();
            deleteAction();
          }}
        />
      )}
    </div>
  );
}
