import React from "react";
import "./whiteTier.css";
import { useDispatch, useSelector } from "react-redux";
import PlusBlack from "../../../../assets/PlusBlack.png";
import SubtractBlack from "../../../../assets/SubtractBlack.png";
import { SET_CART } from "../../../../redux/action/cartAction";
import { convertLineBreaks } from "../../../../utils/textMarkdown";

function WhiteTier({ item, first, last }) {
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cartReducer.currentCart);
  return (
    <div
      key={item.event_tier_id}
      className="tierLight"
      style={{
        borderTopLeftRadius: first ? "50px" : "5px",
        borderBottomLeftRadius: first ? "50px" : "5px",
        borderTopRightRadius: last ? "50px" : "5px",
        borderBottomRightRadius: last ? "50px" : "5px",
      }}
    >
      <div className="priceTierInfoSection">
        <div className="priceTierItemTitleLight">{item.event_tier_name}</div>
        <div className="priceTierCostLight">${item.event_tier_price}</div>
        <div className="tierDividerLight" />
        <div className="priceTierPerksLight">
          {convertLineBreaks(item.event_tier_description, true)}
        </div>
      </div>
      <div className="priceTierToggle">
        {cart.cartContent[item.event_tier_id] !== undefined &&
          cart.cartContent[item.event_tier_id]?.quantity > 0 && (
          <img
            src={SubtractBlack}
            className="toggleIcons"
            alt="Minus"
            onClick={() => {
              const newQuantity = (cart.cartContent[item.event_tier_id]?.quantity ?? 0) - 1;
              dispatch({
                type: SET_CART,
                payload: {
                  ...cart,
                  cartContent: {
                    ...cart.cartContent,
                    [item.event_tier_id]: {
                      quantity: newQuantity,
                      total: newQuantity * item.event_tier_price,
                    }
                  },
                },
                stage: "price",
              });
            }}
          />
        )}
        {cart.cartContent[item.event_tier_id] !== undefined &&
          cart.cartContent[item.event_tier_id]?.quantity > 0 && (
          <div className="toggleTextLight">
            {cart.cartContent[item.event_tier_id]?.quantity ?? 0}
          </div>
        )}
        <img
          src={PlusBlack}
          className="toggleIcons"
          alt="Plus"
          onClick={() => {
            const newQuantity = (cart.cartContent[item.event_tier_id]?.quantity ?? 0) + 1;
            dispatch({
              type: SET_CART,
              payload: {
                ...cart,
                cartContent: {
                  ...cart.cartContent,
                  [item.event_tier_id]: {
                    quantity: newQuantity,
                    total: newQuantity * item.event_tier_price,
                  }
                },
                stage: "price",
              },
            });
          }}
        />
      </div>
    </div>
  );
}

export default WhiteTier;
