import React from "react";
import "./connectionsList.css";
import { useSelector } from "react-redux";
import ProfilePicture from "../../user/profilePicture/profilePicture";

function ConnectionsList() {
  const connections = useSelector((state) => state.userReducer.currentUserConnections);

  return (
    <div
      className="connectionsList"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <h3>{`You've connected with ${connections.length} people!`}</h3>
      {connections.map((connection, index) => {
        return (
          <div key={index} className="connection">
            <div className="connectionUser">
              <ProfilePicture userId={connection.user_id} hideInitials={false} />
              <div className="connectionName">
                {connection.user_first_name} {connection.user_last_name}
              </div>
            </div>
            {connection.lastEvent && (
              <div className="connectionLastSeen">
                Last seen on{" "}
                {new Date(connection.lastEvent.event_start_time).toLocaleDateString("en-US", {
                  month: "long",
                  day: "numeric",
                  year: "numeric",
                })}{" "}
                at {connection.lastEvent.event_name}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}

export default ConnectionsList;
