import React, { useRef, useState } from "react";
import { BringingTag, RequestedTag } from "./bringingWidgetTags";
import { DELETE_CONTRIBUTION, POST_CONTRIBUTION, REQUEST_CONTRIBUTION } from "../../../redux/action/dashboardAction";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import Close from "../../../assets/Close.png";
import { useMediaQuery } from "../../../hooks/useMediaQuery";

export function ExpandAll({ bringingList, actionRefresh }) {
  return (
    <div className="bringExpand">
      <div className="rowFlexSimple space-between">
        <div className="widgetTitle">All Items Brought</div>
        <img
          className="editWidgetCloseImg"
          src={Close}
          alt="Close"
          onClick={() => {
            actionRefresh(true);
          }}
        />
      </div>
      <div className="expandContent">
        <div className="currentlyBringing">
          {bringingList.map((contribution) => (
            <BringingTag key={contribution.contribution_id} name={contribution.item_name} userId={contribution.brought_by} />
          ))}
        </div>
      </div>
    </div>
  );
}

export function ExpandBring({ widgetData, requestedList, actionRefresh }) {
  const dispatch = useDispatch();
  const isDesktop = useMediaQuery("(min-width: 768px)");
  const currentUser = useSelector((state) => state.userReducer.currentUser);
  const [bringItem, setBringItem] = useState("");
  const bringingTextBox = useRef(null);

  return (
    <div
      className="bringExpand"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div className="rowFlexSimple space-between">
        <div className="widgetTitle">Add to the List</div>
        <img
          className="editWidgetCloseImg"
          src={Close}
          alt="Close"
          onClick={() => {
            actionRefresh(true);
          }}
        />
      </div>
      <div className="expandBringContent">
        {requestedList?.length > 0 ? (
          <div className="bringContent">
            <div className="semibold gray80">Select a requested item</div>
            <div className="currentlyRequested">
              {requestedList.map((contribution) => (
                <RequestedTag
                  key={contribution.contribution_id}
                  contribution={contribution}
                  clickAction={() => {
                    dispatch({
                      type: POST_CONTRIBUTION,
                      payload: {
                        ...contribution,
                        id: contribution.contribution_id,
                        brought_by: currentUser.user_id,
                        brought: true,
                      },
                    });
                    actionRefresh(false);
                  }}
                  deleteAction={() => {
                    dispatch({
                      type: DELETE_CONTRIBUTION,
                      payload: contribution.contribution_id,
                    });
                    actionRefresh(false);
                  }}
                />
              ))}
            </div>
          </div>
        ) : null}
        {isDesktop && requestedList?.length > 0 ? (
          <div className="verticalDividerSection">
            <div className="verticalDividerHalf" />
            <div className="gray78">Or</div>
            <div className="verticalDividerHalf" />
          </div>
        ) : null}
        <div className={`bringContent ${requestedList?.length > 0 ? "" : "width100"}`}>
          <div className="semibold gray80">{requestedList?.length > 0 ? "Bring something else" : "Bring something"}</div>
          <input
            ref={bringingTextBox}
            className="inputBox requestInputBox"
            placeholder="e.g Monopoly"
            onChange={(e) => {
              setBringItem(e.target.value);
            }}
          ></input>
          <div className="rowFlex justify-flex-end">
            <div
              className="requestItemButton"
              onClick={() => {
                if (bringItem === "") {
                  alert("Please enter an item name.");
                  return;
                }
                dispatch({
                  type: REQUEST_CONTRIBUTION,
                  payload: { id: uuidv4(), dashboard_id: widgetData.dashboard_id, item_name: bringItem, request_by: currentUser.user_id },
                });
                bringingTextBox.current.value = "";
                actionRefresh(false);
              }}
            >
              Request Item
            </div>
            <div
              className="addItemButton"
              onClick={() => {
                if (bringItem === "") {
                  alert("Please enter an item name.");
                  return;
                }
                dispatch({
                  type: POST_CONTRIBUTION,
                  payload: {
                    id: uuidv4(),
                    dashboard_id: widgetData.dashboard_id,
                    item_name: bringItem,
                    requested_by: currentUser.user_id,
                    brought_by: currentUser.user_id,
                    brought: true,
                  },
                });
                bringingTextBox.current.value = "";
                actionRefresh(true);
              }}
            >
              Bring Item
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
