import React, { useState } from "react";
import "./searchBar.css";
import SearchIcon from "../../assets/Search.png";
import { useNavigate } from "react-router-dom";

function SearchBar() {
  const navigate = useNavigate();
  const [searchInput, setSearchInput] = useState("");
  return (
    <div className='SearchBar' onKeyDown={(e) => {
      if (e.key === "Enter") {
        navigate(`/search/${searchInput}`);
      }
    }}>
      <img className='SearchIcon' src={SearchIcon} alt="SearchIcon" />
      <input className='SearchInput' placeholder='Search for events...' value={searchInput} onChange={(e) => {
        setSearchInput(e.target.value);
      }} />
    </div>
  )
}

export default SearchBar;