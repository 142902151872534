import defaultAxios from "axios";
import { backend } from "../../Constants";

const axios = defaultAxios.create({
  baseURL: backend,
  headers: { "Content-Type": "application/json" },
});

export const postEmailConfirmationApi = async (payload) => {
  try {
    const token = JSON.parse(localStorage.getItem("currentUser"))?.token ?? "";
    var headers = {
      Authorization: `${token}`,
    };
    var data = JSON.stringify(payload);
    const eventEmail = await axios.post(`email/transaction/${payload.eventId}`, data, {
      headers: headers,
    });
    return eventEmail;
  } catch (err) {
    return console.error(err);
  }
};

export const postPayoutApi = async (payload) => {
  try {
    const token = JSON.parse(localStorage.getItem("currentUser"))?.token ?? "";
    var headers = {
      Authorization: `${token}`,
    };
    var data = JSON.stringify(payload);
    const payout = await axios.post(`user/payout/${payload.eventId}`, data, {
      headers: headers,
    });
    return payout;
  } catch (err) {
    return console.error(err);
  }
}