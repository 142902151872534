import defaultAxios from "axios";
import { backend } from "../../Constants";

const axios = defaultAxios.create({
  baseURL: backend,
  headers: { "Content-Type": "application/json" },
});

export const getDashboardApi = async (payload) => {
  try {
    // const token = JSON.parse(localStorage.getItem("currentUser")).token;
    // var headers = {
    //   Authorization: `Bearer ${token}`,
    // };
    const dashboard = await axios.get(`dashboard/${payload}`, {
      // headers: headers,
    });
    return dashboard.data;
  } catch (err) {
    return console.error(err);
  }
};

export const postDashboardApi = async (payload) => {
  try {
    // const token = JSON.parse(localStorage.getItem("currentUser")).token;
    // var headers = {
    //   Authorization: `Bearer ${token}`,
    // };

    var data = JSON.stringify({
      id: payload.id,
      event_id: payload.event_id,
      title: payload.title,
      type: payload.type,
      size: payload.size,
      index: payload.index,
    });

    const dashboard = await axios.post("dashboard/", data, {
      // headers: headers,
    });
    return dashboard;
  } catch (err) {
    return console.error(err);
  }
};

export const deleteDashboardApi = async (payload) => {
  try {
    // const token = JSON.parse(localStorage.getItem("currentUser")).token;
    // var headers = {
    //   Authorization: `Bearer ${token}`,
    // };
    const dashboard = await axios.delete(`dashboard/${payload}`, {
      // headers: headers,
    });
    return dashboard;
  } catch (err) {
    return console.error(err);
  }
};

export const updateDashboardIndexApi = async (payload) => {
  try {
    // const token = JSON.parse(localStorage.getItem("currentUser")).token;
    // var headers = {
    //   Authorization: `Bearer ${token}`,
    // };

    const dashboard = await axios.put(`dashboard/index/${payload.dashboard_id}/${payload.index}`, {
      // headers: headers,
    });
    return dashboard;
  } catch (err) {
    return console.error(err);
  }
};
