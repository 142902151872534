import { combineReducers } from "redux";

import cartReducer from "./cartReducer";
import createReducer from "./createReducer";
import dashboardReducer from "./dashboardReducer";
import eventReducer from "./eventReducer";
import landingReducer from "./landingReducer";
import myEventsReducer from "./myEventsReducer";
import userReducer from "./userReducer";

const rootReducer = combineReducers({
  cartReducer,
  createReducer,
  dashboardReducer,
  eventReducer,
  landingReducer,
  myEventsReducer,
  userReducer,
});

export default rootReducer;
