import React from "react";
import "./checkoutButton.css";
import { useDispatch, useSelector } from "react-redux";
import { SET_CART } from "../../../../redux/action/cartAction";

function CheckoutButton() {
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cartReducer.currentCart);

  if (cart.stage === "payment") {
    return null;
  }

  return (
    <div className="CheckoutButton" onClick={() => {
      dispatch({
        type: SET_CART,
        payload: {
          ...cart,
          stage: "payment",
        },
      });
    }}>
      <div className="CheckoutText">Continue</div>
    </div>
  );
}

export default CheckoutButton;
