import React, { useEffect, useState } from "react";
import "./eventWhoCreate.css";
import CreationNavBar from "../creationNavBar/creationNavBar";
import { useDispatch, useSelector } from "react-redux";
import { SET_CREATION_STEP } from "../../../redux/action/createAction";
import PlusBlack from "../../../assets/PlusBlack.png";
import XBlack from "../../../assets/XBlack.png";
import { v4 as uuidv4 } from "uuid";

function EventWhoCreate() {
  const dispatch = useDispatch();
  const creation = useSelector((state) => state.createReducer.currentCreation);
  const [tierSelected, setTierSelected] = useState();

  useEffect(() => {
    if (tierSelected) return;
    if (!creation.event_tiers.length) {
      const newTierId = uuidv4();
      dispatch({
        type: SET_CREATION_STEP,
        payload: {
          ...creation,
          event_tiers: [
            {
              id: newTierId,
              name: "Tier 1",
              price: "0.00",
              description: "",
              spots: 100,
            },
          ],
        },
      });
      setTierSelected(newTierId);
    } else {
      setTierSelected(creation.event_tiers[0].id);
    }
  }, [dispatch, creation]);

  return (
    <>
      <div className="eventCreationLeft invisiScroll">
        <div>
          <div className="eventCreationTitle">{"Let's talk money"}</div>
          <div className="eventCreationSubTitle">
            {creation.event_type === "crowdfund"
              ? "Set a funding goal for your event and decide on your ticket prices."
              : "Decide on the ticket prices for your event."}
          </div>
          <div className="eventCreationOptionsHolder">
            {/* Funding Goal */}
            {creation.event_type === "crowdfund" && (
              <div className="eventCreationOption">
                <div className="eventWhatQuestionIcon">1</div>
                <div className="eventCreationEntry">
                  <div className="eventCreationOptionTitle">Decide on a funding goal</div>
                  <div className="eventCreationOptionSubTitle">Provide the amount you need for your event to go ahead.</div>
                  <div className="fundingGoalInputs">
                    <input className="eventCreationInput fundingCurrency" type="text" value="CAD$" contentEditable="false" readOnly />
                    <input
                      className="eventCreationInput"
                      type="number"
                      placeholder="0.00"
                      value={creation.event_fund_target || ""}
                      onChange={(e) => {
                        dispatch({
                          type: SET_CREATION_STEP,
                          payload: {
                            ...creation,
                            event_fund_target: e.target.value,
                          },
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
            {/* Ticket Pricing */}
            <div className="eventCreationOption">
              <div className="eventWhatQuestionIcon">{creation.event_type === "crowdfund" ? 2 : 1}</div>
              <div className="eventCreationEntry">
                <div className="eventCreationOptionTitle">Decide on your ticket pricing</div>
                <div className="eventCreationOptionSubTitle">Create one or more ticket tiers to be available.</div>
                <div className="eventCreationTicketOptions">
                  {creation.event_tiers.map((tier) => {
                    return (
                      <div
                        key={tier.id}
                        className={`tierOption ${tierSelected === tier.id ? "tierSelected" : ""}`}
                        onClick={() => {
                          setTierSelected(tier.id);
                        }}
                      >
                        <div className="tierOptionTitle">{tier.name}</div>
                        <div className="tierOptionSubtitle">CAD${tier.price}</div>
                      </div>
                    );
                  })}
                  {creation.event_tiers.length < 6 ? (
                    <div
                      className="addTierButton"
                      onClick={() => {
                        dispatch({
                          type: SET_CREATION_STEP,
                          payload: {
                            ...creation,
                            event_tiers: [
                              ...creation.event_tiers,
                              {
                                id: uuidv4(),
                                name: "Tier " + (creation.event_tiers.length + 1),
                                price: "0.00",
                                description: "",
                                spots: 100,
                              },
                            ],
                          },
                        });
                      }}
                    >
                      <img src={PlusBlack} className="addTierImg" alt="Plus" />
                      <div>Add tier</div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        <CreationNavBar />
      </div>
      <div className="eventCreationRight">
        <div className="tierBoxPreview">
          <div className="tierBox">
            <div className="tierDeletionHeader">
              <div className="tierName">&nbsp;{`${creation.event_tiers.filter((tier) => tier.id === tierSelected)[0]?.name}`}&nbsp;</div>
              {creation.event_tiers.length > 1 && (
                <img
                  src={XBlack}
                  className="addTierImg clickable"
                  alt="Plus"
                  onClick={() => {
                    if (creation.event_tiers.length === 1) return;
                    const filteredList = creation.event_tiers.filter((tier) => tier.id !== tierSelected);
                    dispatch({
                      type: SET_CREATION_STEP,
                      payload: {
                        ...creation,
                        event_tiers: filteredList,
                      },
                    });
                    setTierSelected(filteredList[0]?.id);
                  }}
                />
              )}
            </div>
            <div className="tierEditSection">
              <div className="tierEditPrice">
                <div className="tierEditPriceInner">
                  <input
                    className="tierEditPriceInput"
                    placeholder="CAD$ 0.00"
                    value={
                      creation.event_tiers.filter((tier) => tier.id === tierSelected)[0]?.price &&
                      creation.event_tiers.filter((tier) => tier.id === tierSelected)[0]?.price !== "0.00"
                        ? `CAD$ ${creation.event_tiers.filter((tier) => tier.id === tierSelected)[0]?.price}`
                        : ""
                    }
                    onChange={(e) => {
                      dispatch({
                        type: SET_CREATION_STEP,
                        payload: {
                          ...creation,
                          event_tiers: creation.event_tiers.map((tier) => {
                            if (tier.id === tierSelected) {
                              let cost = e.target.value;
                              cost = cost.replace(/[^0-9.]/g, "");
                              return {
                                ...tier,
                                price: cost,
                              };
                            }
                            return tier;
                          }),
                        },
                      });
                    }}
                  />
                </div>
              </div>
              <div className="tierEditInfo">
                <input
                  className="tierTitleInput"
                  placeholder="Tier Title"
                  value={creation.event_tiers.filter((tier) => tier.id === tierSelected)[0]?.name || ""}
                  onBlur={() => {
                    if (creation.event_tiers.filter((tier) => tier.id === tierSelected)[0]?.name === "") {
                      dispatch({
                        type: SET_CREATION_STEP,
                        payload: {
                          ...creation,
                          event_tiers: creation.event_tiers.map((tier) => {
                            if (tier.id === tierSelected) {
                              return {
                                ...tier,
                                name: "Tier " + (creation.event_tiers.findIndex((tier) => tier.id === tierSelected) + 1),
                              };
                            }
                            return tier;
                          }),
                        },
                      });
                    }
                  }}
                  onChange={(e) => {
                    dispatch({
                      type: SET_CREATION_STEP,
                      payload: {
                        ...creation,
                        event_tiers: creation.event_tiers.map((tier) => {
                          if (tier.id === tierSelected) {
                            return {
                              ...tier,
                              name: e.target.value,
                            };
                          }
                          return tier;
                        }),
                      },
                    });
                  }}
                />
                <textarea
                  className="tierDescInput"
                  placeholder="Tier Benefits"
                  value={creation.event_tiers.filter((tier) => tier.id === tierSelected)[0]?.description || ""}
                  onChange={(e) => {
                    dispatch({
                      type: SET_CREATION_STEP,
                      payload: {
                        ...creation,
                        event_tiers: creation.event_tiers.map((tier) => {
                          if (tier.id === tierSelected) {
                            const inputValue = e.target.value;
                            const lines = inputValue.split("\n");

                            const bulletedLines = lines.map((line) => {
                              if (line.trim() !== "" && !/^\s*[\u2022\u25E6]/.test(line)) {
                                return "• " + line;
                              } else {
                                return line;
                              }
                            });

                            let modifiedBenefits = bulletedLines.join("\n");

                            return {
                              ...tier,
                              description: modifiedBenefits,
                            };
                          }
                          return tier;
                        }),
                      },
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EventWhoCreate;
