import React, { useEffect, useState } from "react";
import "./createEventConfirmation.css";
import CreationNavBar from "../../../components/eventCreate/creationNavBar/creationNavBar";
import { useDispatch, useSelector } from "react-redux";
import { SET_CREATION_STEP } from "../../../redux/action/createAction";
import CheckedSquare from "../../../assets/CheckedSquare.png";
import UncheckedSquare from "../../../assets/UncheckedSquare.png";
import { S3Bucket } from "../../../Constants";
import EventDetailTagSection from "../../../components/tags/eventDetailTagSection/eventDetailTagSection";

function CreateEventConfirmation() {
  const dispatch = useDispatch();
  const creation = useSelector((state) => state.createReducer.currentCreation);
  const [confirmationAgree, setConfirmationAgree] = useState(false);
  const [imgData, setImgData] = useState(null);

  useEffect(() => {
    if (creation.event_picture_cache) {
      setImgData(creation.event_picture_cache);
    } else if (creation.event_picture) {
      setImgData(`${S3Bucket}event/${creation.event_id}/${creation.event_id}.jpg`);
    }
  }, []);

  return (
    <div className="eventCreationCentered invisiScroll">
      <div className="eventCreationTitle">One final thing</div>
      <div className="confirmationSubtext">Double check and confirm your event details.</div>
      <div className="confirmationSection">
        <div className="confirmationImageSection">
          <img src={imgData ? imgData : "https://via.placeholder.com/150"} className="confirmationImage" alt="Event" />
        </div>
        <div className="confirmationInfoSection">
          <div className="confirmationTitle">{creation.event_name}</div>
          <div className="confirmationTldr">{creation.event_tldr}</div>
          <EventDetailTagSection event={creation} />
          <div className="confirmationTiers">
            {creation.event_tiers.map((tier) => {
              return (
                <div key={tier.id} className="tierOption">
                  <div className="tierOptionTitle">{tier.name}</div>
                  <div className="tierOptionSubtitle">CA${tier.price}</div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="noConfirmationSelection">
        <img
          src={confirmationAgree ? CheckedSquare : UncheckedSquare}
          className="noConfirmationIcon"
          alt="Check"
          onClick={() => {
            setConfirmationAgree(!confirmationAgree);
            dispatch({
              type: SET_CREATION_STEP,
              payload: {
                ...creation,
                terms_agreed: !confirmationAgree,
              },
            });
          }}
        />
        <div className="confirmationSubtext">I agree to the Terms of Service.</div>
      </div>
      <CreationNavBar />
    </div>
  );
}

export default CreateEventConfirmation;
