import React, { useEffect, useState } from "react";
import "./checkout.css";
import { useDispatch, useSelector } from "react-redux";
import CheckoutButton from "./checkoutButton/checkoutButton";
import CheckoutEntry from "./checkoutEntry/checkoutEntry";
import DownArrow from "../../../assets/DownArrow.png";
import { SET_CART } from "../../../redux/action/cartAction";

function TierEntry({ entry }) {
  const currentEventTiers = useSelector((state) => state.eventReducer.currentEventTiers);
  const [cartOption, setCartOption] = useState("");

  useEffect(() => {
    const option = currentEventTiers.filter((tier) => tier.event_tier_id === entry[0])[0];
    setCartOption(option.event_tier_name);
  }, [currentEventTiers]);

  return (
    <div className="tierEntry">
      <div className="tierTitle">{cartOption}</div>
      <div className="tierQuantity">x{entry[1].quantity ?? 0}</div>
    </div>
  );
}

function Checkout() {
  const TAX = 0.0; // 0% tax for now

  const dispatch = useDispatch();
  const event = useSelector((state) => state.eventReducer.currentEvent);
  const currentEventTiers = useSelector((state) => state.eventReducer.currentEventTiers);
  const cart = useSelector((state) => state.cartReducer.currentCart);
  const [subtotal, setSubtotal] = useState(0);
  const [tax, setTax] = useState(0);

  useEffect(() => {
    setSubtotal(0);
    setTax(0);
    Object.entries(cart.cartContent).forEach((item) => {
      setSubtotal((prev) => prev + item[1].total);
      setTax((prev) => prev + item[1].total * TAX);
    });
  }, [dispatch, event, currentEventTiers, cart]);


  return (
    <div className="transactionCheckout">
      <div className="cartSection">
        <div className="transactionTitle">Your Order</div>
        <div className="transactionInstructionText">For</div>
        <div className="transactionEventTitle">{event.event_name}</div>
        <div className="transactionInstructionText">
          Hosted by <span style={{ color: "#000000" }}>Harin</span>
        </div>
        <div className="tierEntrySection">
          {Object.entries(cart.cartContent).map((item) => {
            if (item[1].quantity > 0) {
              return <TierEntry key={item[0]} entry={item} />;
            }
          })}
        </div>
      </div>
      <div className="priceSection"
        style={{
          height: cart.stage === "payment" ? 600 : 300,
          position: cart.stage === "payment" ? "absolute" : "relative",
          bottom: cart.stage === "payment" ? 0 : null,
        }}>
        {cart.stage === "payment" &&
          <img src={DownArrow} className="checkoutBack" alt="Back" onClick={() => {
            dispatch({
              type: SET_CART,
              payload: {
                ...cart,
                stage: "note",
              },
            });
          }} />
        }
        <div className="pricingRow">
          <div className="subtotalText">Subtotal</div>
          <div className="subtotalText">${subtotal}</div>
        </div>
        <div className="pricingRow">
          <div className="subtotalText">Tax</div>
          <div className="subtotalText">${tax}</div>
        </div>
        <div className="pricingRow">
          <div className="totalText">Total</div>
          <div className="totalText">${subtotal + tax}</div>
        </div>
        {cart.stage === "payment" && <CheckoutEntry />}
        <CheckoutButton />
      </div>
    </div>
  );
}

export default Checkout;
